import styled from '@emotion/styled';
import { css } from '@emotion/react';

import fonts from '../../../../shared/fonts';
import colors from '../../../../shared/colors';
import { circle } from '../../../../shared/mixins';
import { aspectRatioBox } from '../../../../shared/styles';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.primaryLight};
  color: ${colors.white};
  ${circle('38')};
`;

export const Picture = styled.div`
  width: 100%;
  border-radius: 50%;

  ${({ url, aspectRatio = 1 }) => css`
    ${aspectRatioBox({ ratio: aspectRatio })};
    background-image: url(${url});
    background-size: cover;
    background-position: 50%;
  `};
`;

export const Username = styled.span`
  text-transform: uppercase;
  font-family: ${fonts.GothamMedium};
  font-size: ${fonts.sizes.l};
`;
