import Cookies from 'universal-cookie';

const COOKIES_ACCEPTED_VALUE = '1';
const DEFAULT_COOKIE_NAME = 'temporal_cookie';
const SECONDS_IN_HOUR = 60 * 60;
const SECONDS_IN_DAY = 24 * SECONDS_IN_HOUR;
const SECONDS_IN_YEAR = 365 * SECONDS_IN_DAY;

export const TWO_WEEKS_IN_SECOND = 60 * 60 * 24 * 14;

const getExpirationInSeconds = (expiration = {}) => {
  const cookieExpiration = {
    years: 0,
    days: 0,
    hours: 0,
    ...expiration
  };

  const { years, days, hours } = cookieExpiration;

  return (
    years * SECONDS_IN_YEAR + days * SECONDS_IN_DAY + hours * SECONDS_IN_HOUR
  );
};

export function useCookie(initialCookie) {
  const cookieManager = new Cookies(initialCookie);

  const getCookie = cookieName => cookieManager.get(cookieName);

  const setCookie = (cookieName, value, config = {}) => {
    const options = { ...config };

    if (typeof config.maxAge === 'object') {
      options.maxAge = getExpirationInSeconds(options.maxAge);
    }

    cookieManager.set(cookieName, value, options);
  };

  const removeCookie = cookieName => cookieManager.remove(cookieName);

  const getAllCookies = options => cookieManager.getAll(options);

  return { getCookie, setCookie, removeCookie, getAllCookies };
}

const withCookie =
  ({
    cookieName = DEFAULT_COOKIE_NAME,
    path = '/',
    domain = '',
    secure = false,
    httpOnly = false,
    cookieExpires
  }) =>
  Component =>
  props => {
    const cookieManager = useCookie();

    const setAccepted = (value = COOKIES_ACCEPTED_VALUE) => {
      cookieManager.setCookie(cookieName, value, {
        path,
        domain,
        secure,
        httpOnly,
        maxAge: cookieExpires
      });
    };

    const isCookieAccepted = (value = COOKIES_ACCEPTED_VALUE) =>
      typeof window !== 'undefined'
        ? cookieManager.getCookie(cookieName) === value
        : null;

    return (
      <Component
        {...props}
        isCookieAccepted={isCookieAccepted}
        acceptCookie={setAccepted}
        getCookieValue={cookieManager.getCookie}
      />
    );
  };

export default withCookie;
