import styled from '@emotion/styled';

import Button from '@mui/material/Button';

// @TODO move to  mui theme when promoting component
// Added important is in dev MUI was overriding local styles
export const PrimaryButton = styled(Button)`
  & {
    padding: 14px 20px !important;
    border-radius: 100px !important;
    background-color: var(--sah-primary) !important;
    color: var(--sah-white) !important;
    text-transform: none !important;
    font-family: var(--sah-font-bold) !important;
    font-weight: var(--sah-font-w-bold) !important;
    font-size: var(--sah-font-xxl) !important;
    line-height: var(--sah-line-height-l) !important;
  }
  &&.Mui-disabled {
    opacity: 0.4;
    color: var(--sah-white);
  }
  &&:hover {
    background-color: var(--sah-primary-light);
  }
`;
