import PropTypes from 'prop-types';

import Alert from '../Alert';

import { Consumer } from './ToastContext';
import ToastController from './ToastController';
import toastChannels from './toastChannels';

const DEFAULT_TOAST_COMPONENT = Alert;

const ToastConsumer = ({ channel = toastChannels.DEFAULT }) => (
  <Consumer>
    {({ remove, toasts }) =>
      toasts
        .filter(toast => toast.channel === channel)
        .map(({ content, id, component, ...rest }) => (
          <ToastController
            key={id}
            toastComponent={component || DEFAULT_TOAST_COMPONENT}
            onClose={() => remove(id)}
            {...rest}
          >
            {content}
          </ToastController>
        ))
    }
  </Consumer>
);

ToastConsumer.propTypes = {
  channel: PropTypes.string
};

export default ToastConsumer;
