import {
  Avatar,
  Box,
  Drawer,
  Grid,
  IconButton,
  MenuItem,
  MenuList,
  Toolbar,
  Link
} from '@mui/material';
import { useTheme } from '@mui/styles';
import { styled } from '@mui/material/styles';

import { Typography } from '@spotahome/ui-library/base';
import { useState } from 'react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';

import GroupAdd from '../../../../../../icons/GroupAdd';

import HealthAndSafety from '../../../../../../icons/HealthAndSafety';
import Task from '../../../../../../icons/Task';
import PowerRounded from '../../../../../../icons/PowerRounded';

import {
  getLink,
  LINK_PRIVACY_POLICIES,
  LINK_TERMS_AND_CONDITIONS
} from '../../../../../../utils/url';

import SettingIcon from '../../../../../../icons/SettingIcon';

import HelpIcon from '../../../../../../icons/HelpIcon';

import { useStyles } from './styles';

const StyledMenuList = styled(MenuList)({
  padding: '46px 16px 0px 16px'
});

const StyledMenuItem = styled(MenuItem)({
  padding: '0px 0px 32px 0px',
  '& a, svg': {
    color: '#000000'
  },
  '&:first-of-type': {
    paddingTop: '0'
  },
  '&:last-of-type': {
    paddingTop: '32px'
  },
  '&:hover': {
    backgroundColor: 'transparent'
  }
});

const StyledToolbar = styled(Toolbar)({
  borderBottom: '1px solid #ebebeb'
});

const MobileAvatarMenu = ({ locales, handleLogout, trans, userName }) => {
  const [avatarMenuIsOpen, setAvatarMenuIsOpen] = useState(false);
  const muiTheme = useTheme();
  const classes = useStyles();

  const handleAvatarMenuClick = () => {
    setAvatarMenuIsOpen(!avatarMenuIsOpen);
  };

  return (
    <Box sx={{ flexGrow: 0, display: { md: 'flex' } }}>
      <IconButton
        sx={{
          width: '100%',
          paddingLeft: '0',
          paddingRight: '0',
          '&:hover': {
            cursor: 'pointer',
            backgroundColor: 'transparent'
          }
        }}
        disableRipple
        onClick={handleAvatarMenuClick}
        size="large"
      >
        <Avatar alt={userName} src="/static/images/avatar/2.jpg" />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            paddingLeft: '16px'
          }}
        >
          <Typography.HeadingM>{userName.split(' ')[0]}</Typography.HeadingM>
          <Box
            sx={{
              marginLeft: 'auto'
            }}
          >
            <ChevronRightIcon
              style={{
                fontSize: '24px'
              }}
            />
          </Box>
        </Box>
      </IconButton>

      <Drawer
        className={classes.fontNormalizer}
        anchor={'right'}
        open={avatarMenuIsOpen}
        onClose={() => setAvatarMenuIsOpen(false)}
        theme={muiTheme}
      >
        <StyledToolbar>
          <Grid container alignItems={'center'}>
            <Box
              sx={{
                marginRight: 'auto',
                display: 'flex'
              }}
            >
              <ArrowBackIcon
                onClick={handleAvatarMenuClick}
                style={{
                  fontSize: '24px'
                }}
              />
            </Box>
            <Box
              sx={{
                marginLeft: 'auto',
                marginRight: 'auto'
              }}
            >
              <Typography.HeadingS>{userName}</Typography.HeadingS>
            </Box>
            <Box sx={{ marginLeft: 'auto', display: 'flex' }}>
              <CloseIcon
                onClick={handleAvatarMenuClick}
                style={{
                  fontSize: '24px'
                }}
              />
            </Box>
          </Grid>
        </StyledToolbar>
        <Box role="presentation" sx={{ width: '100vw' }}>
          <StyledMenuList>
            <StyledMenuItem component={Link} href="/settings" disableRipple>
              <SettingIcon />
              <Box sx={{ px: 1 }}>
                <Typography.HeadingS>
                  {trans('sahc.menu.route.settings')}
                </Typography.HeadingS>
              </Box>
            </StyledMenuItem>
            <StyledMenuItem component={Link} disableRipple href="/my-referrals">
              <GroupAdd />
              <Box sx={{ px: 1 }}>
                <Typography.HeadingS>
                  {trans('sahc.header.refer-a-landlord.button')}
                </Typography.HeadingS>
              </Box>
            </StyledMenuItem>
            <StyledMenuItem
              component={Link}
              disableRipple
              href={getLink(LINK_TERMS_AND_CONDITIONS, locales.current)}
            >
              <Task />
              <Box sx={{ px: 1 }}>
                <Typography.HeadingS>
                  {trans('sahc.footer.links.terms.and.conditions')}
                </Typography.HeadingS>
              </Box>
            </StyledMenuItem>
            <StyledMenuItem
              component={Link}
              disableRipple
              href={getLink(LINK_PRIVACY_POLICIES, locales.current)}
            >
              <HealthAndSafety />
              <Box sx={{ px: 1 }}>
                <Typography.HeadingS>
                  {trans('sahc.footer.links.privacy.policy')}
                </Typography.HeadingS>
              </Box>
            </StyledMenuItem>
            <StyledMenuItem component={Link} href="/support" disableRipple>
              <HelpIcon />
              <Box sx={{ px: 1 }}>
                <Typography.HeadingS>
                  {trans('landlord.support.title')}
                </Typography.HeadingS>
              </Box>
            </StyledMenuItem>
            <hr className={classes.hr} />
            <StyledMenuItem
              component={Link}
              disableRipple
              onClick={handleLogout}
            >
              <PowerRounded />
              <Box sx={{ px: 1 }}>
                <Typography.HeadingS>Logout</Typography.HeadingS>
              </Box>
            </StyledMenuItem>
          </StyledMenuList>
        </Box>
      </Drawer>
    </Box>
  );
};

MobileAvatarMenu.propTypes = {
  locales: PropTypes.object.isRequired,
  handleLogout: PropTypes.func.isRequired,
  trans: PropTypes.func.isRequired,
  userName: PropTypes.string.isRequired
};

export default MobileAvatarMenu;
