import PropTypes from 'prop-types';

import * as S from './styles';

function PrimaryButton({ children, ...rest }) {
  return (
    <S.PrimaryButton variant="outline" {...rest}>
      {children}
    </S.PrimaryButton>
  );
}

PrimaryButton.propTypes = {
  children: PropTypes.node.isRequired
};

export default PrimaryButton;
