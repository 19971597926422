import PropTypes from 'prop-types';

import * as S from './styles';

function DefaultButton({ children, ...rest }) {
  return (
    <S.DefaultButton variant="outline" {...rest}>
      {children}
    </S.DefaultButton>
  );
}

DefaultButton.propTypes = {
  children: PropTypes.node.isRequired
};

export default DefaultButton;
