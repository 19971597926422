import { makeStyles } from '@mui/styles';

const ErrorSection = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minHeight: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 36,
    textAlign: 'center',

    '&.no-icon': {
      marginTop: 0
    }
  },
  logo: {
    marginBottom: 32
  },
  title: {
    maxWidth: 550,
    marginBottom: 24
  },
  description: {
    maxWidth: 550,
    marginBottom: 32
  },
  link: {
    display: 'block',
    color: 'black',
    marginTop: 24
  }
});

export default () => ({
  errorSection: ErrorSection()
});
