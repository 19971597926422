import { trans } from '@spotahome/soyuz/client';
import Typography from '@spotahome/landlord-panel-ui-library/src/components/Typography';

import useStyles from './styles';

const EXPERIENCE = [
  {
    title: 'landlord.landing.standard.stats.v2.users-per-month.title',
    text: 'landlord.landing.standard.stats2.v2.users-per-month.description.alternative'
  },
  {
    title:
      'landlord.landing.standard.stats.v2.published-properties.title.alternative',
    text: 'landlord.landing.standard.stats.v2.published-properties.description.alternative'
  },
  {
    title: 'landlord.landing.standard.stats.v2.bookings.title',
    text: 'landlord.landing.standard.stats.v2.bookings.description.alternative'
  }
];

const HistoricalMetrics = () => {
  const classes = useStyles();
  return (
    <section className={classes.historicalMetrics.wrapper}>
      <div className={classes.historicalMetrics.content}>
        {EXPERIENCE.map(({ title, text }) => (
          <div className={classes.historicalMetrics.item} key={title}>
            <div className={classes.historicalMetrics.number}>
              <Typography variant="DisplayM">{trans(title)}</Typography>
            </div>
            <div className={classes.historicalMetrics.text}>
              <Typography variant="TagM">{trans(text)}</Typography>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default HistoricalMetrics;
