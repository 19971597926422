import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { space } from '../../../../shared/spacing';
import { mediaQueries } from '../../../../shared/breakpoints';

const Container = styled.div`
  max-width: 500px;
  padding: 0 ${space.x2};
  margin-right: auto;
  margin-left: auto;

  ${mediaQueries.md(css`
    position: absolute;
    display: flex;
    padding: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: none;
    justify-content: center;
    align-items: center;
  `)};
`;

const PageMessage = ({ children }) => <Container>{children}</Container>;

PageMessage.propTypes = {
  children: PropTypes.element.isRequired
};

export default PageMessage;
