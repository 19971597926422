import PropTypes from 'prop-types';
import { matchPath, useLocation } from 'react-router';

import { withLocales } from '@spotahome/soyuz/client';
import Tracking from '@spotahome/soyuz-tracking';
import { localesShape } from '@spotahome/soyuz/shapes';

import {
  FullWidthLayout,
  routes
} from '@spotahome/landlord-panel-ui-library/src/containers/LandlordPanel';
import { getCourrentRoute } from '@spotahome/landlord-panel-ui-library/src/utils/url';

import { CHANGE_LANGUAGE_PANEL } from '@spotahome/properties-common/src/lib/hotjar-events';
import { trackMenuSectionClicked } from '@spotahome/properties-common/src/lib/segment/menu';
import withTranslations from '@spotahome/properties-common/src/hoc/withTranslations';

const LandlordFullWidthPanelLayout = withLocales()(
  withTranslations(FullWidthLayout)
);

const Layout = ({ locales, onLanguageChange = null, children }) => {
  const location = useLocation();

  const handleLanguageChange = locale => {
    Tracking.hotjar.recordTag([CHANGE_LANGUAGE_PANEL]);

    return onLanguageChange(locale);
  };

  const handleSectionClick = (_, id) => {
    trackMenuSectionClicked(id, null, locales.current);
  };

  const currentRoute = getCourrentRoute(location.pathname, routes, matchPath);

  return (
    <LandlordFullWidthPanelLayout
      currentRoute={currentRoute}
      onLanguageChange={handleLanguageChange}
      onSectionClick={handleSectionClick}
      showDirectContact={false}
    >
      {children}
    </LandlordFullWidthPanelLayout>
  );
};

Layout.propTypes = {
  locales: PropTypes.shape(localesShape).isRequired,
  onLanguageChange: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
};

export default withLocales()(Layout);
