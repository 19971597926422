import styled from '@emotion/styled';
import { createStyles, makeStyles } from '@mui/styles';
import { Drawer } from '@mui/material';

import { fontNormalizer } from '../../styles';

const NavigationModal = styled(Drawer)`
  ${props => props.theme.breakpoints.up('md')} {
    display: none;
  }
`;

export const useStyles = makeStyles(() =>
  createStyles({
    fontNormalizer
  })
);

export const mobileDirectStyles = makeStyles({
  root: {
    paddingTop: '40px',
    paddingBottom: '60px'
  },
  title: {
    marginBottom: '8px'
  },
  text: {
    marginBottom: '16px'
  },
  button: {
    marginBottom: '16px'
  }
});

export default NavigationModal;
