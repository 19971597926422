import PropTypes from 'prop-types';

import useStyles from './styles';

const UniqueSellingPoint = ({ renderIcon, description, title }) => {
  const classes = useStyles();

  return (
    <div className={classes.uniqueSellingPoint.boxWrapper}>
      <div className={classes.uniqueSellingPoint.box}>
        <div className={classes.uniqueSellingPoint.icon}>{renderIcon()}</div>
        <h3 className={classes.uniqueSellingPoint.title}>{title}</h3>
        <div className={classes.uniqueSellingPoint.description}>
          {description}
        </div>
      </div>
    </div>
  );
};

UniqueSellingPoint.propTypes = {
  renderIcon: PropTypes.func.isRequired,
  description: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default UniqueSellingPoint;
