import PropTypes from 'prop-types';
import { Link } from '@mui/material';
import { trans } from '@spotahome/soyuz/client';

const LandingLink = ({ children }) => (
  <Link
    href="/"
    aria-label={trans('lister.landing.page-header-alt')}
    title={trans('lister.landing.page-header-alt')}
    underline="hover"
  >
    {children}
  </Link>
);

LandingLink.propTypes = {
  children: PropTypes.node.isRequired
};

export default LandingLink;
