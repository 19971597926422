import { makeStyles } from '@mui/styles';

const Button = makeStyles(theme => ({
  cta: {
    fontFamily: 'var(--sah-font-bold)',
    fontSize: 'var(--sah-font-m)',
    display: 'inline-block',
    margin: 0,
    padding: '16px 32px',
    borderRadius: 100,
    minWidth: 150,
    [theme.breakpoints.up('sm')]: {
      borderRadius: '0',
      width: 'auto',
      minWidth: 200
    },
    [theme.breakpoints.up('md')]: {
      minWidth: 320
    }
  }
}));

export default () => ({
  button: Button()
});
