const SpotahomeIcon = () => (
  <svg
    width="22"
    height="32"
    viewBox="0 0 22 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.9161 10.9581C21.9161 4.91541 17.0007 0 10.9581 0C5.20681 0 0.483106 4.44764 0.0383417 10.0839C0.0153367 10.3753 0 10.659 0 10.9581C0 11.2571 0.0153367 11.5409 0.0383417 11.8323C0.314402 15.329 2.23916 18.3657 5.0381 20.1677H0V21.0419C0 27.0846 4.91541 32 10.9581 32C16.7017 32 21.433 27.5524 21.8778 21.9161C21.9008 21.6247 21.9161 21.341 21.9161 21.0419C21.9161 20.7429 21.9008 20.4591 21.8778 20.1677C21.6017 16.671 19.677 13.6343 16.878 11.8323H21.9161V10.9581ZM1.78672 11.8323H10.0839V20.1294C5.69758 19.7153 2.20081 16.2109 1.78672 11.8323ZM10.9581 30.2516C6.17302 30.2516 2.23149 26.5862 1.78672 21.9161H20.1294C19.6846 26.5862 15.7431 30.2516 10.9581 30.2516ZM20.1294 20.1677H11.8323V11.8706C16.2109 12.2847 19.7153 15.7815 20.1294 20.1677ZM1.78672 10.0839C2.22382 5.41385 6.17302 1.74838 10.9581 1.74838C15.7431 1.74838 19.6846 5.41385 20.1294 10.0839H1.78672Z"
      fill="#0D0D0D"
    />
  </svg>
);

export default SpotahomeIcon;
