import { useCallback } from 'react';
import Tracking from '@spotahome/soyuz-tracking';

import {
  getPagePath,
  useSoyuzServer,
  useSoyuzRequest
} from '@spotahome/soyuz/client';

export const useLocaleChange = () => {
  const { currentRoute } = useSoyuzServer();
  const { params } = useSoyuzRequest();
  const onChange = useCallback(
    locale => {
      Tracking.ga.sendEvent('i18n', 'language-switcher', locale);
      const queryString = window.location.search;

      window.location =
        getPagePath(currentRoute, { locale, params }) + queryString;
    },
    [currentRoute, params]
  );

  return onChange;
};

export default {
  useLocaleChange
};
