import { useState } from 'react';
import {
  Box,
  Divider,
  Drawer,
  Container,
  useMediaQuery,
  Badge
} from '@mui/material';
import { useTheme } from '@mui/styles';
import PropTypes from 'prop-types';

import CloseIcon from '@mui/icons-material/Close';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';

import { Mediator } from '@spotahome/soyuz-mediator';

import { useSoyuzFlag } from '@spotahome/soyuz/client';

import Typography from '../../../../../../components/Typography';

import localesShape from '../../../../shapes/locales';
import HamburgerIcon from '../../../../../../icons/Hamburger';
import NavMenu from '../NavMenu';
import MobileAvatarMenu from '../MobileAvatarMenu';

import { TextFloatingButton, WhatsappButtonMobile } from '../../WhatsappButton';
import userShape from '../../../../../../shapes/user';

import MobileLanguageMenu from '../MobileLanguageMenu';
import RegisterMenu from '../RegisterMenu';
import WhatsappBubble from '../../../../../../icons/WhatsappBubble';

import PhoneBubble from '../../../../../../icons/PhoneBubble';

import { TOGGLE_NOTIFICATION_CENTER } from '../../../../../../utils/eventChannels';

import NotificationMenu from '../NotificationMenu/NotificationMenu';

import MobileTopBar from './MobileTopBar';
import { useStyles, mobileDirectStyles } from './styles';

const telephoneNumber = {
  link: 'tel:+34911232876',
  text: '+34 911 23 28 76'
};

const MobileSideBarMenu = ({
  trans,
  currentRoute = false,
  locales,
  whatsappLink,
  isTelephoneVisible,
  isWorkingHours,
  showDirectContact,
  handleLogout,
  user,
  onLanguageChange,
  onSectionClick,
  onWhatsappClick,
  onDirectContactClick,
  canShowPlansSection = true,
  highlightPlansSection = true,
  hideAllLinks = false,
  title = null
}) => {
  const muiTheme = useTheme();
  const [submenuIsOpen, setSubmenuIsOpen] = useState(false);
  const isSmallScreen = useMediaQuery(muiTheme.breakpoints.down('md'));
  const [avatarMenuIsOpen, setAvatarMenuIsOpen] = useState(false);
  const classes = useStyles();
  const mobileDirectClasses = mobileDirectStyles();
  const mobileNotificationFlag = useSoyuzFlag('MobileNotificationButton');
  const ShowNotificationCenterFlag = useSoyuzFlag(
    'ShowNotificationCenter'
  ).isOn();
  const isLoggedIn = !!user?.id;
  const showMobileNotification = isLoggedIn && mobileNotificationFlag.isOn();
  const handleAvatarMenuClick = () => {
    setAvatarMenuIsOpen(!avatarMenuIsOpen);
  };

  return (
    <>
      <MobileTopBar withSymbol title={title}>
        {showMobileNotification && !ShowNotificationCenterFlag && (
          <Badge variant="dot" color="secondary">
            <NotificationsActiveIcon
              style={{ color: 'black' }}
              onClick={() => {
                Mediator.publish(TOGGLE_NOTIFICATION_CENTER);
              }}
            />
          </Badge>
        )}
        {isLoggedIn && !hideAllLinks && ShowNotificationCenterFlag && (
          <NotificationMenu />
        )}

        <HamburgerIcon color="black" onClick={() => setSubmenuIsOpen(true)} />
      </MobileTopBar>
      <Drawer
        anchor={'right'}
        open={submenuIsOpen}
        className={classes.fontNormalizer}
        onClose={() => setSubmenuIsOpen(false)}
      >
        <MobileTopBar>
          <CloseIcon
            style={{
              fontSize: '24px'
            }}
            onClick={() => setSubmenuIsOpen(false)}
          />
        </MobileTopBar>
        <Box role="presentation" style={{ width: '100vw' }}>
          <Container>
            {isLoggedIn && (
              <MobileAvatarMenu
                onClick={handleAvatarMenuClick}
                locales={locales}
                handleLogout={handleLogout}
                trans={trans}
                userName={user.fullname}
              />
            )}
            <Divider />
            <NavMenu
              trans={trans}
              currentRoute={currentRoute}
              locales={locales}
              onSectionClick={onSectionClick}
              canShowPlansSection={canShowPlansSection}
              isLoggedIn={isLoggedIn}
              highlightPlansSection={highlightPlansSection}
            />
            <Divider />
            <MobileLanguageMenu
              locales={locales}
              onLanguageChange={onLanguageChange}
            />
            {!isLoggedIn && (
              <>
                <Divider />
                <RegisterMenu locale={locales.current} />
              </>
            )}
            {isSmallScreen && showDirectContact && (
              <>
                <Divider />
                <div className={mobileDirectClasses.root}>
                  <div className={mobileDirectClasses.title}>
                    <Typography variant={'HeadingS'}>
                      {trans('sahc.contact.direct.help.title')}
                    </Typography>
                  </div>
                  <div className={mobileDirectClasses.text}>
                    <Typography variant={'ParagraphXS'}>
                      {trans('sahc.contact.direct.help.text')}
                    </Typography>
                  </div>
                  {isTelephoneVisible && (
                    <div className={mobileDirectClasses.button}>
                      <WhatsappButtonMobile
                        variant="extended"
                        onClick={onDirectContactClick}
                        href={isWorkingHours ? telephoneNumber.link : undefined}
                        target="_blank"
                      >
                        <PhoneBubble
                          color={
                            isWorkingHours ? undefined : 'var(--sah-light)'
                          }
                        />
                        <TextFloatingButton>
                          <Typography variant={'ParagraphMTall'}>
                            {trans('sahc.contact.direct.text', {
                              telephone: telephoneNumber.text
                            })}
                          </Typography>
                        </TextFloatingButton>
                      </WhatsappButtonMobile>
                    </div>
                  )}

                  <div className={mobileDirectClasses.button}>
                    <WhatsappButtonMobile
                      variant="extended"
                      onClick={onWhatsappClick}
                      href={
                        isWorkingHours && whatsappLink
                          ? whatsappLink
                          : undefined
                      }
                      target="_blank"
                    >
                      <WhatsappBubble
                        color={isWorkingHours ? undefined : 'var(--sah-light)'}
                      />
                      <TextFloatingButton>
                        <Typography variant={'ParagraphMTall'}>
                          {trans('sahc.contact.whatsapp.text')}
                        </Typography>
                      </TextFloatingButton>
                    </WhatsappButtonMobile>
                  </div>
                </div>
              </>
            )}
          </Container>
        </Box>
      </Drawer>
    </>
  );
};

MobileSideBarMenu.propTypes = {
  trans: PropTypes.func.isRequired,
  currentRoute: PropTypes.string,
  locales: localesShape.isRequired,
  whatsappLink: PropTypes.string.isRequired,
  handleLogout: PropTypes.func.isRequired,
  user: userShape.isRequired,
  onLanguageChange: PropTypes.func.isRequired,
  onSectionClick: PropTypes.func.isRequired,
  onWhatsappClick: PropTypes.func.isRequired,
  onDirectContactClick: PropTypes.func.isRequired,
  isTelephoneVisible: PropTypes.bool.isRequired,
  isWorkingHours: PropTypes.bool.isRequired,
  showDirectContact: PropTypes.bool.isRequired,
  canShowPlansSection: PropTypes.bool,
  highlightPlansSection: PropTypes.bool,
  hideAllLinks: PropTypes.bool,
  title: PropTypes.node
};

export default MobileSideBarMenu;
