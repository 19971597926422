'use client';

import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';

import ErrorSection from './ErrorSection';

const DefaultErrorBoundary = ({ children }) => {
  return <ErrorBoundary fallback={<ErrorSection />}>{children}</ErrorBoundary>;
};

DefaultErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired
};

export default DefaultErrorBoundary;
