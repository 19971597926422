import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

// @TODO move to  mui theme when promoting component

export const StyledButton = styled(Button)(({ typeProp, size, fullWidth }) => ({
  borderRadius: '100px',
  fontFamily: 'var(--sah-font-light)',
  fontWeight: 'var(--sah-font-w-light)',
  fontSize: 'var(--sah-font-s)',
  lineHeight: '1.3',
  textTransform: 'none',
  '&.Mui-disabled': {
    color: 'var(--sah-white)',
    opacity: '0.4'
  },
  ...(typeProp === 'primary' && {
    backgroundColor: 'var(--sah-primary)',
    color: 'var(--sah-white)!important',
    '& *': {
      color: 'var(--sah-white)!important'
    },
    '&:hover': {
      backgroundColor: 'var(--sah-primary-light)'
    }
  }),
  ...(typeProp === 'secondary' && {
    backgroundColor: 'var(--sah-interaction)',
    color: 'var(--sah-secondary-blue)',
    '&:hover': {
      backgroundColor: 'var(--sah-interaction-light)'
    }
  }),
  ...(typeProp === 'tertiary' && {
    border: '1px solid var(--sah-medium)',
    '&:hover': {
      backgroundColor: '1px solid var(--sah-interaction-dark)'
    }
  }),
  ...(size === 'small' && {
    padding: '8px 14px'
  }),
  ...(size === 'default' && {
    padding: '14px 20px'
  }),
  ...(size === 'big' && {
    padding: '18px 30px'
  }),
  ...(typeProp === 'text' && {
    padding: '0',
    color: 'var(--sah-primary)',
    backgroundColor: 'transparent',
    textDecoration: 'underline',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  }),
  ...(fullWidth && {
    width: '100%'
  })
}));
