import PropTypes from 'prop-types';

const MobileIcon = ({ className = '', color = '#0A0777' }) => (
  <svg
    width="61"
    height="60"
    viewBox="0 0 61 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <rect x="9.66669" y="46" width="41" height="14" fill={color} />
    <rect x="9.66669" width="41" height="9" fill={color} />
    <rect x="9.66669" y="9" width="41" height="37" fill="#C9E3F7" />
    <circle cx="30.1667" cy="53" r="3" fill="#C9E3F7" />
  </svg>
);

export default MobileIcon;

MobileIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string
};
