import BaseIcon from './Base';

const LensIcon = props => (
  <BaseIcon width="20" height="18" viewBox="8.5 8 15 16" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M24 23.374a.325.325 0 0 1-.098.241c-.087.065-.163.097-.229.097a.335.335 0 0 1-.244-.097l-5.47-5.344c-1.077.944-2.422 1.443-4.032 1.497-1.698-.054-3.092-.633-4.18-1.739C8.626 16.945 8.044 15.55 8 13.844c.054-1.653.642-3.032 1.763-4.138 1.121-1.094 2.51-1.663 4.164-1.706 1.708.043 3.134.612 4.277 1.706 1.1 1.106 1.676 2.485 1.73 4.138-.054 1.599-.554 2.913-1.501 3.944l5.469 5.344a.325.325 0 0 1 .098.242zm-10.073-4.266c1.512-.032 2.775-.548 3.787-1.546 1.002-.987 1.519-2.227 1.551-3.718-.032-1.47-.55-2.689-1.55-3.655-1.024-.987-2.286-1.497-3.788-1.529-1.47.032-2.705.542-3.707 1.53-1.001.965-1.518 2.183-1.55 3.654.032 1.491.549 2.731 1.55 3.718.99.998 2.226 1.514 3.707 1.546z"
    />
  </BaseIcon>
);

export default LensIcon;
