import { Component } from 'react';

import { getScreenScrollPosition } from '../utils/dom';
import { createDelayedProxyFn } from '../utils/proxy';
import { DEFAULT_DEBOUNCE_TIME } from '../utils/debounce';
import { DEFAULT_THROTTLE_TIME } from '../utils/throttle';

function getScreenScrollPositionState() {
  const { left, top } = getScreenScrollPosition();
  return {
    scroll: {
      left,
      top
    }
  };
}

/**
 * HOC that provides the screen scroll position when changes
 *
 * @param WrappedComponent
 * @param debounceWait
 * @param throttleWait
 * @returns {ScrollPositionListenerWrapper}
 */
function withScreenScrollPosition(
  WrappedComponent,
  debounceWait = DEFAULT_DEBOUNCE_TIME,
  throttleWait = DEFAULT_THROTTLE_TIME
) {
  class ScrollPositionListenerWrapper extends Component {
    state = getScreenScrollPositionState();

    constructor(props) {
      super(props);

      this.handleEventProxy = createDelayedProxyFn(
        this.handleEvent,
        debounceWait,
        throttleWait
      );
    }

    componentDidMount() {
      window.addEventListener('scroll', this.handleEventProxy);
    }

    componentWillUnmount() {
      window.removeEventListener('scroll', this.handleEventProxy);
    }

    handleEvent = () => {
      this.setState(() => getScreenScrollPositionState());
    };

    render() {
      return <WrappedComponent {...this.state} {...this.props} />;
    }
  }

  return ScrollPositionListenerWrapper;
}

export default withScreenScrollPosition;
