import { makeStyles } from '@mui/styles';

const Hero = makeStyles(theme => ({
  hero: {
    display: 'flex',
    height: 'auto',
    flexWrap: 'wrap',
    alignContent: 'stretch'
  },
  wrapper: {
    display: 'flex',
    padding: '32px 24px 24px',
    flexWrap: 'wrap',
    alignItems: 'center',
    position: 'relative',
    width: '100%',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      padding: '125px 24px 24px',
      justifyContent: 'flex-end',
      width: '50%'
    }
  },
  content: {
    width: '100%',
    maxWidth: '640px'
  },
  heading: {
    textTransform: 'uppercase',
    marginBottom: '16px'
  },
  titleMobile: {
    display: 'block',
    margin: '0 0 16px',
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  titleDesktop: {
    display: 'none',
    margin: '0 0 16px',
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    }
  },
  subtitle: {
    fontSize: 'var(--sah-font-l)',
    fontFamily: 'var(--sah-font-light)',
    margin: '0',
    lineHeight: 'var(--sah-line-height-l)'
  },
  cta: {
    fontFamily: 'var(--sah-font-bold)',
    fontSize: 'var(--sah-font-m)',
    display: 'inline-block',
    borderRadius: '0',
    padding: '16px 32px',
    margin: '32px 0 24px',
    width: 'auto'
  },
  loginLink: {
    fontSize: 'var(--sah-font-s)',
    lineHeight: 'var(--sah-line-height-s)',
    fontFamily: 'var(--sah-font-bold)',
    display: 'block',
    textDecoration: 'underline',
    color: 'var(--sah-interaction-dark)',
    marginBottom: 48,
    [theme.breakpoints.up('sm')]: {
      marginBottom: 80
    }
  },
  rating: {}
}));

const HeroVideo = makeStyles(theme => ({
  wrapper: {
    position: 'relative',
    display: 'none',
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0',
    backgroundClip: 'border-box',
    objectFit: 'cover',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'flex-end',
      width: '50%'
    }
  },
  container: {
    position: 'absolute',
    display: 'block',
    overflow: 'hidden',
    backgroundSize: 'cover',
    objectFit: 'cover',
    width: '100%',
    height: '100%'
  },
  player: {
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  },
  cover: {
    width: '100%'
  }
}));

export default () => ({
  hero: Hero(),
  heroVideo: HeroVideo()
});
