import { css } from '@emotion/react';

export const animatedHover = color => css`
  transition: color 0.25s ease-out, opacity 0.25s ease-out,
    background-color 0.25s ease-out, box-shadow 0.25s ease-out,
    fill 0.25s ease-out, text-shadow 0.25s ease-out,
    -webkit-box-shadow 0.25s ease-out;

  &:hover:not(:disabled) {
    color: ${color};
    fill: ${color};
  }
`;

export const square = size => css`
  height: ${size};
  width: ${size};
`;

export const circle = size => css`
  height: ${size}px;
  width: ${size}px;
  border-radius: ${size / 2}px;
`;
