import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';

const DEFAULT_CREDENTIALS = 'same-origin';

export const cache = new InMemoryCache();

const createApolloClient = (
  uri = '/landlord-properties/graphql',
  credentials = DEFAULT_CREDENTIALS
) =>
  new ApolloClient({
    link: new HttpLink({ uri, credentials }),
    cache
  });

export default createApolloClient;
