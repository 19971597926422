import { useState } from 'react';
import PropTypes from 'prop-types';
import { trans } from '@spotahome/soyuz/client';
import Tracking from '@spotahome/soyuz-tracking';

import { Modal, ModalHeader, ModalBody } from '@spotahome/ui-library';

import useStyles from './styles';

const FeatureBoxButton = ({ onClick }) => {
  const classes = useStyles();
  return (
    <button
      type="button"
      onClick={onClick}
      className={classes.featureBox.learnMore}
    >
      {trans('lister.landing.alternative.show-more')}
    </button>
  );
};

FeatureBoxButton.propTypes = {
  onClick: PropTypes.func.isRequired
};

const track = featureId => {
  Tracking.ga.sendEvent(
    'landlord_landing',
    'feature_read_more_link',
    featureId
  );
  Tracking.segment.trackEvent('landlord_landing_feature_read_more_link', null, {
    featureId
  });
};

const FeatureBox = ({
  icon,
  description,
  title,
  learnMore = null,
  learnMoreLink = null,
  id
}) => {
  const classes = useStyles();
  const [isModalOpen, setModalOpen] = useState(false);

  const handleModalOnClose = () => setModalOpen(false);
  const handleModalOnOpen = () => {
    track(id);
    setModalOpen(true);
  };

  const handleLearnMoreLink = () => {
    track(id);

    window.open(learnMoreLink, '_blank');
  };

  return (
    <>
      <div className={classes.featureBox.box}>
        <div className={classes.featureBox.icon}>{icon}</div>
        <h3 className={classes.featureBox.title}>{title}</h3>
        <div className={classes.featureBox.description}>{description}</div>
        {learnMoreLink && <FeatureBoxButton onClick={handleLearnMoreLink} />}
        {learnMore && <FeatureBoxButton onClick={handleModalOnOpen} />}
      </div>
      {learnMore && (
        <Modal
          className={classes.featureBox.modal}
          isOpen={isModalOpen}
          onRequestClose={handleModalOnClose}
        >
          <ModalHeader
            onRequestClose={handleModalOnClose}
            showClose
            label={title}
          />
          <ModalBody>
            <div
              className={classes.featureBox.learnMoreDes}
              dangerouslySetInnerHTML={{
                __html: learnMore
              }}
            />
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

FeatureBox.propTypes = {
  id: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  description: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  learnMore: PropTypes.node,
  learnMoreLink: PropTypes.string
};

export default FeatureBox;
