export const DEFAULT_DEBOUNCE_TIME = 100;

/**
 * Creates a debounced function that delays invoking fn
 * until after wait milliseconds have elapsed since
 * the last time the debounced function was invoked
 *
 * @param fn
 * @param wait
 * @returns {Function}
 */
const debounce = (fn, wait = DEFAULT_DEBOUNCE_TIME) => {
  let timeoutId;
  return (...args) => {
    if (timeoutId !== undefined) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      fn(...args);
    }, wait);
  };
};

export default debounce;
