import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

import BaseIcon from './Base';

const GroupAdd = ({ width = 30, height = 19, ...props }) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '32px',
      height: '32px'
    }}
  >
    <BaseIcon width={width} height={height} viewBox="0 0 30 19" {...props}>
      <path
        fill="#0D0D0D"
        d="M15.8 9.5333c.5555-.6222.9724-1.3333 1.2506-2.1333.2774-.8.416-1.6222.416-2.4667 0-.8666-.1386-1.7-.416-2.5-.2782-.8-.6951-1.5-1.2506-2.1 1.1777.1334 2.1502.6387 2.9173 1.516.7662.8782 1.1493 1.9063 1.1493 3.084 0 1.1778-.3831 2.2054-1.1493 3.0827-.7671.8782-1.7396 1.384-2.9173 1.5173Zm7 10.2V16.6c0-.7111-.1498-1.3947-.4494-2.0507-.3004-.6551-.7173-1.216-1.2506-1.6826 1.0222.3333 1.9613.7889 2.8173 1.3666C24.7724 14.8111 25.2 15.6 25.2 16.6v3.1333h-2.4ZM25.2 11V8.3333h-2.6667v-2H25.2V3.6667h2v2.6666h2.6666v2H27.2V11h-2ZM10.1333 9.6c-1.2889 0-2.3889-.4613-3.3-1.384-.9111-.9218-1.3667-2.016-1.3667-3.2827 0-1.2889.4556-2.3889 1.3667-3.3.9111-.911 2.0111-1.3666 3.3-1.3666s2.3889.4555 3.3 1.3666c.9111.9111 1.3667 2.0111 1.3667 3.3 0 1.2667-.4556 2.361-1.3667 3.2827-.9111.9227-2.0111 1.384-3.3 1.384Zm-10 10.1333v-2.9666c0-.6445.1778-1.2445.5333-1.8.3556-.5556.8223-.9889 1.4-1.3 1.3334-.6445 2.6667-1.128 4-1.4507a17.2726 17.2726 0 0 1 4.0667-.4827c1.3556 0 2.7053.1609 4.0493.4827 1.3449.3227 2.6729.8062 3.984 1.4507.6.3111 1.0778.7444 1.4334 1.3.3555.5555.5333 1.1555.5333 1.8v2.9666h-20Zm10-12.1333c.7333 0 1.3613-.2613 1.884-.784.5218-.5218.7827-1.1493.7827-1.8827 0-.7333-.2609-1.3613-.7827-1.884-.5227-.5217-1.1507-.7826-1.884-.7826-.7333 0-1.3609.2609-1.8827.7826-.5226.5227-.784 1.1507-.784 1.884 0 .7334.2614 1.361.784 1.8827.5218.5227 1.1494.784 1.8827.784Zm-8 10.1333h16v-.9666c0-.2667-.0778-.5111-.2333-.7334-.1556-.2222-.3667-.4111-.6334-.5666-1.1555-.5556-2.328-.9836-3.5173-1.284a14.7367 14.7367 0 0 0-3.616-.4494c-1.2222 0-2.4276.1498-3.616.4494-1.1893.3004-2.3618.7284-3.5173 1.284-.2667.1555-.4778.3444-.6334.5666-.1555.2223-.2333.4667-.2333.7334v.9666Z"
      />
    </BaseIcon>
  </Box>
);

GroupAdd.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
};

export default GroupAdd;
