import { makeStyles } from '@mui/styles';

import { fontNormalizer } from '../../styles';

export const useStyles = makeStyles({
  hr: {
    borderTop: '1px solid #EBEBEB'
  },
  fontNormalizer
});
