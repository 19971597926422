import styled from '@emotion/styled';

import { space } from '../../../../shared/spacing';

const PageSection = styled.section`
  max-width: 960px;
  width: 100%;
  padding: ${space.x4};
  margin: 0 auto;
  overflow-x: visible;

  .page-appear {
    opacity: 0.01;
  }

  .page-appear.page-appear-active {
    opacity: 1;
    transition: opacity 0.2s ease-in;
  }
`;

export default PageSection;
