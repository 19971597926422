import PropTypes from 'prop-types';

import BaseIcon from './Base';

const StarsIcon = ({ width = 16, height = 16, ...props }) => (
  <BaseIcon width={width} height={height} viewBox="0 0 17 17" {...props}>
    <path
      fill="#0A0777"
      d="m12.594 6.807-.747-1.609-1.659-.725 1.659-.725.747-1.608.748 1.608L15 4.473l-1.658.725-.748 1.609Zm0 9.333-.747-1.608-1.659-.725 1.659-.725.747-1.609.748 1.609 1.658.725-1.658.725-.748 1.608Zm-6.796-2.346L4.303 10.59 1 9.14l3.303-1.45 1.495-3.203L7.293 7.69l3.303 1.45-3.303 1.45-1.495 3.204Z"
    />
  </BaseIcon>
);

StarsIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
};

export default StarsIcon;
