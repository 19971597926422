import BaseIcon from './Base';

const ClearIcon = props => (
  <BaseIcon width="20" height="20" viewBox="2 2 25 25" {...props}>
    <g fill="none" fillRule="evenodd">
      <circle cx="16" cy="16" r="10" fill="#E3E3E3" />
      <g fill="#6C6C6C">
        <path d="M20.056 12.217l.315.338-8.101 7.554-.315-.337z" />
        <path d="M11.944 12.217l8.1 7.555-.314.337-8.101-7.554z" />
      </g>
    </g>
  </BaseIcon>
);

export default ClearIcon;
