import { PropTypes } from 'prop-types';
import { trans, getResourcePath } from '@spotahome/soyuz/client';

import useStyles from './styles';

const getPicturePath = (pictureId, extension = 'jpg') =>
  getResourcePath(`/images/${pictureId}.${extension}`);

const PlusPicture = ({ pictureId, isMobile = false }) => {
  const { picture: classes } = useStyles();

  return (
    <picture
      className={`${classes.picture} ${
        isMobile ? classes.mobile : classes.desktop
      }`}
    >
      <source srcSet={getPicturePath(pictureId, 'webp')} type="image/webp" />
      <img
        src={getPicturePath(pictureId)}
        loading="lazy"
        alt={trans('landlord.landing.plus-section.title')}
        type="image/jpeg"
        className={classes.img}
      />
    </picture>
  );
};

PlusPicture.propTypes = {
  isMobile: PropTypes.bool,
  pictureId: PropTypes.string.isRequired
};

export default PlusPicture;
