import isUAWebview from 'is-ua-webview';

function checkIsTouchDevice() {
  return (
    !!(
      typeof window !== 'undefined' &&
      ('ontouchstart' in window ||
        (window.DocumentTouch &&
          typeof document !== 'undefined' &&
          document instanceof window.DocumentTouch))
    ) ||
    !!(
      typeof navigator !== 'undefined' &&
      (navigator.maxTouchPoints || navigator.msMaxTouchPoints)
    )
  );
}

const cachedIsTouchDevice = checkIsTouchDevice();

function isTouchDevice() {
  return cachedIsTouchDevice;
}

function isWebView() {
  return typeof window !== 'undefined'
    ? isUAWebview(window.navigator.userAgent)
    : false;
}

const device = {
  isTouchDevice,
  isWebView
};

export default device;
