import styled from '@emotion/styled';
import { css } from '@emotion/react';

import fonts from '../../../../shared/fonts';
import colors from '../../../../shared/colors';
import { space } from '../../../../shared/spacing';
import { mediaQueries } from '../../../../shared/breakpoints';
import CloseIcon from '../../../../icons/Close';
import Star from '../../../../icons/Star';

export const Banner = styled.section`
  position: relative;
  background-color: ${colors.blue};
  color: ${colors.white};
  width: 100%;
  height: 5.6rem;
  display: flex;
  font-size: ${fonts.sizes.xs};

  ${mediaQueries.md(css`
    height: 4rem;
  `)};

  ${mediaQueries.xl(css`
    display: none;
  `)};
`;

export const Logo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: ${space.x2};
  margin-right: ${space.x2};
`;

export const LogoImage = styled.img`
  width: 32px;
  height: 32px;

  ${mediaQueries.md(css`
    width: 24px;
    height: 24px;
  `)}
`;

export const MainSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: ${space.x1};

  p {
    margin-bottom: ${space.x05};
  }

  ${mediaQueries.md(css`
    flex-direction: row;
    align-items: center;

    p {
      margin-right: ${space.x1};
    }
  `)};
`;

export const AppCallToAction = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  a {
    text-decoration: underline ${colors.white};
    color: inherit;
    font-size: inherit;
    font-family: ${fonts.GothamMedium};
    margin-left: ${space.x1};
  }

  ${mediaQueries.md(css`
    margin-top: ${space.x1};
  `)};
`;

export const ImageCallToAction = styled(Star)`
  width: 0.8rem;
  height: 0.8rem;
`;

export const CloseButton = styled.button`
  background: none;
  border: 0;
  color: inherit;
  position: absolute;
  top: ${space.x1};
  right: ${space.x2};

  ${mediaQueries.md(css`
    top: ${space.x15};
  `)};
`;

export const StyledCloseIcon = styled(CloseIcon)`
  width: 1.2rem;
  height: 1.2rem;

  :hover {
    color: ${colors.greyLight};
  }
`;
