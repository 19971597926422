import Tracking from '@spotahome/soyuz-tracking';

const MENU_SECTION_CLICKED = 'MenuSectionClicked';

export const trackMenuSectionClicked = (id, user, locale) => {
  Tracking.segment.trackEvent(MENU_SECTION_CLICKED, user, {
    user_id: user.id,
    section: id,
    locale
  });
};
