import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  store: {
    height: '42px',
    [theme.breakpoints.up('md')]: {
      height: '30px'
    }
  }
}));
