import { Children, cloneElement, isValidElement } from 'react';

export function measureDomNode(node, style) {
  if (typeof document === 'undefined') {
    return { width: 0, height: 0 };
  }

  const container = document.createElement('div');

  container.style = style;

  const clonedNode = node.cloneNode(true);

  container.appendChild(clonedNode);
  document.body.appendChild(container);

  const width = container.clientWidth;
  const height = container.clientHeight;

  container.parentNode.removeChild(container);

  return { width, height };
}

export function getScreenScrollPosition() {
  if (typeof window === 'undefined' || typeof document === 'undefined') {
    return { top: 0, left: 0 };
  }

  const doc = document.documentElement;
  const left = (window.pageXOffset || doc.scrollLeft) - (doc.clientLeft || 0);
  const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);

  return { top, left };
}

export function getWindowBounds() {
  if (typeof window === 'undefined') {
    return { width: 0, height: 0 };
  }

  return {
    width: window.innerWidth,
    height: window.innerHeight
  };
}

export const getTextWidthForInput = text => {
  if (typeof document === 'undefined') {
    return 0;
  }
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  context.font = "16px 'Gotham Book'";
  const metrics = context.measureText(text);
  return metrics.width;
};

export function filterChildrenByComponent(children, filterFn, props) {
  return Children.map(children, child => {
    if (filterFn(child)) {
      return cloneElement(child, Object.assign({}, props, child.props));
    }

    if (child && child.props && child.props.children) {
      const matches = filterChildrenByComponent(
        child.props.children,
        filterFn,
        props
      ).filter(comp => comp !== null);
      return matches[0];
    }

    return null;
  });
}

export function isElementType(ComponentElement) {
  return node =>
    isValidElement(node) &&
    (node.type === ComponentElement || node.props.type === ComponentElement);
}
