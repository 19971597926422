import { css } from '@emotion/react';
import { lighten } from 'polished';

import normalize from './normalize';
import fonts from './fonts';
import colors from './colors';
import { space, border } from './spacing';
import { borderGreyLight } from './borders';
import { boxShadow } from './shadows';

export const globalStyles = css`
  ${normalize};
  ${fonts.faces};

  html {
    box-sizing: border-box;
    font-size: ${fonts.sizes.base};
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  body {
    padding: 0;
    margin: 0;
    overflow-x: hidden;
    font-family: ${fonts.Gotham};
    font-size: ${fonts.sizes.m};
    color: ${colors.greyDark};
    fill: ${colors.greyDark};
  }

  strong {
    font-family: ${fonts.GothamMedium};
  }
`;

export const screenReaderOnly = css`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
`;

export const screenReaderOnlyFocusable = css`
  &:active,
  &:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }
`;

export const sharedInput = () => css`
  position: relative;
  width: 100%;
  min-height: 5.6rem;
  padding: ${space.x2};
  ${borderGreyLight(0.5)};
  border-radius: ${border.radius};
  background-color: ${colors.white};
  font-family: ${fonts.Gotham};
  font-size: ${fonts.sizes.m};
  color: ${colors.greyDark};
  cursor: pointer;
  ${boxShadow.styles};

  &::placeholder {
    color: ${colors.greySeparator};
  }

  & + & {
    margin-top: ${space.x2};
  }
`;

export const focusInput = () => css`
  &:focus {
    ${borderGreyLight(1)};
    outline: 0;
    background-color: ${lighten(0.19, colors.greyLight)};
  }
`;

export const disabledInput = () => css`
  &:disabled {
    opacity: 0.75;
    cursor: not-allowed;
  }
`;

export const errorInput = () => css`
  &[aria-invalid='true'] {
    border-color: ${colors.error};
  }
`;

export const aspectRatioBox = ({ ratio = 1 / 1 }) => css`
  &:before {
    content: '';
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: ${ratio === 0 ? 100 : 100 / ratio}%;
  }

  &:after {
    content: '';
    display: table;
    clear: both;
  }
`;
