import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import fonts from '../../../../shared/fonts';
import colors from '../../../../shared/colors';
import { space } from '../../../../shared/spacing';
import { mediaQueries } from '../../../../shared/breakpoints';

import Avatar from '../Avatar';

const Container = styled.div`
  display: flex;
`;

const UserInfo = styled.div`
  display: none;
  flex-direction: column;
  margin-left: ${space.x1};
  justify-content: center;
  color: ${colors.greyDark};
  line-height: 1.3;

  ${mediaQueries.xl(css`
    display: flex;
  `)};
`;

const Name = styled.strong`
  font-size: ${fonts.sizes.s};
`;

const Email = styled.span`
  font-size: ${fonts.sizes.xs};
`;

const UserDetails = ({ name, email, pictureUrl = null, className = null }) => (
  <Container className={className}>
    {name && <Avatar pictureUrl={pictureUrl} username={name} />}
    <UserInfo>
      <Name>{name}</Name>
      <Email>{email}</Email>
    </UserInfo>
  </Container>
);

UserDetails.propTypes = {
  /** Name of the user  */
  name: PropTypes.string.isRequired,
  /** Email of the user */
  email: PropTypes.string.isRequired,
  /** Avatar picture of the user */
  pictureUrl: PropTypes.string,
  /** Classname to extend styles */
  className: PropTypes.string
};

export default UserDetails;
