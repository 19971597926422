import PropTypes from 'prop-types';

import colors from '../../shared/colors';

import Spinner from '../Spinner';

import { StyledButton } from './styles';

const SPINNER_COLOR = {
  primary: colors.white,
  secondary: colors.white,
  tertiary: colors.primary
};

function RedesignButton({
  children,
  type = 'primary',
  size = 'default',
  loading = false,
  disabled = false,
  startIcon = null,
  isFormSubmit = false,
  fullWidth = false,
  className = '',
  ...rest
}) {
  return (
    <StyledButton
      {...rest}
      type={isFormSubmit ? 'submit' : 'button'}
      typeProp={type}
      startIcon={loading ? null : startIcon}
      disabled={loading || disabled}
      size={size}
      fullWidth={fullWidth}
      className={className}
    >
      {loading ? <Spinner size="20px" color={SPINNER_COLOR[type]} /> : children}
    </StyledButton>
  );
}

RedesignButton.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'text']),
  size: PropTypes.oneOf(['small', 'default', 'big']),
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  startIcon: PropTypes.node,
  isFormSubmit: PropTypes.bool,
  fullWidth: PropTypes.bool,
  className: PropTypes.string
};

export default RedesignButton;
