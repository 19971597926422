import { useState, cloneElement } from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import Collapse from '@mui/material/Collapse';

import Typography from '../../../../../../../components/Typography';

import NotificationItemStyles, { COLORS, ICON_COLOR } from './styles';

const NotificationItem = ({
  color,
  onClose = null,
  notificationIcon = null,
  onClick,
  text
}) => {
  const classes = NotificationItemStyles();
  const [open, setOpen] = useState(true);

  const handleOnClose = () => {
    if (onClose) {
      onClose();
    }

    setOpen(false);
  };

  return (
    <>
      <Collapse in={open}>
        <div
          className={`${classes.notificationWrapper} ${classes[color]}`}
          onClick={onClick}
          role="button"
          tabIndex={0}
        >
          {notificationIcon && (
            <div
              className={classes.iconWrapper}
              style={{ ...ICON_COLOR[color] }}
            >
              {cloneElement(notificationIcon)}
            </div>
          )}
          <div className={classes.textWrapper}>
            <Typography
              variant="ParagraphSShort"
              color={COLORS[color].color}
              isHTML
            >
              {text}
            </Typography>
          </div>

          {onClose && (
            <CloseIcon
              onClick={handleOnClose}
              className={classes.closeButton}
            />
          )}
        </div>
      </Collapse>
    </>
  );
};

NotificationItem.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary', 'tertiary']).isRequired,
  onClose: PropTypes.func,
  notificationIcon: PropTypes.node,
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired
};

export default NotificationItem;
