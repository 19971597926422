import PropTypes from 'prop-types';

import useStyles from './styles';

const PrecisionHotspot = ({
  wrapperClassname = undefined,
  ledClassName = undefined,
  position = 'right',
  isHidden = false,
  children
}) => {
  const classes = useStyles();
  const hotspotClassNames = `${classes.hotspotPrecision.led} ${
    classes.hotspotPrecision[position]
  } ${isHidden ? classes.hotspotPrecision.hidden : ''} ${ledClassName || ''}`;
  return (
    <div
      className={`${classes.hotspotPrecision.root} ${wrapperClassname || ''}`}
      data-testid="precision-hotspot"
    >
      {children}
      <span className={hotspotClassNames} />
    </div>
  );
};

PrecisionHotspot.propTypes = {
  wrapperClassname: PropTypes.string,
  ledClassName: PropTypes.string,
  position: PropTypes.oneOf(['left', 'right']),
  isHidden: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.array
  ]).isRequired
};

export default PrecisionHotspot;
