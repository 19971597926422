const LinkedInIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="22"
    viewBox="0 0 42.3 36"
  >
    <path d="M36.5,0H5.8C4.3,0,3.1,1.2,3.1,2.6v30.8c0,1.4,1.2,2.6,2.7,2.6h30.7c1.5,0,2.7-1.2,2.7-2.6V2.6 C39.1,1.2,37.9,0,36.5,0z M13.8,30.7H8.5V13.5h5.3V30.7z M11.1,11.1C9.4,11.1,8,9.8,8,8.1C8,6.3,9.4,5,11.1,5 c1.7,0,3.1,1.4,3.1,3.1C14.2,9.8,12.9,11.1,11.1,11.1z M33.8,30.7h-5.3v-8.4c0-2,0-4.6-2.8-4.6c-2.8,0-3.2,2.2-3.2,4.4v8.5h-5.3 V13.5h5.1v2.3h0.1c0.7-1.4,2.5-2.8,5.1-2.8c5.4,0,6.4,3.6,6.4,8.2V30.7z" />
  </svg>
);

export default LinkedInIcon;
