import { makeStyles } from '@mui/styles';

const HistoricalMetrics = makeStyles(theme => ({
  wrapper: {
    background: 'var(--sah-secondary)',
    padding: '42px 24px 0',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      padding: '42px'
    }
  },
  content: {
    width: '100%',
    display: 'flex',
    margin: 'auto',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    maxWidth: '1440px',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row'
    }
  },
  item: {
    textAlign: 'center',
    color: 'var(--sah-dark)',
    padding: '0 12px 0 0',
    [theme.breakpoints.up('md')]: {
      paddingLeft: '12px'
    }
  },
  number: {
    marginBottom: 0,
    [theme.breakpoints.up('md')]: {
      marginBottom: '8px'
    }
  },
  text: {
    textTransform: 'uppercase',
    marginBottom: '42px',
    [theme.breakpoints.up('md')]: {
      marginBottom: 0
    }
  }
}));

export default () => ({
  historicalMetrics: HistoricalMetrics()
});
