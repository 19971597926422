import PropTypes from 'prop-types';

import * as S from './styles';

const getInitials = (username = '') => {
  const [firstName, secondName] = username.split(' ');
  return [firstName, secondName]
    .map(name => (name ? name.charAt(0) : ''))
    .join('');
};

const Avatar = ({ pictureUrl = null, username }) => (
  <S.Container>
    {pictureUrl ? (
      <S.Picture url={pictureUrl} />
    ) : (
      <S.Username>{getInitials(username)}</S.Username>
    )}
  </S.Container>
);

Avatar.propTypes = {
  /** The picture URL  */
  pictureUrl: PropTypes.string,
  /** The fallback if no picture is given */
  username: PropTypes.string.isRequired
};

export default Avatar;
