import PropTypes from 'prop-types';

import BaseIcon from './Base';

const HamburgerIcon = ({ color = 'white', ...props }) => (
  <BaseIcon width="16" height="14" {...props}>
    <rect width="16" height="2" fill={color} />
    <rect y="6" width="16" height="2" fill={color} />
    <rect y="12" width="16" height="2" fill={color} />
  </BaseIcon>
);

HamburgerIcon.propTypes = {
  color: PropTypes.string
};

export default HamburgerIcon;
