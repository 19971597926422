import fonts from '../../shared/fonts';

const { Spotahome } = fonts;

const icons = {
  leftArrow: {
    code: 'ea16',
    font: Spotahome
  },
  rightArrow: {
    code: 'ea61',
    font: Spotahome
  },
  angleUp: {
    code: 'ea81',
    font: Spotahome
  },
  angleDown: {
    code: 'ea82',
    font: Spotahome
  }
};

export default icons;
