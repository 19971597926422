import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: '32px',
    '&:last-child': {
      marginBottom: 0
    },
    [theme.breakpoints.up('sm')]: {
      flexBasis: 'calc((100% - 32px) / 2)'
    },
    [theme.breakpoints.up('lg')]: {
      flexBasis: 'calc((1088px - 96px) / 4)',
      marginBottom: 0
    }
  },
  list: {
    display: 'flex',
    margin: 0,
    flexDirection: ({ isInline }) => (isInline ? 'row' : 'column'),
    listStyle: 'none',
    padding: 0,
    '& li': {
      listStyleType: 'none',
      marginBottom: '12px',
      marginRight: ({ isInline }) => (isInline ? '8px' : 0)
    }
  },
  title: {
    marginTop: '16px'
  }
}));
