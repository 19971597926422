import { Button, TrustpilotReviews } from '@spotahome/ui-library';
import { trans, getPagePath, getResourcePath } from '@spotahome/soyuz/client';
import Typography from '@spotahome/landlord-panel-ui-library/src/components/Typography';

import {
  trackLandlordJoinCta,
  trackLandlordAccessCta
} from '../../utils/track';

import useStyles from './styles';

const HeroSection = () => {
  const sendJoinTodayGaEvent = () => {
    trackLandlordJoinCta();
  };

  const sendAccessPanelGaEvent = () => {
    trackLandlordAccessCta();
  };

  const classes = useStyles();

  return (
    <section className={classes.hero.hero}>
      <div className={classes.hero.wrapper}>
        <div className={classes.hero.content}>
          <div className={classes.hero.heading}>
            <Typography variant="TagL">
              {trans('lister.landing.page-header-alt')}
            </Typography>
          </div>

          <div className={classes.hero.titleMobile}>
            <Typography variant="DisplayM">
              {trans('lister.landing.experiment.copies.v1.page-title')}
            </Typography>
          </div>
          <div className={classes.hero.titleDesktop}>
            <Typography variant="DisplayL">
              {trans('lister.landing.experiment.copies.v1.page-title')}
            </Typography>
          </div>

          <p className={classes.hero.subtitle}>
            {trans('lister.landing.experiment.copies.v1.page-subtitle')}
          </p>

          <Button
            data-test="landlord-cta"
            className={classes.hero.cta}
            size="big"
            color="primary"
            href={getPagePath('landlord.signup')}
            onClick={sendJoinTodayGaEvent}
          >
            {trans('lister.landing.experiment.copies.v1.signup')}
          </Button>
          <a
            className={classes.hero.loginLink}
            href={getPagePath('landlord.login')}
            onClick={sendAccessPanelGaEvent}
          >
            {trans('lister.landing.alternative.signin')}
          </a>
          <TrustpilotReviews theme="minimal" className={classes.hero.rating} />
        </div>
      </div>
      <div className={classes.heroVideo.wrapper}>
        <img
          src={getResourcePath('/images/new-landlord-cover.jpg')}
          loading="lazy"
          alt={trans('lister.landing.alternative.page-title')}
          className={classes.heroVideo.cover}
        />
        <div className={classes.heroVideo.container}>
          <video
            autoPlay
            loop
            playsInline
            muted
            className={classes.heroVideo.player}
          >
            <source src={getResourcePath('/videos/landlord-landing.mp4')} />
          </video>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
