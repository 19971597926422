import { css } from '@emotion/react';

const base = 10;

// Font sizes
const sizes = {
  base: `${base}px`,
  xxs: '1rem',
  xs: '1.2rem',
  s: '1.4rem',
  m: '1.6rem',
  l: '1.7rem',
  xl: '2rem',
  xxl: '2.4rem',
  xxxl: '2.8rem',
  xxxxl: '5.6rem'
};

// Font names
const names = {
  GothamLight: "'Gotham Light', 'PoppinsLight-fallback'",
  Gotham: "'Poppins', 'Poppins-fallback'",
  GothamMedium: "'PoppinsMedium', 'Poppins-fallback'",
  GothamBold: "'Gotham Bold', 'PoppinsBold-fallback'",
  GothamLightItalic: "'Gotham Light Italic', 'PoppinsLight-fallback'",
  Spotahome: "'Spotahome Family Regular'"
};

// Font faces
const faces = css`
  @font-face {
    font-family: 'Gotham Light';
    src: url('/fonts/Poppins-ExtraLight.ttf') format('truetype');
    font-style: normal;
    font-weight: 200;
  }

  @font-face {
    font-family: 'Gotham Book';
    src: url('/fonts/Poppins-Regular.ttf') format('truetype');
    font-style: normal;
    font-weight: 400;
  }

  @font-face {
    font-family: 'Gotham Medium';
    src: url('/fonts/Poppins-Medium.ttf') format('truetype');
    font-style: normal;
    font-weight: 600;
  }

  @font-face {
    font-family: 'Gotham Bold';
    src: url('/fonts/Poppins-Bold.ttf') format('truetype');
    font-style: normal;
    font-weight: 700;
  }

  @font-face {
    font-family: 'Gotham Light Italic';
    src: url('/fonts/Poppins-ExtraLightItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: 200;
  }

  @font-face {
    font-family: ${names.Spotahome};
    src: url('/fonts/spotahome-family-Regular.eot');
    src: url('/fonts/spotahome-family-Regular.eot?#iefix')
        format('embedded-opentype'),
      url('/fonts/spotahome-family-Regular.woff') format('woff'),
      url('/fonts/spotahome-family-Regular.ttf') format('truetype'),
      url('/fonts/spotahome-family-Regular.svg#6a327a217ddd10461b1acdc4d224fee0')
        format('svg');
    font-style: normal;
    font-weight: normal;
  }
  @font-face {
    font-family: 'Poppins';
    src: url('/fonts/Poppins-Regular.ttf') format('truetype');
    font-style: normal;
    font-weight: 400;
  }
  @font-face {
    font-family: 'PoppinsLight';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLDz8Z1xlFQ.woff2)
      format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
  }

  @font-face {
    font-family: 'PoppinsMedium';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLGT9Z1xlFQ.woff2)
      format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
  }

  @font-face {
    font-family: 'Agrandir';
    src: url('https://static.spotahome.com/fonts/Agrandir-Regular.otf');
    src: url('https://static.spotahome.com/fonts/Agrandir-Regular.eot#iefix')
        format('embedded-opentype'),
      url('https://static.spotahome.com/fonts/Agrandir-Regular.woff')
        format('woff'),
      url('https://static.spotahome.com/fonts/Agrandir-Regular.ttf')
        format('truetype'),
      url('https://static.spotahome.com/fonts/Agrandir-Regular.woff2')
        format('woff2');
    font-style: normal;
    font-display: swap;
    font-weight: 400;
  }

  @font-face {
    font-family: 'Agrandor';
    src: local('Verdana');
    ascent-override: 105%;
    descent-override: 50%;
    line-gap-override: 8%;
    size-adjust: 92%;
  }

  @font-face {
    font-family: 'Poppins-fallback';
    src: local('Verdana'), local('Arial');
    ascent-override: 105%;
    descent-override: 35%;
    line-gap-override: 10%;
    size-adjust: 92%;
  }
  @font-face {
    font-family: 'PoppinsBold-fallback';
    src: local('Verdana'), local('Arial');
    font-weight: 700;
  }

  @font-face {
    font-family: 'PoppinsLight-fallback';
    src: local('Verdana'), local('Arial');
    font-weight: 300;
  }
`;

const lineHeights = {
  small: 1.2,
  regular: 1.5
};

const fonts = {
  sizes,
  faces,
  lineHeights,
  ...names
};

export default fonts;
