import styled from '@emotion/styled';

import { space } from '../../shared/spacing';
import { pixelsToRem } from '../../utils/units';

import Close from '../../icons/Close';

const TEXT_COLOR = '#fff';
const BG_COLOR = '#133f54';
const BUTTON_COLOR = '#00adff';

const RichCardWrapper = styled.div`
  width: 100%;
`;

const RichCardContentContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: ${({ background }) => background || BG_COLOR};
  border-radius: ${space.x05};
  box-shadow: 0 0 ${space.x075} 0 rgba(0, 0, 0, 0.2);
  color: ${TEXT_COLOR};
  padding: ${space.x2};
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-bottom: ${pixelsToRem(14)};

  ${({ isTitle }) =>
    isTitle
      ? `font-weight: bolder;
      font-size: ${pixelsToRem(14)};`
      : `font-size: ${pixelsToRem(12)};`}

  ${({ topMargin }) => topMargin && `margin: ${pixelsToRem(5)} 0 0;`}

  ${({ bottomMargin }) => bottomMargin && `margin: 0 0 ${pixelsToRem(5)};`}

  ${({ noMargin }) => noMargin && `margin: 0;`}
`;

const CellSpace = styled.div`
  flex: 1;
`;

const CloseIcon = styled(Close)`
  position: absolute;
  top: ${space.x2};
  right: ${space.x2};
  width: ${space.x2};
  height: ${space.x2};
  cursor: pointer;
`;

const Button = styled.button`
  border: none;
  border-radius: ${space.x05};
  background-color: ${BUTTON_COLOR};
  text-align: center;
  font-weight: bolder;
  color: ${TEXT_COLOR};
  padding: ${space.x075} ${space.x2};
  cursor: pointer;
`;

export default {
  RichCardWrapper,
  RichCardContentContainer,
  Row,
  CellSpace,
  CloseIcon,
  Button
};
