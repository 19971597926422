const MoneyBoxIcon = () => (
  <svg
    width="89"
    height="88"
    viewBox="0 0 89 88"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask id="mask0_1275_5656" x="0" y="0" width="89" height="88">
      <path
        d="M72.6666 0H16.6666C7.83007 0 0.666626 7.16344 0.666626 16V72C0.666626 80.8366 7.83007 88 16.6666 88H72.6666C81.5032 88 88.6666 80.8366 88.6666 72V16C88.6666 7.16344 81.5032 0 72.6666 0Z"
        fill="#FFCCEF"
      />
    </mask>
    <g mask="url(#mask0_1275_5656)">
      <path
        d="M72.6666 0H16.6666C7.83007 0 0.666626 7.16344 0.666626 16V72C0.666626 80.8366 7.83007 88 16.6666 88H72.6666C81.5032 88 88.6666 80.8366 88.6666 72V16C88.6666 7.16344 81.5032 0 72.6666 0Z"
        fill="#FB6E44"
      />
      <rect x="18.2667" y="43.9999" width="70.4" height="44" fill="#FFCCEF" />
      <path
        d="M18.2666 43.9999L0.666626 87.9999H35.8666L18.2666 43.9999Z"
        fill="white"
      />
      <rect
        x="35.8663"
        y="50.4167"
        width="52.8"
        height="12.65"
        fill="#0A0777"
      />
      <path
        d="M77.3952 48.5441C74.9808 42.5521 69.1218 38.3163 62.2654 38.3163C55.4089 38.3163 49.5483 42.5504 47.1355 48.5441C46.378 50.4261 45.9503 52.4779 45.9503 54.6308C45.9503 57.7223 46.827 60.6033 48.3207 63.0663H76.2133C77.707 60.6033 78.5837 57.7223 78.5837 54.6308C78.5837 52.4779 78.1559 50.4261 77.3985 48.5441H77.3952Z"
        fill="white"
      />
    </g>
  </svg>
);

export default MoneyBoxIcon;
