import { trans } from '@spotahome/soyuz/client';

import { getDisplayName } from '@spotahome/landlord-panel-ui-library/src/utils/react';

function withTranslations(WrappedComponent) {
  const Component = props => <WrappedComponent trans={trans} {...props} />;

  Component.displayName = `withTranslations(${getDisplayName(
    WrappedComponent
  )})`;

  return Component;
}

export default withTranslations;
