import device from '../../../../shared/device';

function getShowAppBanner() {
  try {
    let show = JSON.parse(sessionStorage.getItem('showLandlordAppBanner'));

    if (show === null) {
      show = true;
    }

    if (show) {
      sessionStorage.setItem('showLandlordAppBanner', 'false');
    }

    return show;
  } catch {
    return false;
  }
}

function deactivateAppBannerSession() {
  try {
    sessionStorage.setItem('showLandlordAppBanner', 'false');
    // eslint-disable-next-line no-empty
  } catch {}
}

function clearShowAppBannerSession() {
  try {
    sessionStorage.removeItem('showLandlordAppBanner');
    // eslint-disable-next-line no-empty
  } catch {}
}

const showAppBanner =
  !device.isWebView() && device.isTouchDevice() && getShowAppBanner();

export { showAppBanner, deactivateAppBannerSession, clearShowAppBannerSession };
