import styled from '@emotion/styled';

const PageContainer = styled.main`
  position: relative;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease-in-out;
`;

export default PageContainer;
