import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  tableButton: {
    height: 34,
    padding: '0 24px !important',
    '&.plus': {
      '& p': {
        color: 'white'
      }
    },
    '&.fullWidth': {
      width: '100%'
    }
  }
});
