const ProfitIcon = () => (
  <svg
    width="88"
    height="88"
    viewBox="0 0 88 88"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask id="mask0_1275_5642" x="0" y="0" width="88" height="88">
      <path
        d="M72 0H16C7.16344 0 0 7.16344 0 16V72C0 80.8366 7.16344 88 16 88H72C80.8366 88 88 80.8366 88 72V16C88 7.16344 80.8366 0 72 0Z"
        fill="#FFCCEF"
      />
    </mask>
    <g mask="url(#mask0_1275_5642)">
      <path
        d="M72 0H16C7.16344 0 0 7.16344 0 16V72C0 80.8366 7.16344 88 16 88H72C80.8366 88 88 80.8366 88 72V16C88 7.16344 80.8366 0 72 0Z"
        fill="#FFCCEF"
      />
      <path
        d="M23.7744 88.1044L16.0002 88.1044C7.16363 88.1044 0.000179917 80.941 0.00018069 72.1044L0.000182021 56.8716L23.7744 56.8716L23.7744 88.1044Z"
        fill="#0A0777"
      />
      <rect
        x="28.9021"
        y="38.2253"
        width="23.7742"
        height="49.8793"
        fill="white"
      />
      <rect
        x="58.27"
        y="20.0451"
        width="23.7742"
        height="68.0596"
        fill="#FB6E44"
      />
      <path d="M70.1573 0L88.1223 23.7742H52.1923L70.1573 0Z" fill="#FB6E44" />
    </g>
  </svg>
);

export default ProfitIcon;
