import PropTypes from 'prop-types';

const QualityIcon = ({ className = '', color = '#FFCCEF' }) => (
  <svg
    width="61"
    height="60"
    viewBox="0 0 61 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M12.3333 0C12.3333 9.94848 20.3849 18 30.3333 18C40.2818 18 48.3333 9.94848 48.3333 0H12.3333Z"
      fill={color}
    />
    <path
      d="M30.3333 18L35.2908 34.0426H51.3333L38.3546 43.9574L43.3121 60L30.3333 50.0851L17.3546 60L22.3121 43.9574L9.33334 34.0426H25.3759L30.3333 18Z"
      fill="#FB6E44"
    />
  </svg>
);

export default QualityIcon;

QualityIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string
};
