import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import fonts from '../../../../shared/fonts';
import { space } from '../../../../shared/spacing';
import { mediaQueries } from '../../../../shared/breakpoints';

import Close from '../../../../icons/Close';
import Lens from '../../../../icons/Lens';
import SearchInput from '../../../../components/SearchInput';
import colors from '../../../../shared/colors';

import { noop } from '../../../../utils/fn';

import BackButton from './BackButton';

const BackButtonContainer = styled.div`
  position: absolute;
  left: 0;
  padding-left: ${space.x2};

  ${mediaQueries.md(css`
    padding-left: 0;
  `)};

  ${mediaQueries.xl(css`
    position: relative;
    margin-bottom: ${space.x2};
  `)};
`;

const TitleContainer = styled.div`
  text-align: center;

  ${props =>
    mediaQueries.md(css`
      display: ${props.centerOnTablet ? 'block' : 'flex'};
      text-align: ${props.centerOnTablet ? 'center' : 'left'};
    `)};

  ${mediaQueries.xl(css`
    display: flex;
    justify-content: flex-start;
    text-align: left;
  `)};
`;

const SearchButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  background: none;
  cursor: pointer;
  outline: none;
  margin-right: 10px;

  &:hover {
    fill: ${colors.primary};
    color: ${colors.primary};
  }

  ${mediaQueries.md(css`
    margin-right: 0;
  `)};
`;

const Title = styled.h2`
  font-size: ${fonts.sizes.xl};
  margin: 0;
`;

const Subtitle = styled.span`
  display: ${({ hideOnMobile }) => (hideOnMobile ? 'none' : 'block')};
  font-size: ${fonts.sizes.xs};

  ${mediaQueries.md(css`
    display: inline-block;
    position: relative;
    margin-left: 1rem;
    top: ${space.x075};
  `)};

  ${mediaQueries.xl(css`
    display: block;
    position: relative;
  `)};
`;

const Header = styled.header`
  position: relative;
  z-index: 1;
`;

const SearchInputContainer = styled.div`
  margin-top: 16px;
  padding: 0 16px;

  ${mediaQueries.md(css`
    padding: 0;
  `)};
`;

const PageHeader = ({
  className = null,
  subtitle = null,
  subtitleHideOnMobile = false,
  backRoute = null,
  withSearch = false,
  onSearchChanged = noop,
  searchPlaceholder = null,
  lastSearch = '',
  searchOpened = false,
  toggleSearchOpened = noop,
  backButtonProps = {},
  searchButtonProps = {},
  searchInputProps = {},
  title
}) => (
  <Header className={className}>
    {backRoute && (
      <BackButtonContainer>
        <BackButton
          label={backRoute.label}
          onClick={backRoute.handleGoBack}
          {...backButtonProps}
        />
      </BackButtonContainer>
    )}
    <TitleContainer centerOnTablet={backRoute !== null}>
      <Title>{title}</Title>
      {subtitle && (
        <Subtitle hideOnMobile={subtitleHideOnMobile}>{subtitle}</Subtitle>
      )}
      {withSearch && (
        <SearchButton
          type="button"
          onClick={() => toggleSearchOpened()}
          {...searchButtonProps}
        >
          {searchOpened ? <Close /> : <Lens />}
        </SearchButton>
      )}
    </TitleContainer>
    {searchOpened && (
      <SearchInputContainer>
        <SearchInput
          autoFocus
          placeholder={searchPlaceholder}
          onChange={event => onSearchChanged(event.target.value)}
          disabled={false}
          value={lastSearch || ''}
          onClearSearchClicked={() => onSearchChanged('')}
          className={'searchInput'}
          {...searchInputProps}
        />
      </SearchInputContainer>
    )}
  </Header>
);

PageHeader.propTypes = {
  /** Class name for extending styles */
  className: PropTypes.string,
  /** Label for the title  */
  title: PropTypes.string.isRequired,
  /** Optional subtitle  */
  subtitle: PropTypes.string,
  /** If the optional subtitle will be hidden on mobile  */
  subtitleHideOnMobile: PropTypes.bool,
  /** Optional back route to render a back button */
  backRoute: PropTypes.shape({
    handleGoBack: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired
  }),
  /** Should have search option */
  withSearch: PropTypes.bool,
  /** Callback when search input value has changed */
  onSearchChanged: PropTypes.func,
  /** Search input placeholder */
  searchPlaceholder: PropTypes.string,
  /** Last search value */
  lastSearch: PropTypes.string,
  /** Search input should be shown */
  searchOpened: PropTypes.bool,
  /** Callback when search (or close search) is clicked */
  toggleSearchOpened: PropTypes.func,
  backButtonProps: PropTypes.object,
  searchButtonProps: PropTypes.object,
  searchInputProps: PropTypes.object
};

export default PageHeader;
