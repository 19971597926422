import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { rgba } from 'polished';

import colors from '../../../../shared/colors';
import fonts from '../../../../shared/fonts';
import { space } from '../../../../shared/spacing';
import { animatedHover } from '../../../../shared/mixins';
import { mediaQueries } from '../../../../shared/breakpoints';
import Icon from '../../../../components/Icon';
import localesShape from '../../shapes/locales';
import { noop } from '../../../../utils/fn';

import {
  getLink,
  LINK_TERMS_AND_CONDITIONS,
  LINK_PRIVACY_POLICIES
} from '../../../../utils/url';

import FooterLink from './FooterLink';

const StyledFooter = styled.footer`
  flex-shrink: 0;
  background-color: ${rgba(colors.darkBlue, 0.05)};
  color: ${colors.grey};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: center;
  font-family: ${fonts.GothamMedium};
  font-size: ${fonts.sizes.s};
  padding: ${space.x2} ${space.x4};
  max-width: 960px;
  margin: 0 auto;

  & > * + * {
    margin-bottom: ${space.x2};
  }

  ${mediaQueries.lg(css`
    flex-direction: row;

    & > * + * {
      margin-bottom: 0;
    }
  `)};
`;

const CopyrightSection = styled.small`
  font-family: ${fonts.Gotham};
  font-size: ${fonts.sizes.s};
  color: ${colors.greyDark};
  text-align: center;
`;

const LinksList = styled.ul`
  color: ${colors.darkBlue};
  padding: 0;
  margin: 0;
  width: 100%;
  text-align: center;

  li {
    display: inline-block;

    &:not(:last-of-type) {
      :after {
        content: ' ';
        padding: 0 ${space.x2};
      }
    }

    > * {
      color: inherit;
      line-height: ${space.x4};
      text-decoration: none;
      ${animatedHover(colors.greyDark)};
    }
  }

  ${mediaQueries.lg(css`
    width: auto;
  `)}
`;

const LanguageContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  label {
    font-size: ${fonts.sizes.xxs};
    position: absolute;
    right: 0;
  }

  select {
    font-family: ${fonts.GothamBold};
    width: 100%;
    outline: none;
    color: ${colors.greyDark};
    background-color: transparent;
    border: none;
    appearance: none;
    text-indent: 1px;
    margin-left: ${space.x1};
    cursor: pointer;
    z-index: 1;

    option {
      font-family: ${fonts.GothamMedium};
      background-color: ${rgba(colors.darkBlue, 0.05)};
    }
  }
`;

const LinksSection = styled.section`
  display: flex;

  ${mediaQueries.lg(css`
    justify-content: flex-end;
  `)}
`;

const Footer = ({ locales, trans, onLanguageChange, onPageExit = noop }) => (
  <StyledFooter>
    <Content>
      <CopyrightSection>
        ©{new Date().getFullYear()} Spotahome —{' '}
        {trans('sahc.footer.copyright.text')}
      </CopyrightSection>

      <LinksSection>
        <LinksList>
          <li>
            <FooterLink
              to={getLink(LINK_TERMS_AND_CONDITIONS, locales.current)}
              onClick={onPageExit}
              id="terms-and-conditions"
            >
              {trans('sahc.footer.links.terms.and.conditions')}
            </FooterLink>
          </li>
          <li>
            <FooterLink
              to={getLink(LINK_PRIVACY_POLICIES, locales.current)}
              onClick={onPageExit}
              id="privacy-policy"
            >
              {trans('sahc.footer.links.privacy.policy')}
            </FooterLink>
          </li>
          <li>
            <LanguageContainer>
              {trans('sahc.footer.language.title')}
              <select
                id="languageSelect"
                defaultValue={locales.current}
                onChange={e => onLanguageChange(e.target.value)}
              >
                {Object.keys(locales.ui).map(locale => (
                  <option key={locale} value={locale}>
                    {locales.ui[locale].name}
                  </option>
                ))}
              </select>
              <label htmlFor="languageSelect">
                <Icon name="angleDown" />
              </label>
            </LanguageContainer>
          </li>
        </LinksList>
      </LinksSection>
    </Content>
  </StyledFooter>
);

Footer.propTypes = {
  /** Translation function */
  trans: PropTypes.func.isRequired,
  /** Callback for language change event */
  onLanguageChange: PropTypes.func.isRequired,
  /** Locales object */
  locales: localesShape.isRequired,
  /** Callback for page exit event */
  onPageExit: PropTypes.func
};

export default Footer;
