import { makeStyles } from '@mui/styles';

const Plans = makeStyles(theme => ({
  section: {
    padding: '0',
    background: 'var(sah--white)',
    marginBottom: 0,
    [theme.breakpoints.up('sm')]: {
      marginBottom: 120
    }
  }
}));

export default () => ({
  plans: Plans()
});
