import { SvgIcon } from '@mui/material';
import PropTypes from 'prop-types';

const CheckBlack = ({ color }) => (
  <SvgIcon viewBox="0 0 16 16" style={{ width: 16, height: 16 }}>
    <path
      fill={color}
      fillRule="evenodd"
      d="M8 16c4.4183 0 8-3.5817 8-8s-3.5817-8-8-8-8 3.5817-8 8 3.5817 8 8 8Zm-1.3351-4.4329.0023-.0024.0024.0025 5.997-5.997-1.2368-1.2368L6.6673 9.096 4.57 6.9986 3.3332 8.2354l3.3317 3.3317Z"
      clipRule="evenodd"
    />
  </SvgIcon>
);

CheckBlack.defaultProps = {
  color: 'var(--sah-dark)'
};

CheckBlack.propTypes = {
  color: PropTypes.string
};
export default CheckBlack;
