import { trans } from '@spotahome/soyuz/client';

const getStructuredFaq = faqs => {
  if (!faqs.length) {
    return null; // No faqs given
  }

  const content = faqs.map(faq => ({
    '@type': 'Question',
    name: faq.top,
    acceptedAnswer: {
      '@type': 'Answer',
      text: faq.content
    }
  }));

  return {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: content
  };
};

const OLD_LANDLORD_FAQ = [
  {
    top: 'landlord.landing.standard.faq.accept-booking.question',
    content: 'landlord.landing.standard.faq.accept-booking.answer',
    eventLabel: 'meet-tt'
  },
  {
    top: 'landlord.landing.standard.faq.pay-to-publish.question',
    content: 'landlord.landing.standard.faq.pay-to-publish.answer',
    eventLabel: 'pay-to-list'
  },
  {
    top: 'landlord.landing.standard.faq.type-of-property.question',
    content: 'landlord.landing.standard.faq.type-of-property.answer',
    eventLabel: 'type-of-properties'
  },
  {
    top: 'landlord.landing.standard.faq.rules-regulations.question',
    content: 'landlord.landing.standard.faq.rules-regulations.answer',
    eventLabel: 'rules-and-regulations'
  }
];

const LANDLORD_FAQ = [
  {
    top: 'landlord.landing.standard.faq.opt-for-plus.question',
    content: 'landlord.landing.standard.faq.opt-for-plus.answer',
    eventLabel: 'what-is-plus'
  },
  {
    top: 'landlord.landing.standard.faq.is-plus-free.question',
    content: 'landlord.landing.standard.is-plus-free.answer',
    eventLabel: 'is-plus-free'
  },
  {
    top: 'landlord.landing.standard.faq.what-plus-offers.question',
    content: 'landlord.landing.standard.faq.what-plus-offers.answer',
    eventLabel: 'offer-of-plus'
  },
  {
    top: 'landlord.landing.standard.faq.where-join-plus.question',
    content: 'landlord.landing.standard.faq.where-join-plus.answer',
    eventLabel: 'where-to-join'
  }
];

export const getLandlordFaq = (translate = trans) =>
  [...OLD_LANDLORD_FAQ, ...LANDLORD_FAQ].map(faq => ({
    top: translate(faq.top),
    content: translate(faq.content),
    eventLabel: faq.eventLabel,
    isOpened: true
  }));

export const getLandlordStructuredFaq = translate =>
  getStructuredFaq(getLandlordFaq(translate));

export default {
  getLandlordFaq,
  getLandlordStructuredFaq
};
