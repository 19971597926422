import BaseIcon from './Base';

const CheckCircledIcon = props => (
  <BaseIcon width="32" height="32" viewBox="0 0 32 32" {...props}>
    <g fill="none" fillRule="evenodd">
      <circle cx="16" cy="16" r="11.5" stroke="currentColor" />
      <path
        fill="currentColor"
        d="M23.616 10a.363.363 0 0 0-.25.114c-3.794 3.803-6.565 6.894-10.297 10.65l-4.462-3.77a.366.366 0 1 0-.477.557l4.723 3.996a.363.363 0 0 0 .5-.022c3.882-3.892 6.668-7.024 10.536-10.9a.363.363 0 0 0-.273-.624z"
      />
    </g>
  </BaseIcon>
);

export default CheckCircledIcon;
