import { makeStyles } from '@mui/styles';

const UniqueSellingPoint = makeStyles(theme => ({
  box: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'var(--sah-white)',
    padding: '20px',
    margin: '10px',
    borderRadius: '20px',
    flex: '1',
    maxWidth: '500px',
    minHeight: '350px',
    [theme.breakpoints.up('sm')]: {
      minHeight: '300px'
    }
  },
  icon: {
    minWidth: '50px',
    width: '50px',
    textAlign: 'center',
    marginRight: '16px',
    marginBottom: '32px',
    [theme.breakpoints.up('sm')]: {
      textAlignLast: 'left'
    }
  },
  description: {
    fontSize: 'var(--sah-font-s)',
    lineHeight: '24px',
    color: 'var(--sah-dark)',
    padding: 0,
    [theme.breakpoints.up('sm')]: {
      padding: '8px 0'
    },
    fontFamily: 'var(--sah-font-title)'
  },
  title: {
    fontFamily: 'var(--sah-font-title)',
    color: 'var(--sah-dark)',
    padding: 0,
    marginTop: 0,
    fontSize: 'var(--sah-font-xxxl)',
    lineHeight: 'var(--sah-line-height-l)',
    fontWeight: 'var(--sah-font-w-light)',
    marginBottom: '16px'
  },
  boxWrapper: {
    display: 'flex',
    justifyContent: 'center'
  }
}));

const UniqueSellingPointSection = makeStyles(theme => ({
  section: {
    width: '100%',
    padding: '48px 24px',
    display: 'flex',
    flexDirection: 'column',
    justifyItems: 'center',
    backgroundColor: 'var(--sah-tertiary)',
    [theme.breakpoints.up('sm')]: {
      padding: '(40px * 2) 48px'
    },
    [theme.breakpoints.up('lg')]: {
      padding: '120px 48px'
    }
  },
  radioGroup: {
    justifyContent: 'center'
  },
  radio: {
    color: 'var(--sah-interaction-dark)',
    '&$checked': {
      color: 'var(--sah-interaction-dark)'
    }
  },
  checked: {},
  container: {
    width: '100%',
    maxWidth: '1440px',
    margin: 'auto'
  },
  title: {
    fontFamily: 'var(--sah-font-title)',
    fontWeight: 'var(--sah-font-w-light)',
    fontSize: 'var(--sah-font-xxxxl)',
    lineHeight: 'var(--sah-line-height-xxxxl)',
    margin: '0 0 40px',

    [theme.breakpoints.up('sm')]: {
      fontSize: 'var(--sah-font-xxxxxxl)',
      lineHeight: 'var(--sah-line-height-xxxxxxl)',
      margin: '0 0 48px'
    },

    [theme.breakpoints.up('lg')]: {
      margin: '0 0 88px',
      fontSize: '64px',
      lineHeight: '72px'
    }
  },
  elements: {
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  },
  cta: {
    fontFamily: 'var(--sah-font-title)',
    fontSize: 'var(--sah-font-xxxl)',
    margin: '25px 10px',
    display: 'flex',
    justifyContent: 'center',
    '& a': {
      minWidth: '250px'
    }
  }
}));

export default () => ({
  uniqueSellingPoint: UniqueSellingPoint(),
  uniqueSellingPointSection: UniqueSellingPointSection()
});
