import BaseIcon from './Base';

const CloseIcon = props => (
  <BaseIcon width="20" height="18" viewBox="0 0 20 18" {...props}>
    <g fill="currentColor" fillRule="evenodd">
      <path d="M18.789.804l.682.731L1.918 17.903l-.682-.731z" />
      <path d="M1.211.804l17.553 16.368-.682.731L.529 1.535z" />
    </g>
  </BaseIcon>
);

export default CloseIcon;
