import { useEffect } from 'react';
import { css, Global } from '@emotion/react';
import { ApolloProvider } from '@apollo/react-hooks';
import {
  getPagePath,
  useSoyuzUser,
  useSoyuzData
} from '@spotahome/soyuz/client';
import { ThemeProvider as ThemeProviderSAH } from '@spotahome/ui-library';
import { Mediator } from '@spotahome/soyuz-mediator';
import createApolloClient from '@spotahome/properties-common/src/graphql/client';
import { setDefaultLocale } from '@spotahome/landlord-panel-ui-library/src/utils/dates';
import GoogleOneTap from '@spotahome/landlord-panel-ui-library/src/components/GoogleOneTap';
import ThemeProvider from '@spotahome/landlord-panel-ui-library/src/components/ThemeProvider';

import { StaticRouter } from 'react-router';

import { useLocaleChange } from './hooks/useLocaleChange';
import { getLandlordFaq, getLandlordStructuredFaq } from './utils/faq';

import Layout from './components/Layout';
import ServicePlans from './components/ServicePlans';
import FeatureBoxSection from './components/FeatureBoxSection';
import HeroSection from './components/HeroSection';
import PlusSection from './components/PlusSection';
import HistoricalMetrics from './components/HistoricalMetrics';
import FAQSection from './components/FAQSection';
import Footer from './components/Footer';
import UniqueSellingPointSection from './components/UniqueSellingPointSection';
import StepsToPublishSection from './components/StepsToPublishSection';

const navigateToLandlord = (payload = {}) => {
  if (payload.initialAction === 'signup') {
    window.location.href = getPagePath('landlord.login');
  } else {
    window.location.href = getPagePath('landlord.login');
  }
};

const getLandlordStructuredFaqScript = trans => ({
  type: 'application/ld+json',
  innerHTML: JSON.stringify(getLandlordStructuredFaq(trans))
});

const RouterContent = () => {
  const handleOnLocaleChange = useLocaleChange();
  return (
    <Layout onLanguageChange={handleOnLocaleChange}>
      <HeroSection />
      <HistoricalMetrics />
      <FeatureBoxSection />
      <UniqueSellingPointSection />
      <StepsToPublishSection />
      <ServicePlans />
      <FAQSection questions={getLandlordFaq()} />
      <PlusSection />
      <Footer />
      <GoogleOneTap />
    </Layout>
  );
};

const LandingPage = () => {
  const {
    LandlordLandingName: { getGraphqlClient }
  } = useSoyuzData();

  return (
    <ApolloProvider
      client={getGraphqlClient ? getGraphqlClient() : createApolloClient()}
    >
      <StaticRouter location="/">
        <RouterContent />
      </StaticRouter>
    </ApolloProvider>
  );
};

const LandlordLandingPageContainer = () => {
  const user = useSoyuzUser();
  useEffect(() => {
    Mediator.subscribe('AUTH_MODAL_OPEN', navigateToLandlord);

    return () => Mediator.unsubscribe('AUTH_MODAL_OPEN', navigateToLandlord);
  }, []);

  useEffect(() => {
    setDefaultLocale(user.locale);
  }, [user.locale]);

  return (
    <ThemeProviderSAH flags={{ newButtons: true }} theme="spotahome">
      <ThemeProvider>
        <Global
          styles={css`
            html {
              font-size: 16px !important;
              box-sizing: border-box;
            }

            *,
            *:before,
            *:after {
              box-sizing: inherit;
            }

            body {
              margin: 0;
            }

            html,
            body {
              font-family: var(--sah-font-regular);
              line-height: var(--sah-line-height-m);
            }
          `}
        />
        <LandingPage />
      </ThemeProvider>
    </ThemeProviderSAH>
  );
};

LandlordLandingPageContainer.requestAllData = async (
  req,
  _,
  { graphqlClient }
) => ({
  headers: {
    scripts: [getLandlordStructuredFaqScript(req.trans)]
  },
  data: {
    getGraphqlClient: () => graphqlClient
  }
});

LandlordLandingPageContainer.NAME = 'LandlordLandingName';

export default LandlordLandingPageContainer;
