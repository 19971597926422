import { trans } from '@spotahome/soyuz/client';
import { styled } from '@mui/material/styles';
import AccordioMUI from '@mui/material/Accordion';
import AccordionDetailsMUI from '@mui/material/AccordionDetails';
import Typography from '@spotahome/landlord-panel-ui-library/src/components/Typography';

import { trackLandlordStepsToPublishClicked } from '../../utils/track';

import AccordionHeader from './AccordionHeader';
import AccordionPaymentsContent from './AccordionPaymentsContent';

const Container = styled('div')(({ theme }) => ({
  padding: 20,
  [theme.breakpoints.up('sm')]: {
    padding: 0
  }
}));

const AccordionDetails = styled(AccordionDetailsMUI)(({ theme }) => ({
  display: 'flex',
  padding: '0 20px 20px',
  marginBottom: '32px',
  [theme.breakpoints.up('sm')]: {
    padding: '0 56px 56px',
    marginBottom: 0
  }
}));

const Accordion = styled(AccordioMUI)(
  ({ theme, isFirst, isMiddle, isLast }) => ({
    position: 'relative',
    margin: 0,
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
    boxShadow: 'none',
    '&::before': {
      opacity: 0
    },
    [theme.breakpoints.up('sm')]: {
      width: 890
    },
    ...(isFirst && {
      backgroundColor: 'var(--sah-secondary-blue-light)'
    }),
    ...(isMiddle && {
      backgroundColor: 'var(--sah-secondary-light)',
      top: -35
    }),

    ...(isLast && {
      backgroundColor: 'var(--sah-tertiary)',
      borderRadius: 24,
      top: -66
    })
  })
);

const StepsToPublishAccordion = () => {
  const accordionElementClicked = step => (event, newExpanded) => {
    if (newExpanded) {
      trackLandlordStepsToPublishClicked(step);
    }
  };

  return (
    <Container>
      <Accordion square isFirst onChange={accordionElementClicked('step1')}>
        <AccordionHeader position="1">
          {trans('landing.landlord.section.stepsTpPublish.text3')}
        </AccordionHeader>
        <AccordionDetails>
          <Typography variant="ParagraphXL">
            {trans('landing.landlord.section.stepsTpPublish.text4')}
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion square isMiddle onChange={accordionElementClicked('step2')}>
        <AccordionHeader position="2">
          {trans('landing.landlord.section.stepsTpPublish.text5')}
        </AccordionHeader>
        <AccordionDetails>
          <Typography variant="ParagraphXL">
            {trans('landing.landlord.section.stepsTpPublish.text6')}
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        defaultExpanded
        square
        isLast
        onChange={accordionElementClicked('step3')}
      >
        <AccordionHeader position="3" isLast>
          {trans('landing.landlord.section.stepsTpPublish.text7')}
        </AccordionHeader>
        <AccordionPaymentsContent />
      </Accordion>
    </Container>
  );
};

export default StepsToPublishAccordion;
