import PropTypes from 'prop-types';

const ProtectionIcon = ({ className = '', color = '#0A0777' }) => (
  <svg
    width="61"
    height="60"
    viewBox="0 0 61 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M30.3333 55C23.9681 55 17.8637 52.4714 13.3628 47.9706C8.86191 43.4697 6.33335 37.3652 6.33335 31L54.3333 31C54.3333 37.3652 51.8048 43.4697 47.3039 47.9706C42.803 52.4714 36.6985 55 30.3333 55Z"
      fill="#C9E3F7"
    />
    <rect
      x="54.3333"
      y="31"
      width="48"
      height="24"
      transform="rotate(-180 54.3333 31)"
      fill="#C9E3F7"
    />
    <path
      d="M26.9436 40L26.3412 39.3958L18.3333 31.3635L21.8058 27.8805L26.9081 32.9984L41.8609 18L45.3333 21.483L26.9436 40Z"
      fill={color}
    />
  </svg>
);

export default ProtectionIcon;

ProtectionIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string
};
