import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import fonts from '../../../../shared/fonts';
import Icon from '../../../../components/Icon';
import { space } from '../../../../shared/spacing';
import { mediaQueries, breakpoints } from '../../../../shared/breakpoints';

const Span = styled.span`
  cursor: pointer;
  outline: none;
`;

const StyledIcon = styled(Icon)`
  vertical-align: middle;
  display: inline-block;
  font-size: ${fonts.sizes.xl};
  margin-top: -${space.x025};
  ${mediaQueries.between(
    breakpoints.md,
    breakpoints.xl
  )(css`
    margin-top: 0;
    font-size: ${fonts.sizes.xxl};
  `)};
`;

const Label = styled.span`
  display: none;
  ${mediaQueries.xl(css`
    display: inline;
    vertical-align: middle;
    font-size: ${fonts.sizes.xs};
    margin-left: ${space.x1};
  `)};
`;

const BackButton = ({ label, onClick, ...htmlProps }) => (
  <Span onClick={onClick} tabIndex={0} role="button" {...htmlProps}>
    <StyledIcon name="leftArrow" />
    <Label>{label}</Label>
  </Span>
);

BackButton.defaultProps = {
  label: ''
};

BackButton.propTypes = {
  /** Label for the button  */
  label: PropTypes.string,
  /** Method called when the button is clicked */
  onClick: PropTypes.func.isRequired
};

export default BackButton;
