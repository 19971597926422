import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import PropTypes from 'prop-types';
import { trans, getPagePath } from '@spotahome/soyuz/client';
import { Link } from '@spotahome/ui-library';
import { styled } from '@mui/material/styles';

import { trackLandlordLandingSectionViewed } from '../../utils/track';

import FAQAccordion from './FAQAccordion';

const MAX_FAQ_WIDTH = '670px';

const Section = styled('section')(({ theme }) => ({
  padding: '64px 16px',
  backgroundColor: 'var(--sah-primary-light-blue)',
  [theme.breakpoints.up('lg')]: {
    padding: '64px 32px',
    display: 'flex'
  }
}));

const Wrapper = styled('div')({
  maxWidth: MAX_FAQ_WIDTH,
  margin: 'auto'
});

const Title = styled('div')({
  color: 'var(--sah-interaction-dark)',
  paddingBottom: '24px',
  maxWidth: MAX_FAQ_WIDTH,
  margin: '0 auto',
  fontFamily: 'var(--sah-font-title)',
  fontWeight: 'var(--sah-font-w-regular)'
});

const TitleLink = styled(Link)({
  fontSize: 'var(--sah-font-l)',
  fontWeight: 'var(--sah-font-w-bold)',
  padding: '32px 0',
  textAlign: 'center',
  '& a': {
    textDecoration: 'underline',
    whiteSpace: 'nowrap'
  }
});

const FAQSection = ({ questions }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.5
  });

  useEffect(() => {
    if (inView) {
      trackLandlordLandingSectionViewed('faqs');
    }
  }, [inView]);

  return (
    <Section ref={ref}>
      <Title>
        <h2>{trans('home.tenant.FAQ-section.title.awnser-to-questions')}</h2>
        <TitleLink
          href={getPagePath('externals.help-center')}
          target="_blank"
          rel="noopener noreferrer"
        >
          {`${trans('home.tenant.FAQ-section.footer.title')} ${trans(
            'home.tenant.FAQ-section.footer.link'
          )}`}
        </TitleLink>
      </Title>
      <Wrapper>
        <FAQAccordion data={questions} />
      </Wrapper>
    </Section>
  );
};

FAQSection.propTypes = {
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      top: PropTypes.node.isRequired,
      content: PropTypes.node.isRequired,
      eventLabel: PropTypes.string.isRequired
    })
  ).isRequired
};

export default FAQSection;
