import { memo } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import randomDigits from '../utils/random-digits';

const Svg = styled.svg`
  display: inline-block;
  color: ${({ color }) => color || undefined};
`;

const Base = ({
  width = 32,
  height = 32,
  title = undefined,
  color = undefined,
  children,
  ...props
}) => {
  const uniqueTitleId = title ? `icon-${randomDigits(10)}` : undefined;
  const ariaHidden = title ? undefined : 'true';
  const role = title ? 'img' : undefined;

  return (
    <Svg
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={uniqueTitleId}
      aria-hidden={ariaHidden}
      role={role}
      color={color}
      {...props}
    >
      {title && <title id={uniqueTitleId}>{title}</title>}
      {children}
    </Svg>
  );
};

Base.propTypes = {
  children: PropTypes.node.isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  color: PropTypes.string,
  title: PropTypes.string
};

export default memo(Base);
