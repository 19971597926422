import { trans } from '@spotahome/soyuz/client';
import Box from '@mui/material/Box';
import { Typography } from '@spotahome/ui-library/base';

import RedesignButton from '../../../../components/RedesignButton/RedesignButton';
import PlaceholderLogo from '../../../../icons/PlaceholderLogo';

import useStyles from './styles';

const ErrorSection = () => {
  const classes = useStyles();

  return (
    <Box className={`${classes.errorSection.root}`}>
      <Box className={classes.errorSection.logo}>
        <PlaceholderLogo />
      </Box>
      <Box className={classes.errorSection.title}>
        <Typography.DisplayM>
          {trans('error-section.title')}
        </Typography.DisplayM>
      </Box>
      <Box className={classes.errorSection.description}>
        <Typography.ParagraphMTall>
          {trans('error-section.text')}
        </Typography.ParagraphMTall>
      </Box>
      <Box>
        <RedesignButton type="primary" size="big" href="/properties">
          <Typography.LabelM>
            {trans('error-section.back-button')}
          </Typography.LabelM>
        </RedesignButton>
        <Typography.LabelS>
          <a href="/support">
            <span className={classes.errorSection.link}>
              {trans('error-section.contact-us')}
            </span>
          </a>
        </Typography.LabelS>
      </Box>
    </Box>
  );
};

export default ErrorSection;
