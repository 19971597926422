const AlertIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    style={{ backgroundColor: 'white' }}
  >
    <path
      fill="#D32011"
      d="M12.947 6h-1.894v8.263h1.894V6ZM12 16.41c-.295 0-.537.1-.726.301-.19.2-.284.447-.284.742a1 1 0 0 0 .284.71c.19.2.431.3.726.3s.537-.1.726-.3a1 1 0 0 0 .284-.71c0-.295-.094-.542-.284-.742a.955.955 0 0 0-.726-.3ZM12 0c1.663 0 3.226.316 4.689.947a12.1 12.1 0 0 1 3.806 2.558 12.1 12.1 0 0 1 2.558 3.806C23.684 8.774 24 10.337 24 12c0 1.663-.316 3.226-.947 4.69a12.103 12.103 0 0 1-2.558 3.805 12.098 12.098 0 0 1-3.806 2.558C15.226 23.684 13.663 24 12 24c-1.663 0-3.227-.316-4.69-.947a12.104 12.104 0 0 1-3.805-2.558A12.104 12.104 0 0 1 .947 16.69C.316 15.226 0 13.663 0 12c0-1.663.316-3.226.947-4.689a12.101 12.101 0 0 1 2.558-3.806A12.105 12.105 0 0 1 7.31.947C8.773.316 10.337 0 12 0Z"
    />
  </svg>
);

export default AlertIcon;
