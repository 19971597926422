import { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { useSoyuzLocales, trans, useSoyuzUser } from '@spotahome/soyuz/client';
import { ThemeProvider, SPOTAHOME_THEME } from '@spotahome/ui-library/base';

import { useMediaQuery, Box } from '@mui/material';
import { useTheme } from '@mui/styles';

import { ToastProvider, ToastContainer } from '../../../../components/Toast';
import PageContainer from '../PageContainer';
import { mediaQueries } from '../../../../shared/breakpoints';
import DefaultErrorBoundary from '../DefaultErrorBoundary/DefaultErrorBoundary';

import useStyles from './styles';
import { clearChatUserInfo, setChatUserInfo } from './livechat';
import { clearShowAppBannerSession } from './landlordAppBanner';
import NavBar from './NavBar';
import MobileSideBarMenu from './NavBar/MobileSideBarMenu';

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const PageWrapper = styled.div`
  overflow: auto;
  flex: 1 0 auto;

  ${mediaQueries.xl(css`
    overflow: initial;
  `)};
`;

const MAIN_CONTAINER_ID = 'mainContainer';

const FullWidthLayout = ({
  children,
  currentRoute = '',
  onLanguageChange,
  onLogOutClick = () => {},
  whatsappLink = 'https://wa.me/34911983040',
  isTelephoneVisible = false,
  isWorkingHours = false,
  showDirectContact = true,
  onSectionClick = () => {},
  onWhatsappClick = () => {},
  onDirectContactClick = () => {},
  canShowPlansSection = true,
  highlightPlansSection = true,
  hideAllLinks = false,
  title = null
}) => {
  const user = useSoyuzUser();
  const locales = useSoyuzLocales();
  const muiTheme = useTheme();
  const isSmallScreen = useMediaQuery(muiTheme.breakpoints.down('md'));
  const classes = useStyles();

  useEffect(() => {
    setChatUserInfo(user);
  }, [user]);

  const handleLogout = event => {
    clearChatUserInfo();
    clearShowAppBannerSession();
    onLogOutClick(event);
  };

  const mobileSidebarProps = {
    whatsappLink,
    trans,
    currentRoute,
    locales,
    handleLogout,
    user,
    onLanguageChange,
    onSectionClick,
    onWhatsappClick,
    onDirectContactClick,
    isTelephoneVisible,
    isWorkingHours,
    showDirectContact,
    canShowPlansSection,
    highlightPlansSection,
    hideAllLinks,
    title
  };

  const desktopNavbarProps = {
    trans,
    currentRoute,
    locales,
    handleLogout,
    user,
    onLanguageChange,
    onSectionClick,
    canShowPlansSection,
    highlightPlansSection,
    hideAllLinks
  };

  return (
    <ToastProvider>
      <AppContainer>
        <ThemeProvider theme={SPOTAHOME_THEME}>
          <Box className={classes.fontNormalizer}>
            {isSmallScreen ? (
              <MobileSideBarMenu {...mobileSidebarProps} />
            ) : (
              <NavBar {...desktopNavbarProps} />
            )}
          </Box>
        </ThemeProvider>
        <ToastContainer />
        <PageWrapper>
          <DefaultErrorBoundary>
            <PageContainer>{children}</PageContainer>
          </DefaultErrorBoundary>
        </PageWrapper>
      </AppContainer>
    </ToastProvider>
  );
};

FullWidthLayout.propTypes = {
  /** Current Route for highlight the section */
  currentRoute: PropTypes.string,
  /** Content for the layout */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  /** Callback passed to the Header component */
  onClickBannerAppHeader: PropTypes.func,
  whatsappLink: PropTypes.string,
  isTelephoneVisible: PropTypes.bool,
  isWorkingHours: PropTypes.bool,
  showDirectContact: PropTypes.bool,
  onLogOutClick: PropTypes.func,
  onLanguageChange: PropTypes.func.isRequired,
  onSectionClick: PropTypes.func,
  onWhatsappClick: PropTypes.func,
  onDirectContactClick: PropTypes.func,
  canShowPlansSection: PropTypes.bool,
  highlightPlansSection: PropTypes.bool,
  hideAllLinks: PropTypes.bool,
  title: PropTypes.node
};

export default FullWidthLayout;
export { MAIN_CONTAINER_ID };
