import { makeStyles } from '@mui/styles';

const setColor = (color, fallback = 'var(--sah-interaction-dark)') =>
  color || fallback;

const Typography = makeStyles({
  root: {
    margin: 0
  }
});

const Display = makeStyles({
  root: {
    fontFamily: "'Agrandir', 'Agrandor'",
    fontWeight: '400',
    color: ({ color }) => setColor(color)
  },
  display__xxl: {
    fontSize: '76px',
    lineHeight: '86px'
  },
  display__xl: {
    fontSize: '64px',
    lineHeight: '74px'
  },
  display__l: {
    fontSize: '56px',
    lineHeight: '66px'
  },
  display__m: {
    fontSize: '40px',
    lineHeight: '48px'
  },
  display__s: {
    fontSize: '34px',
    lineHeight: '42px'
  },
  display__xs: {
    fontSize: '26px',
    lineHeight: '34px'
  }
});

const Heading = makeStyles({
  root: {
    fontFamily: "'PoppinsMedium', 'Poppins-fallback'",
    fontWeight: '500',
    color: ({ color }) => setColor(color)
  },
  heading__l: {
    fontSize: '22px',
    lineHeight: '26px'
  },
  heading__m: {
    fontSize: '18px',
    lineHeight: '24px'
  },
  heading__s: {
    fontSize: '16px',
    lineHeight: '20px'
  }
});

const Paragraph = makeStyles({
  root: {
    fontFamily: "'PoppinsLight', 'PoppinsLight-fallback'",
    fontWeight: '300',
    color: ({ color }) => setColor(color)
  },
  paragraph__xl: {
    fontSize: '20px',
    lineHeight: '28px'
  },
  paragraph__l: {
    fontSize: '18px',
    lineHeight: '26px'
  },
  paragraph__m: {
    fontSize: '16px',
    lineHeight: '24px',
    '& b': {
      fontFamily: "'PoppinsMedium', 'Poppins-fallback'",
      fontWeight: '500'
    }
  },
  paragraph__st: {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: '400'
  },
  paragraph__s: {
    fontSize: '14px',
    lineHeight: '24px',
    '& b': {
      fontFamily: "'PoppinsMedium', 'Poppins-fallback'",
      fontWeight: '500'
    }
  },
  paragraph__ss: {
    fontSize: '14px',
    lineHeight: '18px',
    fontWeight: '600'
  },
  paragraph__xs: {
    fontSize: '12px',
    lineHeight: '16px'
  }
});

const Label = makeStyles({
  root: {
    fontFamily: "'PoppinsMedium', 'Poppins-fallback'",
    fontWeight: '500',
    color: ({ color }) => setColor(color)
  },
  label__m: {
    fontSize: '16px',
    lineHeight: '20px'
  },
  label__s: {
    fontSize: '14px',
    lineHeight: '18px'
  }
});

const Tag = makeStyles({
  root: {
    fontFamily: "'PoppinsMedium', 'Poppins-fallback'",
    fontWeight: '500',
    color: ({ color }) => setColor(color)
  },
  tag__l: {
    fontSize: '16px',
    lineHeight: '20px'
  },
  tag__m: {
    fontSize: '12px',
    lineHeight: '16px'
  },
  tag__s: {
    fontSize: '10px',
    lineHeight: '14px',
    color: ({ color }) => setColor(color, 'var(--sah-medium)')
  }
});

export default {
  typography: Typography,
  display: Display,
  heading: Heading,
  paragraph: Paragraph,
  label: Label,
  tag: Tag
};
