import { makeStyles } from '@mui/styles';

export const ICON_COLOR = {
  primary: {
    background: '#fff',
    color: 'var(--sah-primary)'
  },
  secondary: {
    background: 'var(--sah-secondary-pink)',
    color: '#0A0777'
  },
  tertiary: {
    background: '#676767',
    tertiary: 'var(--sah-medium)'
  }
};

export const COLORS = {
  primary: {
    background: 'var(--sah-primary)',
    '& p': {
      color: '#FFF'
    }
  },
  secondary: {
    background: 'var(--sah-secondary-blue-light)',
    '& p': {
      color: '#000'
    }
  },
  tertiary: {
    background: '#FFF',
    border: '1px solid #C7C7C7',
    '& p': {
      color: '#000'
    }
  }
};

const NotificationItemStyles = makeStyles(theme => ({
  iconWrapper: {
    width: '24px',
    height: '24px',
    flex: '0 0 24px',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    marginRight: '16px',
    '& > svg:first-child': {
      fontSize: '16px'
    }
  },
  notificationWrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1.25),
    borderRadius: theme.spacing(1),
    height: '56px',
    cursor: 'pointer',
    userSelect: 'none',
    '& > svg': {
      marginRight: '16px'
    },
    '& *': {
      lineHeight: '18px'
    }
  },
  textWrapper: {
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': '2',
    overflow: 'hidden'
  },
  closeButton: {
    marginLeft: 'auto',
    fontSize: '16px',
    marginRight: '0!important',
    cursor: 'pointer'
  },
  ...COLORS
}));

export default NotificationItemStyles;
