import styled from '@emotion/styled';

import Button from '@mui/material/Button';

// @TODO move to  mui theme when promoting component
export const DefaultButton = styled(Button)`
  && {
    padding: 14px 20px;
    border-radius: 100px;
    border: 1px solid var(--sah-interaction-dark);
    text-transform: none;
  }
  &:disabled {
    border: 1px solid var(--sah-medium);
    opacity: 0.5;
    color: var(--sah-medium);
  }
`;
