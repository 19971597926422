import { Component } from 'react';
import PropTypes from 'prop-types';

import randomDigits from '../../utils/random-digits';

import { Provider } from './ToastContext';
import toastChannels from './toastChannels';

/**
 * Represents a toast request object
 *
 * @typedef {Object} ToastRequest
 * @property {string} title (optional)
 * @property {string} content
 * @property {string} type
 * @property {string} channel (optional)
 * @property {boolean} closeable (optional)
 * @property {boolean} autoClose (optional)
 * @property {number} autoCloseTimeout (optional)
 * @property {number} clearPrevious (optional)
 */

/**
 * Represents a toast object
 *
 * @typedef {Object} Toast
 * @property {string} id
 * @property {string} title
 * @property {string} content
 * @property {string} type
 * @property {string} channel
 * @property {boolean} closeable
 * @property {boolean} autoClose
 * @property {number} autoCloseTimeout
 */

const DEFAULT_TYPE = 'info';

function generateType(type) {
  if (type === undefined) return DEFAULT_TYPE;

  return type;
}

function generateAutoClose(autoClose, type) {
  if (autoClose !== undefined && type === 'success') return true;

  return autoClose;
}

function generateChannel(channel) {
  let result = channel === undefined || channel === null ? undefined : channel;

  if (channel === undefined) {
    result = toastChannels.DEFAULT;
  }

  return result;
}

/**
 * Creates a toast object
 *
 * @param fields
 * @returns {Toast}
 */
function createToast(fields = {}) {
  const { type, channel, autoClose, ...rest } = fields;
  const finalType = generateType(type);
  const finalChannel = generateChannel(channel);
  const finalAutoClose = generateAutoClose(autoClose, type);
  return {
    ...rest,
    type: finalType,
    channel: finalChannel,
    autoClose: finalAutoClose
  };
}

class ToastProvider extends Component {
  state = {
    toasts: []
  };

  /**
   * Creates a new toast and shows it
   *
   * @param {ToastRequest} toast
   * @returns {string} id of the toast
   */
  show = toast => {
    const id = randomDigits();
    this.setState(prevState => {
      const { clearPrevious, ...rest } = toast;
      const toasts = clearPrevious ? [] : prevState.toasts.slice(0);

      toasts.push(createToast({ id, ...rest }));

      return { toasts };
    });
    return id;
  };

  /**
   * Remove a toast
   *
   * @param id
   */
  remove = id => {
    this.setState(prevState => {
      const toasts = prevState.toasts.filter(t => t.id !== id);
      return { toasts };
    });
  };

  /**
   * Remove all toasts
   */
  removeAll = () => {
    this.setState(() => ({ toasts: [] }));
  };

  /**
   * Remove all toasts from the same channel
   */
  removeByChannel = channel => {
    this.setState(prevState => {
      const toasts = prevState.toasts.filter(t => t.channel !== channel);
      return { toasts };
    });
  };

  render() {
    const { children } = this.props;
    const { toasts } = this.state;
    const { show, remove, removeAll, removeByChannel } = this;

    return (
      <Provider
        value={{
          show,
          remove,
          removeAll,
          removeByChannel,
          toasts
        }}
      >
        {children}
      </Provider>
    );
  }
}

ToastProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.array
  ]).isRequired
};

export default ToastProvider;
