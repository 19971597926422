import Typography from '@spotahome/landlord-panel-ui-library/src/components/Typography';
import { trans, getResourcePath } from '@spotahome/soyuz/client';
import { styled } from '@mui/material/styles';
import AccordionDetails from '@mui/material/AccordionDetails';

const Container = styled('section')({
  display: 'flex',
  placeContent: 'center',
  placeItems: 'center',
  justifyContent: 'space-between',
  maxWidth: 500,
  padding: 0
});

export const Content = styled('div')({
  '& > *': {
    marginBottom: 26
  }
});

export const Image = styled('img')({
  width: 216,
  height: 154,
  placeSelf: 'end'
});

const PaymentsAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  display: 'flex',
  padding: '0 20px 20px',
  flexDirection: 'column',
  justifyContent: 'space-between',
  [theme.breakpoints.up('sm')]: {
    padding: '0 56px 56px',
    flexDirection: 'row'
  }
}));

const AccordionPaymentsContent = () => {
  return (
    <PaymentsAccordionDetails>
      <Container>
        <Content>
          <Typography variant="ParagraphXL">
            {trans('landing.landlord.section.stepsTpPublish.text6')}
          </Typography>
          <Typography variant="ParagraphM">
            {trans('landing.landlord.section.stepsTpPublish.text8')}
          </Typography>
        </Content>
      </Container>
      <Image
        src={getResourcePath(`/images/landlord-landing-steps-to-publish.png`)}
        loading="lazy"
        alt={trans('landing.landlord.section.stepsTpPublish.text7')}
        type="image/png"
      />
    </PaymentsAccordionDetails>
  );
};

export default AccordionPaymentsContent;
