import { SvgIcon } from '@mui/material';

const PlusPlan = () => (
  <SvgIcon viewBox="0 0 32 32" style={{ width: 32, height: 32 }}>
    <path
      fill="#0A0777"
      d="M12.6666 12.9888A5.9998 5.9998 0 0 0 16 14a6.0073 6.0073 0 0 0 6-6 6 6 0 1 0-9.3334 4.9888ZM8 28.3546a5.9954 5.9954 0 0 1-3.3334-1.0123 6.0047 6.0047 0 0 1-2.2099-2.6956 6.0122 6.0122 0 0 1-.3414-3.4703 6.0083 6.0083 0 0 1 1.642-3.0753 5.9946 5.9946 0 0 1 6.5387-1.3021 6.0014 6.0014 0 0 1 2.6928 2.2122A6.0112 6.0112 0 0 1 14 22.3482a6.0169 6.0169 0 0 1-1.7594 4.2451A6.004 6.004 0 0 1 8 28.3546Zm12.6666-1.0123A5.9953 5.9953 0 0 0 24 28.3546a6.004 6.004 0 0 0 4.2406-1.7613A6.0169 6.0169 0 0 0 30 22.3482a6.0112 6.0112 0 0 0-1.0112-3.337 6.0014 6.0014 0 0 0-2.6927-2.2122 5.9946 5.9946 0 0 0-6.5387 1.3021 6.0076 6.0076 0 0 0-1.6421 3.0753 6.0125 6.0125 0 0 0 .3414 3.4703 6.0052 6.0052 0 0 0 2.2099 2.6956Z"
    />
  </SvgIcon>
);

export default PlusPlan;
