import { useCallback, useEffect, useRef, useState } from 'react';

import { useQuery } from '@apollo/react-hooks';

import Tracking from '@spotahome/soyuz-tracking';

import { useSoyuzUser } from '@spotahome/soyuz/client';

import {
  NOTIFICATIONS_PENDING,
  NOTIFICATION_BELL_CLICKED
} from '../../../../../../utils/segment/events';

import NotificationIcon from './NotificationIcon/NotificationIcon';
import NotificationModal from './NotificationModal';
import useStyles from './styles';
import { GET_NOTIFICATIONS } from './queries';

function NotificationMenu() {
  const [showModal, setShowModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const notificationIconRef = useRef(null);
  const classes = useStyles();
  const user = useSoyuzUser();

  const hideNotificationsByDefault = () => {
    localStorage.setItem('firstTimeNotification', false);
  };

  const toggleModal = () => {
    setShowModal(!showModal);
    hideNotificationsByDefault();
    Tracking.segment.trackEvent(NOTIFICATION_BELL_CLICKED, user, {
      user_id: user.id
    });
  };

  const onClickNotifications = () => {
    hideNotificationsByDefault();
  };

  const data = useQuery(GET_NOTIFICATIONS, {
    context: { uri: '/landlord-properties/graphql' }
  });

  const trackNotification = useCallback(() => {
    Tracking.segment.trackEvent(NOTIFICATIONS_PENDING, user, {
      user_id: user.id,
      page: window.location.pathname,
      notifications: data.data.getNotifications
    });
  }, [data, user]);

  useEffect(() => {
    if (data?.data?.getNotifications?.length > 0) {
      setAnchorEl(notificationIconRef.current);
      setShowModal(localStorage.getItem('firstTimeNotification') === null);
      trackNotification();
    }
  }, [data, trackNotification]);

  const notifications = data?.data?.getNotifications ?? [];

  return (
    <div className={classes.notificationMenu.root}>
      <NotificationIcon
        onClick={toggleModal}
        isActive={notifications.length > 0}
        ref={notificationIconRef}
      />
      <NotificationModal
        open={showModal}
        handleClose={toggleModal}
        anchorEl={anchorEl}
        notifications={notifications}
        onClickNotifications={onClickNotifications}
      />
    </div>
  );
}

export default NotificationMenu;
