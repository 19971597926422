import debounceThrottle from './debounce-throttle';
import debounce from './debounce';
import throttle from './throttle';

// eslint-disable-next-line import/prefer-default-export
export function createDelayedProxyFn(callback, debounceWait, throttleWait) {
  if (debounceWait > 0 && throttleWait > 0) {
    return debounceThrottle(callback, debounceWait, throttleWait);
  }
  if (debounceWait > 0) {
    return debounce(callback, debounceWait);
  }
  if (throttleWait > 0) {
    return throttle(callback, debounceWait);
  }

  return callback;
}
