import { makeStyles } from '@mui/styles';

const NotificationMenu = makeStyles({
  root: {
    position: 'relative'
  }
});

export default () => ({
  notificationMenu: NotificationMenu()
});
