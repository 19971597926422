import { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { RichTooltip } from '../../../../components/Tooltip';

import { noop } from '../../../../utils/fn';

import * as S from './styles';

const LogoutTooltip = forwardRef(
  ({ title, isOpen, logoutButtonText, onLogout, distance, children }, ref) => {
    return (
      <RichTooltip
        hasButton
        content={<S.Title>{title}</S.Title>}
        buttonText={logoutButtonText}
        onButtonClick={onLogout}
        isOpen={isOpen}
        distance={distance}
        ref={ref}
      >
        <S.Container>
          {children}
          <S.LogoutIcon />
        </S.Container>
      </RichTooltip>
    );
  }
);

LogoutTooltip.defaultProps = {
  onLogout: noop,
  children: null,
  distance: 30
};

LogoutTooltip.propTypes = {
  onLogout: PropTypes.func,
  title: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  logoutButtonText: PropTypes.string.isRequired,
  distance: PropTypes.number,
  children: PropTypes.node
};

export default LogoutTooltip;
