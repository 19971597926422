export const tabStyles = {
  root: {
    color: '#000 !important',
    fontWeight: '400 !important',
    fontSize: '16px !important',
    '&:focus': {
      opacity: '1 !important'
    },
    '&.Mui-selected': {
      color: '#000 !important',
      fontWeight: '600 !important'
    }
  }
};

export const tabsStyles = {
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      width: '100%',
      backgroundColor: '#0a0d77'
    }
  }
};
