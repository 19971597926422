export const CHANGE_LANGUAGE_PANEL = 'change-language-panel';
export const LOG_OUT = 'log-out';
export const EDIT_UNIT = 'edit-unit';
export const VIEW_LISTING = 'view-listing';
export const BOOKING_MENU_SECTION_CLICKED = 'booking-menu-section-clicked';

export default {
  CHANGE_LANGUAGE_PANEL,
  LOG_OUT,
  EDIT_UNIT,
  VIEW_LISTING,
  BOOKING_MENU_SECTION_CLICKED
};
