import styled from '@emotion/styled';

import Spinner from '../Spinner';

const ButtonSpinner = styled(Spinner)`
  position: absolute;
  margin: 0;
`;

export default ButtonSpinner;
