import PropTypes from 'prop-types';
import { useTheme } from '@mui/styles';
import { Typography } from '@spotahome/ui-library/base';
import { getPagePath, trans } from '@spotahome/soyuz/client';

import * as S from './styles';

const RegisterMenu = ({ locale }) => {
  const muiTheme = useTheme();
  return (
    <S.Container theme={muiTheme}>
      <S.RegisterNavItem
        href={getPagePath('landlord.signup', { locale })}
        data-test={`signup-menu-item`}
        theme={muiTheme}
        isLoggedIn={false}
      >
        <Typography.HeadingS>
          {trans('landlord.login.signup')}
        </Typography.HeadingS>
      </S.RegisterNavItem>
      <Typography.HeadingS>{' | '}</Typography.HeadingS>
      <S.RegisterNavItem
        href={getPagePath('landlord.login', { locale })}
        data-cy={`signin-menu-item`}
        theme={muiTheme}
        isLoggedIn={false}
      >
        <Typography.HeadingS>
          {trans('lister.landing.alternative.signin')}
        </Typography.HeadingS>
      </S.RegisterNavItem>
    </S.Container>
  );
};

RegisterMenu.propTypes = {
  locale: PropTypes.string.isRequired
};

export default RegisterMenu;
