import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { space, border } from '../../shared/spacing';
import fonts from '../../shared/fonts';
import TextButton from '../TextButton';

import CloseIcon from '../../icons/Close';

import { alertTypesConfig, alertTypesArray, alertTypes } from './alert-types';

const Container = styled.div`
  display: flex;
  position: relative;
  border-radius: ${border.radius};
  ${({ withoutShadow }) =>
    !withoutShadow
      ? css`
          box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
        `
      : null};
  font-size: ${fonts.sizes.xs};
  width: 100%;
  padding: ${space.x05} ${space.x1} ${space.x05} ${space.x05};

  ${({ bgColor, bColor }) => css`
    background-color: ${bgColor};
    border-top: 2px solid ${bColor};
  `};
`;

const MessageContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 ${space.x2} 0 ${space.x05};
  line-height: 1.5;
  width: 100%;
  justify-content: center;
`;

const Title = styled.span`
  font-family: ${fonts.GothamMedium};
  margin-bottom: ${space.x05};
`;

const CloseButton = styled(TextButton)`
  display: flex;
  flex-direction: column;
`;

const StyledCloseIcon = styled(CloseIcon)`
  width: 15px;
`;

const Alert = ({
  type,
  closable = true,
  children,
  title = null,
  onRequestClose = () => {},
  withoutBorder = false,
  withoutShadow = false,
  ...rest
}) => {
  const typeConfig = alertTypesConfig[type];
  if (!typeConfig) return null;

  const { backgroundColor, borderColor, icon } = typeConfig;

  return (
    <Container
      withoutShadow={withoutShadow}
      bgColor={backgroundColor}
      bColor={withoutBorder ? backgroundColor : borderColor}
      data-test={`alert-message-${type}`}
      {...rest}
    >
      {icon}
      <MessageContent>
        {title && <Title>{title}</Title>}
        <div>{children}</div>
      </MessageContent>
      {closable && (
        <CloseButton
          type="button"
          onClick={onRequestClose}
          data-test="alert-close-button"
        >
          <StyledCloseIcon />
        </CloseButton>
      )}
    </Container>
  );
};

Alert.propTypes = {
  type: PropTypes.oneOf(alertTypesArray).isRequired,
  title: PropTypes.string,
  closable: PropTypes.bool,
  withoutBorder: PropTypes.bool,
  withoutShadow: PropTypes.bool,
  onRequestClose: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

Alert.types = alertTypes;
Alert.typesArray = alertTypesArray;

export default Alert;
