import { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css, keyframes } from '@emotion/react';

import { space } from '../../shared/spacing';
import { mediaQueries } from '../../shared/breakpoints';

const scaleAnimation = keyframes`
  0% { transform: scaleY(0); }
  100% { transform: scaleY(1); }
`;

const growAnimation = keyframes`
  0% { max-height: 0; }
  100% { max-height: 200px; }
`;

const slideFromLeft = keyframes`
  0% { transform: translateX(320px); }
  100% { transform: translateX(0); }
`;

const ToastWrapper = styled.div`
  transform-origin: top;
  animation: ${scaleAnimation} 0.3s linear, ${growAnimation} 1s linear;

  ${mediaQueries.lg(css`
    margin-bottom: ${space.x2};
    :nth-last-of-type {
      margin-bottom: ${space.x2};
    }
  `)};

  ${mediaQueries.xl(css`
    animation: ${slideFromLeft} 0.6s ease-out;
  `)};
`;

class ToastController extends Component {
  timeout = null;

  state = {
    autoCloseTimeout: this.props.autoCloseTimeout
  };

  static getDerivedStateFromProps({ autoClose, autoCloseTimeout }) {
    if (!autoClose) return null;

    const timeout =
      typeof autoClose === 'number' ? autoClose : autoCloseTimeout;

    return { autoCloseTimeout: timeout };
  }

  componentDidMount() {
    const { autoClose, onClose } = this.props;
    const { autoCloseTimeout } = this.state;

    if (autoClose) {
      this.timeout = setTimeout(onClose, autoCloseTimeout);
    }
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  render() {
    const { toastComponent: Toast, onClose, ...props } = this.props;

    return (
      <ToastWrapper>
        <Toast onRequestClose={onClose} {...props} />
      </ToastWrapper>
    );
  }
}

ToastController.defaultProps = {
  autoCloseTimeout: 3000,
  autoClose: false
};

ToastController.propTypes = {
  autoCloseTimeout: PropTypes.number,
  autoClose: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  toastComponent: PropTypes.func.isRequired
};

export default ToastController;
