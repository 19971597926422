export const DEFAULT_LINK_LOCALE = 'en';

export const LINK_HELP = {
  en: 'https://help.spotahome.com',
  de: 'https://help.spotahome.com/hc/de',
  es: 'https://help.spotahome.com/hc/es',
  fr: 'https://help.spotahome.com/hc/fr',
  it: 'https://help.spotahome.com/hc/it',
  pt: 'https://help.spotahome.com/hc/pt'
};

export const LINK_TERMS_AND_CONDITIONS = {
  en: 'https://www.spotahome.com/terms-and-conditions',
  de: 'https://www.spotahome.com/de/geschaftsbedingungen',
  es: 'https://www.spotahome.com/es/terminos-y-condiciones',
  fr: 'https://www.spotahome.com/fr/termes-et-conditions',
  it: 'https://www.spotahome.com/it/termini-e-condizioni',
  pt: 'https://www.spotahome.com/pt/termos-e-condicoes',
  pl: 'https://www.spotahome.com/pl/terms-and-conditions',
  tr: 'https://www.spotahome.com/tr/sartlar-ve-kosulla'
};

export const LINK_PRIVACY_POLICIES = {
  en: 'https://www.spotahome.com/privacy-policies',
  de: 'https://www.spotahome.com/de/privacy-policies',
  es: 'https://www.spotahome.com/es/politica-privacidad',
  fr: 'https://www.spotahome.com/fr/privacy-policies',
  it: 'https://www.spotahome.com/it/privacy-policies',
  pt: 'https://www.spotahome.com/pt/privacy-policies',
  pl: 'https://www.spotahome.com/pl/privacy-policies',
  tr: 'https://www.spotahome.com/tr/privacy-policies'
};

export const LINK_CONTACT_US = {
  en: 'https://www.spotahome.com/contact-us',
  de: 'https://www.spotahome.com/de/kontaktieren-sie-uns',
  es: 'https://www.spotahome.com/es/contacto',
  fr: 'https://www.spotahome.com/fr/nous-contacter',
  it: 'https://www.spotahome.com/it/contattaci',
  pt: 'https://www.spotahome.com/pt/contacte-nos',
  pl: 'https://www.spotahome.com/pl/skontaktuj-sie-z-nami',
  tr: 'https://www.spotahome.com/tr/bize-ulasin'
};

export const LINK_HOME = {
  en: 'https://www.spotahome.com/',
  de: 'https://www.spotahome.com/de/',
  es: 'https://www.spotahome.com/es/',
  fr: 'https://www.spotahome.com/fr',
  it: 'https://www.spotahome.com/it/',
  pt: 'https://www.spotahome.com/pt/',
  pl: 'https://www.spotahome.com/pl/',
  tr: 'https://www.spotahome.com/tr/'
};

export const LINK_TUTORIAL = {
  en: 'https://view.genial.ly/619367146e08110dcd970fcb/guide-introducing-your-landlord-panel',
  de: 'https://view.genial.ly/6336a6477e63b300186c96da/interactive-image-create-a-listing-in-just-a-few-steps-de',
  es: 'https://view.genial.ly/61a8b3724282470e281b5ffc/interactive-image-crea-tu-anuncio-en-muy-pocos-pasos',
  fr: 'https://view.genial.ly/6335953c3e14570018fd2c9e/interactive-image-create-a-listing-in-just-a-few-steps-fr',
  it: 'https://view.genial.ly/63354c7e90a1d70010a9d74c/interactive-image-create-a-listing-in-just-a-few-steps-it',
  pt: 'https://view.genial.ly/63340808d697820011718c2d/interactive-image-crie-o-seu-anuncio-em-apenas-alguns-passos',
  pl: 'https://view.genial.ly/619367146e08110dcd970fcb/guide-introducing-your-landlord-panel',
  tr: 'https://view.genial.ly/619367146e08110dcd970fcb/guide-introducing-your-landlord-panel'
};

export const getLink = (link, locale) =>
  link[locale] || link[DEFAULT_LINK_LOCALE];

export const getCourrentRoute = (currentPath, routes, matchFn) => {
  const currentRouteKey = Object.keys(routes).find(routeKey => {
    const route = routes[routeKey];
    const match = matchFn(route, currentPath);
    return !!match;
  });
  return currentRouteKey;
};
