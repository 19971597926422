import PropTypes from 'prop-types';
import {
  createTheme,
  ThemeProvider as ThemeProviderMUI
} from '@mui/material/styles';
import { green, lightBlue, orange } from '@mui/material/colors';

const themeMUI = createTheme({
  palette: {
    primary: {
      main: '#3f51b5'
    },
    info: {
      main: lightBlue[700],
      light: lightBlue[500],
      dark: lightBlue[900]
    },
    success: {
      main: green[800],
      light: green[500],
      dark: green[900]
    },
    warning: {
      main: '#ED6C02',
      light: orange[500],
      dark: orange[900]
    },
    text: { hint: 'rgba(0, 0, 0, 0.38)' }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontSize: '0.875rem',
          lineHeight: 1.43,
          letterSpacing: '0.01071em'
        }
      }
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover'
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          color: 'rgba(0, 0, 0, 0.87)'
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: 'rgba(0, 0, 0, 0.87)'
        }
      }
    }
  }
});

const ThemeProvider = ({ children }) => (
  <ThemeProviderMUI theme={themeMUI}>{children}</ThemeProviderMUI>
);

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default ThemeProvider;
