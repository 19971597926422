const PlaceholderLogo = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 34V80H28.2805V60.2949C28.2805 53.7691 33.5385 48.459 40 48.459C46.4615 48.459 51.7195 53.7691 51.7195 60.2949V80H68V34H12Z"
      fill="#FFCEF4"
    />
    <path
      d="M50.5 34C53.8137 34 56.5 31.3137 56.5 28C56.5 24.6863 53.8137 22 50.5 22C47.1863 22 44.5 24.6863 44.5 28C44.5 31.3137 47.1863 34 50.5 34Z"
      fill="#0A0777"
    />
    <path
      d="M10 25.7765C16.7346 35.0145 30.2994 36.726 40.3256 29.6027C50.3517 22.4795 53.0157 9.23799 46.2812 0L10 25.7765Z"
      fill="#FB6E44"
    />
  </svg>
);

export default PlaceholderLogo;
