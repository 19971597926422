export const DEFAULT_THROTTLE_TIME = 100;

/**
 * Creates a throttled function that only invokes fn
 * at most once per every wait milliseconds
 *
 * @param fn
 * @param wait
 * @returns {Function}
 */
const throttle = (fn, wait = 100) => {
  let inThrottle;
  return (...args) => {
    if (!inThrottle) {
      fn(...args);
      inThrottle = true;
      setTimeout(() => (inThrottle = false), wait);
    }
  };
};

export default throttle;
