import PropTypes from 'prop-types';

import { Box, CardActions, CardContent } from '@mui/material';
import { trans } from '@spotahome/soyuz/client';

import Typography from '@spotahome/landlord-panel-ui-library/src/components/Typography';

import StandardPlan from '@spotahome/landlord-panel-common/src/components/Plans/components/Icons/StandardPlan';
import PlusPlan from '@spotahome/landlord-panel-common/src/components/Plans/components/Icons/PlusPlan';
import AllInPlan from '@spotahome/landlord-panel-common/src/components/Plans/components/Icons/AllInPlan';

import { PLANS } from '../plansInfo';

import useStyles, { StyledCard } from './styles';

const PLAN_ICONS = {
  [PLANS.STANDARD]: <StandardPlan />,
  [PLANS.PLUS]: <PlusPlan />,
  [PLANS.ALLIN]: <AllInPlan />
};

export const PlanCard = ({ children, plan, planDescription }) => {
  const classes = useStyles();

  return (
    <Box
      sx={{
        textAlign: 'left',
        height: '100%',
        position: 'relative'
      }}
    >
      {plan === PLANS.PLUS && (
        <div
          style={{
            backgroundColor: 'white',
            position: 'absolute',
            top: '-18px',
            width: '100%'
          }}
        >
          <Box className={classes.planCard.plusHat}>
            <Typography variant="LabelS">
              {trans('sahc.properties.plans.plus.popular')}
            </Typography>
          </Box>
        </div>
      )}
      <StyledCard plan={plan}>
        <CardContent
          sx={{
            padding: '24px 24px 0 24px',
            flex: 1
          }}
        >
          <div className={classes.planCard.icon}>{PLAN_ICONS[plan]}</div>
          <div className={classes.planCard.title}>
            <Typography variant="DisplayM">{plan.toLowerCase()}</Typography>
          </div>
          <Typography variant="ParagraphMTall">{planDescription}</Typography>
        </CardContent>
        <CardActions
          sx={{
            padding: '0 24px 24px',
            height: '100%',
            justifyContent: 'center'
          }}
        >
          {children}
        </CardActions>
      </StyledCard>
    </Box>
  );
};

PlanCard.propTypes = {
  plan: PropTypes.string.isRequired,
  planDescription: PropTypes.string.isRequired,
  buttonInfo: PropTypes.shape({
    text: PropTypes.string.isRequired
  }).isRequired,
  children: PropTypes.node.isRequired
};
