/* eslint-disable import/prefer-default-export */
import PropTypes from 'prop-types';

export const HeaderToolbarPropTypes = {
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  avatarBorderWidth: PropTypes.number.isRequired,
  logoutTitle: PropTypes.string.isRequired,
  isLogoutTooltipOpen: PropTypes.bool.isRequired,
  logoutButtonText: PropTypes.string.isRequired,
  onLogout: PropTypes.func,
  distance: PropTypes.number
};
