import Tracking from '@spotahome/soyuz-tracking';

export const trackLandlordJoinCta = section => {
  Tracking.ga.sendEvent('landlord_landing', 'clickBtn', 'mainBtn');
  Tracking.segment.trackEvent('LandlordLandingJoinCta', null, { section });
};

export const trackLandlordAccessCta = () => {
  Tracking.ga.sendEvent('landlord_landing', 'clickBtn', 'loginBtn');
  Tracking.segment.trackEvent('LandlordLandingAccessCta', null);
};

export const trackLandlordStepsToPublishClicked = step => {
  Tracking.ga.sendEvent('landlord_landing', 'clickStep', step);
  Tracking.segment.trackEvent('LandlordLandingStepsToPublishClicked', null, {
    step
  });
};
export const trackLandlordStepsToPublishCta = () => {
  Tracking.ga.sendEvent('landlord_landing', 'clickBtn', 'stepsToPublish');
  Tracking.segment.trackEvent('LandlordLandingStepsToPublishCtaClicked', null);
};

export const trackLandlordLandingFAQs = faq => {
  Tracking.ga.sendEvent('landlord_landing', 'clickFaq', faq);
  Tracking.segment.trackEvent('LandlordLandingFAQs', null, { faq });
};

export const trackLandlordLandingSectionViewed = section => {
  Tracking.ga.sendEvent('landlord_landing', 'view', section);
  Tracking.segment.trackEvent('LandlordLandingSectionViewed', null, {
    section
  });
};
