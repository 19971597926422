import Proptypes from 'prop-types';
import classnames from 'classnames';

import DefaultButton from '../../../../components/DefaultButton';
import PrimaryButton from '../../../../components/PrimaryButton';

import { PLANS } from '../../plansInfo';

import { useStyles } from './styles';

const ActionButton = ({ plan, currentPlan, fullWidth, ...props }) => {
  const classes = useStyles();

  const isUpgradeToPlusAvailable =
    currentPlan === PLANS.STANDARD && plan === PLANS.PLUS;

  const className = classnames(classes.tableButton, {
    plus: isUpgradeToPlusAvailable,
    fullWidth
  });

  const ButtonTag = isUpgradeToPlusAvailable ? PrimaryButton : DefaultButton;

  return <ButtonTag className={className} {...props} />;
};

ActionButton.propTypes = {
  plan: Proptypes.string.isRequired,
  children: Proptypes.node.isRequired,
  currentPlan: Proptypes.string.isRequired,
  fullWidth: Proptypes.bool
};

ActionButton.defaultProps = {
  fullWidth: false
};
export default ActionButton;
