import throttle, { DEFAULT_THROTTLE_TIME } from './throttle';
import debounce, { DEFAULT_DEBOUNCE_TIME } from './debounce';

/**
 * Creates a function that combines debounce and throttle
 *
 * @param fn
 * @param throttleWait
 * @param debounceWait
 * @returns {Function}
 */
const debounceThrottle = (
  fn,
  debounceWait = DEFAULT_DEBOUNCE_TIME,
  throttleWait = DEFAULT_THROTTLE_TIME
) => {
  const fn1 = debounce(fn, debounceWait);
  const fn2 = throttle(fn, throttleWait);

  return (...args) => {
    fn1(...args);
    fn2(...args);
  };
};

export default debounceThrottle;
