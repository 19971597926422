export const setChatUserInfo = ({ email, fullname, locale }) => {
  /* eslint-disable no-undef */
  if (typeof zE !== 'undefined') {
    zE(() => {
      if (typeof $zopim !== 'undefined') {
        $zopim(() => {
          $zopim.livechat.setEmail(email);
          $zopim.livechat.setName(fullname);
          $zopim.livechat.setLanguage(locale);
        });
      }
    });
  }
  /* eslint-enable no-undef */
};

export const clearChatUserInfo = () => {
  /* eslint-disable no-undef */
  if (typeof zE !== 'undefined') {
    zE(() => {
      if (typeof $zopim !== 'undefined') {
        $zopim(() => {
          $zopim.livechat.clearAll();
        });
      }
    });
  }
  /* eslint-enable no-undef */
};
