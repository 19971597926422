import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import isPropValid from '@emotion/is-prop-valid';

import colors from '../../shared/colors';
import { animatedHover } from '../../shared/mixins';

const StyledButton = styled('button', {
  shouldForwardProp: prop => isPropValid(prop) && prop !== 'color'
})(
  ({ color, hovercolor }) => css`
    background-color: transparent;
    cursor: pointer;
    border: none;
    outline: none;
    color: ${color};
    fill: ${color};
    padding: 0;
    text-decoration: none;

    ${animatedHover(hovercolor)}

    &[disabled] {
      cursor: not-allowed;
    }
  `
);

const TextButton = ({
  type = 'button',
  color = colors.greyDark,
  hoverColor = colors.primary,
  className = null,
  ...props
}) => (
  <StyledButton
    type={type}
    className={className}
    color={color}
    hovercolor={hoverColor}
    {...props}
  />
);

TextButton.propTypes = {
  /** Button type */
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  /** Default color for the text of the button */
  color: PropTypes.string,
  /** Color to be displayed when mouse is over the button */
  hoverColor: PropTypes.string,
  /** Text button content. */
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.array
  ]).isRequired,
  /** ClassName to extend styles */
  className: PropTypes.string
};

export default TextButton;
