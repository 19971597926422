import BaseIcon from './Base';

const WarnCircledIcon = props => (
  <BaseIcon width="32" height="32" viewBox="-4 -4 32 32" {...props}>
    <g fill="none">
      <circle cx="12" cy="12" r="11.5" stroke="currentColor" />
      <path
        fill="currentColor"
        d="M12.077 5a.82.82 0 0 0-.086.009.82.82 0 0 0-.726.829v8.837a.82.82 0 1 0 1.641 0V5.838a.82.82 0 0 0-.83-.838zm.008 11.7c-.593 0-1.085.484-1.085 1.078 0 .593.492 1.085 1.085 1.085.594 0 1.086-.492 1.086-1.085 0-.594-.492-1.077-1.086-1.077z"
      />
    </g>
  </BaseIcon>
);

export default WarnCircledIcon;
