import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

import BaseIcon from './Base';

const Task = ({ width = 20, height = 25, ...props }) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '32px',
      height: '32px'
    }}
  >
    <BaseIcon width={width} height={height} viewBox="0 0 20 25" {...props}>
      <path
        fill="#0D0D0D"
        d="m8.6 20.2333 7.0667-7.0666-1.4334-1.4334-5.6666 5.6334-2.8-2.8334-1.4334 1.4334L8.6 20.2333Zm-6.2 5.4334c-.6667 0-1.2333-.2334-1.7-.7-.4667-.4667-.7-1.0334-.7-1.7V2.7333c0-.6666.2333-1.2333.7-1.7.4667-.4666 1.0333-.7 1.7-.7H13l7 7v15.9334c0 .6666-.2333 1.2333-.7 1.7-.4667.4666-1.0333.7-1.7.7H2.4ZM12 8.3333v-6H2.4c-.0889 0-.1778.0445-.2667.1334C2.0444 2.5556 2 2.6444 2 2.7333v20.5334c0 .0889.0444.1777.1333.2666.089.0889.1778.1334.2667.1334h15.2c.0889 0 .1778-.0445.2667-.1334.0889-.0889.1333-.1777.1333-.2666V8.3333h-6Z"
      />
    </BaseIcon>
  </Box>
);

Task.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
};

export default Task;
