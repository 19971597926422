import { SvgIcon } from '@mui/material';

const AllInPlan = () => (
  <SvgIcon viewBox="0 0 32 32" style={{ width: 32, height: 32 }}>
    <path
      fill="#FB6E44"
      d="M7.7777 8.3259A4 4 0 0 0 10 9a4.005 4.005 0 0 0 4-4 4.0002 4.0002 0 0 0-4.7804-3.9231 4 4 0 0 0-1.4419 7.249Zm17 11A4.0003 4.0003 0 0 0 27 20a4.0052 4.0052 0 0 0 4-4 4.0003 4.0003 0 0 0-2.4693-3.6955 3.9996 3.9996 0 0 0-5.4538 2.9151 3.9996 3.9996 0 0 0 1.7008 4.1063Zm-17 11A4.0002 4.0002 0 0 0 10 31a4.0052 4.0052 0 0 0 4-4 4.0003 4.0003 0 0 0-2.4693-3.6955 3.9998 3.9998 0 0 0-5.2262 5.2262 4 4 0 0 0 1.4732 1.7952ZM16 20a4.0003 4.0003 0 0 1-3.6955-2.4693 3.9996 3.9996 0 0 1 2.9151-5.4538A3.9996 3.9996 0 0 1 20 16a4.0052 4.0052 0 0 1-4 4Zm3.7777-11.6741A4 4 0 0 0 22 9a4.005 4.005 0 0 0 4-4 4.0002 4.0002 0 0 0-4.7804-3.9231 3.9998 3.9998 0 0 0-2.9151 5.4538 3.9996 3.9996 0 0 0 1.4732 1.7952Z"
    />
    <path
      fill="#FB6E44"
      d="M19.7777 8.3259A4 4 0 0 0 22 9a4.005 4.005 0 0 0 4-4 4.0002 4.0002 0 0 0-4.7804-3.9231 3.9998 3.9998 0 0 0-2.9151 5.4538 3.9996 3.9996 0 0 0 1.4732 1.7952Z"
    />
    <path
      fill="#FB6E44"
      d="M19.7777 8.3259A4 4 0 0 0 22 9a4.005 4.005 0 0 0 4-4 4.0002 4.0002 0 0 0-4.7804-3.9231 3.9998 3.9998 0 0 0-2.9151 5.4538 3.9996 3.9996 0 0 0 1.4732 1.7952Zm0 22A4.0003 4.0003 0 0 0 22 31a4.0052 4.0052 0 0 0 4-4 4.0003 4.0003 0 0 0-2.4693-3.6955 3.9996 3.9996 0 0 0-5.4538 2.9151 3.9996 3.9996 0 0 0 1.7008 4.1063Zm-17-11A4.0002 4.0002 0 0 0 5 20a4.0051 4.0051 0 0 0 4-4 4 4 0 1 0-6.2223 3.3259Z"
    />
    <path
      fill="#FB6E44"
      d="M2.7777 19.3259A4.0002 4.0002 0 0 0 5 20a4.0051 4.0051 0 0 0 4-4 4 4 0 1 0-6.2223 3.3259Z"
    />
  </SvgIcon>
);

export default AllInPlan;
