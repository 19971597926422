import PropTypes from 'prop-types';

import { trans } from '@spotahome/soyuz/client';
import { Button } from '@spotahome/ui-library';

const HelpCenterButton = ({ href = '#', color = 'light', ...props }) => {
  return (
    <Button
      fullWidth={false}
      alt
      size="medium"
      color={color}
      href={href}
      {...props}
    >
      {trans('footer.item.help_center')}
    </Button>
  );
};

HelpCenterButton.propTypes = {
  href: PropTypes.string,
  color: PropTypes.string
};

export default HelpCenterButton;
