import { getPagePath } from '@spotahome/soyuz/client';

export const PLANS = {
  STANDARD: 'STANDARD',
  PLUS: 'PLUS',
  ALLIN: 'ALL-IN'
};

export const tableInfo = {
  'sahc.properties.plans.table.1': [true, true, true],
  'sahc.properties.plans.table.2': [true, true, true],
  'sahc.properties.plans.table.3': [true, true, true],
  'sahc.properties.plans.table.4': [true, true, true],
  'sahc.properties.plans.table.5': [false, true, true],
  'sahc.properties.plans.table.6': [
    'sahc.properties.plans.table.6.standard',
    'sahc.properties.plans.table.6.plus',
    'sahc.properties.plans.table.6.plus'
  ],
  'sahc.properties.plans.table.7': [
    false,
    'sahc.properties.plans.table.7.plus',
    'sahc.properties.plans.table.7.plus'
  ],
  'sahc.properties.plans.table.8': [
    false,
    'sahc.properties.plans.table.8.plus',
    'sahc.properties.plans.table.8.plus'
  ],
  'sahc.properties.plans.table.9': [false, true, true],
  'sahc.properties.plans.table.10': [false, true, true],
  'sahc.properties.plans.table.11': [false, false, true]
};

export const tooltips = [
  'sahc.properties.plans.table.5',
  'sahc.properties.plans.table.10'
];

export const plansInfo = {
  [PLANS.STANDARD]: {
    description: 'sahc.properties.plans.standard.description',
    buttonInfo: {
      text: 'sahc.properties.plans.standard.button',
      link: () => getPagePath('landlord.panel.properties')
    }
  },
  [PLANS.PLUS]: {
    description: 'sahc.properties.plans.plus.description',
    buttonInfo: {
      text: 'contract_types.learn_more',
      link: () => getPagePath('externals.plus-plan')
    }
  },
  [PLANS.ALLIN]: {
    description: 'sahc.properties.plans.allin.description',
    buttonInfo: {
      text: 'contract_types.learn_more',
      link: () => getPagePath('externals.all-in-plan')
    }
  }
};
