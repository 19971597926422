import PropTypes from 'prop-types';

import BaseIcon from './Base';

const PhoneBubble = ({ color = '#0A0777', ...props }) => (
  <BaseIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
    <>
      <circle cx="12" cy="12" r="12" fill={color} />
      <mask
        id="bubble-phone"
        width="16"
        height="16"
        x="4"
        y="4"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#fff" d="M4 4h16v16H4z" />
      </mask>
      <g mask="url(#bubble-phone)">
        <path
          fill="#fff"
          d="M16.95 17.633c-1.29 0-2.57-.308-3.842-.925A12.486 12.486 0 0 1 9.7 14.283c-1-1-1.809-2.133-2.425-3.4-.617-1.267-.925-2.55-.925-3.85 0-.2.066-.367.2-.5.133-.133.3-.2.5-.2h2.166a.696.696 0 0 1 .7.567l.384 1.966c.022.167.016.317-.017.45a.69.69 0 0 1-.217.35l-1.516 1.5a11.842 11.842 0 0 0 4.35 4.3l1.483-1.5a.913.913 0 0 1 .392-.233c.15-.044.302-.056.458-.033l1.85.383a.707.707 0 0 1 .409.233.666.666 0 0 1 .158.45v2.167c0 .2-.067.367-.2.5-.134.133-.3.2-.5.2Z"
        />
      </g>
    </>
  </BaseIcon>
);

PhoneBubble.propTypes = {
  color: PropTypes.string
};

export default PhoneBubble;
