import styled from '@emotion/styled';
import { css } from '@emotion/react';

import Tooltip from '../Tooltip/Tooltip';
import Button from '../../../Button';

import { mediaQueries } from '../../../../shared/breakpoints';
import { space, border } from '../../../../shared/spacing';
import fonts from '../../../../shared/fonts';

export const StyledTooltip = styled(Tooltip)`
  .react-tooltip-lite {
    border-radius: ${border.radius};
    padding: 0 !important;
    max-width: 288px !important;
    width: auto !important;
  }
`;

export const StyledButton = styled(Button)`
  font-family: ${fonts.GothamBold};
  font-size: ${fonts.sizes.s};
  min-width: 100%;

  ${mediaQueries.sm(css`
    padding: ${space.x15};
  `)};
`;

export default { StyledTooltip, StyledButton };
