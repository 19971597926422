import colors from '../../shared/colors';

import CheckedCircledIcon from '../../icons/CheckedCircled';
import WarnCircledIcon from '../../icons/WarnCircled';
import InfoCircledIcon from '../../icons/InfoCircled';

export const alertTypes = {
  SUCCESS: 'success',
  ERROR: 'error',
  INFO: 'info',
  WARNING: 'warning'
};

export const alertTypesConfig = {
  [alertTypes.SUCCESS]: {
    backgroundColor: colors.greenSuccessLight,
    borderColor: colors.greenSuccess,
    icon: <CheckedCircledIcon color={colors.greenSuccess} />
  },
  [alertTypes.ERROR]: {
    backgroundColor: colors.redErrorLight,
    borderColor: colors.redError,
    icon: <WarnCircledIcon color={colors.redError} />
  },
  [alertTypes.INFO]: {
    backgroundColor: colors.blueInfoLight,
    borderColor: colors.blueInfo,
    icon: <InfoCircledIcon color={colors.blueInfo} />
  },
  [alertTypes.WARNING]: {
    backgroundColor: colors.orangeAlertLight,
    borderColor: colors.orangeAlert,
    icon: <WarnCircledIcon color={colors.orangeAlert} />
  }
};

export const alertTypesArray = Object.keys(alertTypes).map(
  key => alertTypes[key]
);
