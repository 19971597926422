import { useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { AppBar as AppBarMUI, Box, Tab, Tabs } from '@mui/material';
import { withStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import { TabContext, TabPanel as TabPanelMUI } from '@mui/lab';

import { trans } from '@spotahome/soyuz/client';

import Typography from '@spotahome/landlord-panel-ui-library/src/components/Typography';

import PlanContainer from './components/PlanContainer';
import { PLANS } from './plansInfo';
import { tabsStyles, tabStyles } from './mobileStyles';

const StyledTabs = withStyles(tabsStyles)(props => (
  <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />
));

const StyledTab = withStyles(tabStyles)(props => (
  <Tab disableRipple {...props} />
));

const TabPanel = styled(TabPanelMUI)({
  padding: '24px 0'
});

const Title = styled(Box)({
  padding: '25px 16px 16px'
});

const AppBar = styled(AppBarMUI)({
  boxShadow: 'none',
  backgroundColor: 'transparent',
  marginTop: 0
});

const MobilePlans = () => {
  // the plan set here will be the selected tab
  const [plan, setPlan] = useState(PLANS.PLUS);

  const handleChange = (_, newValue) => {
    setPlan(Object.keys(PLANS)[newValue]);
  };

  const handleChangeIndex = index => {
    setPlan(Object.keys(PLANS)[index]);
  };

  return (
    <>
      <Title>
        <Typography variant="DisplayM">
          {trans('sahc.properties.plans.title')}
        </Typography>
      </Title>
      <TabContext value={plan}>
        <AppBar position="static" enableColorOnDark>
          <StyledTabs
            value={Object.keys(PLANS).indexOf(plan)}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            {Object.values(PLANS).map(p => (
              <StyledTab label={p.toLowerCase()} key={p} />
            ))}
          </StyledTabs>
        </AppBar>
        <SwipeableViews
          axis="x"
          index={Object.keys(PLANS).indexOf(plan)}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={plan} index={0}>
            <PlanContainer plan={PLANS.STANDARD} />
          </TabPanel>
          <TabPanel value={plan} index={1}>
            <PlanContainer plan={PLANS.PLUS} />
          </TabPanel>
          <TabPanel value={plan} index={2}>
            <PlanContainer plan={PLANS.ALLIN} />
          </TabPanel>
        </SwipeableViews>
      </TabContext>
    </>
  );
};

export default MobilePlans;
