import PropTypes from 'prop-types';

import styled from '@emotion/styled';
import { css } from '@emotion/react';

import PageSection from '../../containers/LandlordPanel/components/PageSection';
import { mediaQueries } from '../../shared/breakpoints';

import withScreenScrollPosition from '../../hoc/withScreenScrollPosition';

import ToastConsumer from './ToastConsumer';
import toastChannels from './toastChannels';

const TOP_THRESHOLD = 55;

function distanceFromTop(scrollTop, offset = 0) {
  return (scrollTop > TOP_THRESHOLD ? 0 : TOP_THRESHOLD - scrollTop) + offset;
}

const SectionContainer = styled.div`
  ${({ top, topXL }) => css`
    position: fixed;
    top: ${top}px;
    left: 0;
    right: 0;
    z-index: 1;

    ${mediaQueries.md(css`
      left: 72px;
    `)};

    ${mediaQueries.xl(css`
      top: ${topXL}px;
      left: auto;
      right: 10px;
    `)}
  `}
`;

const GlobalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;

  ${mediaQueries.xl(css`
    left: auto;
    top: 10px;
    right: 10px;
  `)}
`;

const Container = styled(PageSection)`
  padding: 0;
`;

const ToastContainer = ({ scroll }) => {
  const top = distanceFromTop(scroll.top);
  const topXL = distanceFromTop(scroll.top, 20);

  return (
    <Container>
      <SectionContainer top={top} topXL={topXL}>
        <ToastConsumer channel={toastChannels.SECTION} />
      </SectionContainer>
      <GlobalContainer>
        <ToastConsumer channel={toastChannels.DEFAULT} />
      </GlobalContainer>
    </Container>
  );
};

ToastContainer.propTypes = {
  scroll: PropTypes.shape({
    top: PropTypes.number.isRequired,
    left: PropTypes.number.isRequired
  }).isRequired
};

export default withScreenScrollPosition(ToastContainer, 0, 0);
