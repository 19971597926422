import { Box, Drawer, Grid, MenuItem, MenuList, Toolbar } from '@mui/material';
import { useTheme } from '@mui/styles';

import { Typography } from '@spotahome/ui-library/base';
import { useState } from 'react';
import * as React from 'react';
import PropTypes from 'prop-types';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import localesShape from '../../../../shapes/locales';

import WorldGlobe from '../../../../../../icons/WorldGlobe';

import Es from '../../../../../../icons/es';
import En from '../../../../../../icons/en';
import It from '../../../../../../icons/it';
import De from '../../../../../../icons/de';
import Fr from '../../../../../../icons/fr';
import Pt from '../../../../../../icons/pt';

import useStyles from './styles';

const flags = {
  es: <Es />,
  en: <En />,
  it: <It />,
  de: <De />,
  fr: <Fr />,
  pt: <Pt />
};

const MobileLanguageMenu = ({ locales, onLanguageChange }) => {
  const classes = useStyles();
  const [languageMenuIsOpen, setLanguageMenuOpen] = useState(false);
  const [lang, setLang] = React.useState(locales.current);

  const handleLanguageChange = locale => {
    setLang(locale);
    onLanguageChange(locale);
    setLanguageMenuOpen(false);
  };

  const muiTheme = useTheme();

  const handleLanguageMenuClick = () => {
    setLanguageMenuOpen(!languageMenuIsOpen);
  };

  return (
    <Box sx={{ flexGrow: 0, display: { md: 'flex' } }}>
      <Box
        className={classes.languageMenuOption}
        onClick={handleLanguageMenuClick}
      >
        <WorldGlobe />
        <Typography.HeadingS>{lang}</Typography.HeadingS>
        <Box className={classes.rightIcon}>
          <ChevronRightIcon
            style={{
              fontSize: '24px'
            }}
          />
        </Box>
      </Box>

      <Drawer
        anchor={'right'}
        open={languageMenuIsOpen}
        onClose={() => setLanguageMenuOpen(false)}
        theme={muiTheme}
        className={classes.fontNormalizer}
      >
        <Toolbar className={classes.toolbar}>
          <Grid container alignItems={'center'}>
            <Box
              sx={{
                marginRight: 'auto',
                display: 'flex'
              }}
            >
              <ArrowBackIcon
                onClick={handleLanguageMenuClick}
                style={{
                  fontSize: '24px'
                }}
              />
            </Box>
            <Box
              sx={{
                marginLeft: 'auto',
                marginRight: 'auto',
                textTransform: 'capitalize'
              }}
            >
              <Typography.HeadingS>{lang}</Typography.HeadingS>
            </Box>
            <Box sx={{ marginLeft: 'auto', display: 'flex' }}>
              <CloseIcon
                onClick={handleLanguageMenuClick}
                style={{
                  fontSize: '24px'
                }}
              />
            </Box>
          </Grid>
        </Toolbar>
        <Box role="presentation" sx={{ width: '100vw' }}>
          <MenuList className={classes.menu}>
            {Object.keys(locales.ui).map(locale => (
              <MenuItem
                sx={{
                  paddingBottom: '32px',
                  '&:hover': {
                    backgroundColor: 'transparent'
                  }
                }}
                disableRipple
                key={locale}
                onClick={() => handleLanguageChange(locale)}
              >
                <Box className={classes.flag}>{flags[locale]}</Box>
                <Box sx={{ px: 1 }}>
                  <Typography.HeadingS>
                    {locales.ui[locale].name}
                  </Typography.HeadingS>
                </Box>
              </MenuItem>
            ))}
          </MenuList>
        </Box>
      </Drawer>
    </Box>
  );
};

MobileLanguageMenu.propTypes = {
  locales: PropTypes.shape(localesShape).isRequired,
  onLanguageChange: PropTypes.func.isRequired
};

export default MobileLanguageMenu;
