import { PropTypes } from 'prop-types';

import { Button } from '@spotahome/ui-library';
import { getPagePath } from '@spotahome/soyuz/client';

import useStyles from './styles';

const LandingButton = ({
  children,
  href = null,
  onClick = () => null,
  dataTest = ''
}) => {
  const { button: classes } = useStyles();

  return (
    <Button
      data-test={dataTest}
      className={classes.cta}
      size="big"
      color="primary"
      href={href || getPagePath('landlord.signup')}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

LandingButton.propTypes = {
  dataTest: PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired
};

export default LandingButton;
