import { makeStyles } from '@mui/styles';

const baseSize = '10px';
const animationDuration = '1.5s';

const HotspotPrecision = makeStyles({
  root: {
    position: 'relative'
  },
  led: {
    position: 'absolute',
    top: 0,
    backgroundColor: 'var(--sah-primary)',
    '&:after, &:before': {
      pointerEvents: 'none',
      content: '""',
      height: baseSize,
      width: baseSize,
      borderRadius: '50%',
      position: 'absolute',
      top: 0,
      opacity: 1,
      backgroundColor: 'inherit'
    }
  },
  right: {
    right: '-15px',
    '&:after, &:before': {
      right: 0
    },
    '&:before': {
      animation: `$led-out-in-right ${animationDuration} linear infinite`
    },
    '&:after': {
      animation: `$led-out-in-right ${animationDuration} linear calc(${animationDuration} / 2) infinite`
    }
  },
  left: {
    left: 0,
    '&:after, &:before': {
      left: 0
    },
    '&:before': {
      animation: `$led-out-in-left ${animationDuration} linear infinite`
    },
    '&:after': {
      animation: `$led-out-in-left ${animationDuration} linear calc(${animationDuration} / 2) infinite`
    }
  },
  hidden: {
    display: 'none'
  },
  '@keyframes led-out-in-right': {
    '0%': {
      height: `calc(2 * ${baseSize})`,
      width: `calc(2 * ${baseSize})`,
      top: `calc(${baseSize} / -2)`,
      right: `calc(${baseSize} / -2)`,
      opacity: 0.5
    },
    '25%': {
      opacity: 0
    },
    '50%': {
      height: baseSize,
      width: baseSize,
      top: 0,
      right: 0,
      opacity: 1
    },
    '100%': {
      height: `calc(2 * ${baseSize})`,
      width: `calc(2 * ${baseSize})`,
      top: `calc(${baseSize} / -2)`,
      right: `calc(${baseSize} / -2)`,
      opacity: 0.5
    }
  },
  '@keyframes led-out-in-left': {
    '0%': {
      height: `calc(2 * ${baseSize})`,
      width: `calc(2 * ${baseSize})`,
      top: `calc(${baseSize} / -2)`,
      left: `calc(${baseSize} / -2)`,
      opacity: 0.5
    },
    '25%': {
      opacity: 0
    },
    '50%': {
      height: baseSize,
      width: baseSize,
      top: 0,
      left: 0,
      opacity: 1
    },
    '100%': {
      height: `calc(2 * ${baseSize})`,
      width: `calc(2 * ${baseSize})`,
      top: `calc(${baseSize} / -2)`,
      left: `calc(${baseSize} / -2)`,
      opacity: 0.5
    }
  }
});
export default () => ({
  hotspotPrecision: HotspotPrecision()
});
