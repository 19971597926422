import { createStyles, makeStyles } from '@mui/styles';

// This should be removed after using the correct theme in every page

export const fontNormalizer = {
  '--sah-font-s': '14px',
  '--sah-font-m': '16px',
  '--sah-font-l': '18px',
  '--sah-font-bold': "'PoppinsMedium', 'Poppins-fallback'",
  '--sah-font-light': "'PoppinsLight', 'Poppins-fallback'"
};

export default makeStyles(() =>
  createStyles({
    fontNormalizer
  })
);
