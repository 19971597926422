import PropTypes from 'prop-types';

import BaseIcon from './Base';

const WhatsappBubble = ({
  color = '#25D366',
  pathColor = '#fff',
  ...props
}) => (
  <BaseIcon width="24" height="24" fill="none" {...props}>
    <circle cx="12" cy="12" r="12" fill={color} />
    <g clipPath="url(#whatsapp-bubble-group)">
      <path
        fill={pathColor}
        fillRule="evenodd"
        d="M15.384 13.489c-.186-.093-1.099-.542-1.269-.605-.17-.062-.294-.092-.418.094-.124.185-.48.604-.588.728-.108.124-.216.139-.402.046-.186-.093-.785-.289-1.494-.921a5.593 5.593 0 0 1-1.033-1.287c-.108-.186-.012-.287.081-.379.084-.083.186-.217.279-.325.093-.109.124-.186.186-.31s.03-.232-.016-.326c-.046-.093-.418-1.007-.573-1.379-.15-.362-.303-.312-.417-.319a8.035 8.035 0 0 0-.356-.006.68.68 0 0 0-.495.233c-.17.185-.65.635-.65 1.549 0 .914.665 1.797.758 1.921.093.124 1.31 2 3.172 2.805.444.19.79.305 1.06.39.444.142.85.122 1.17.074.355-.053 1.098-.45 1.253-.883.154-.434.154-.806.108-.883-.046-.078-.17-.124-.356-.217Zm-3.388 4.627h-.003a6.168 6.168 0 0 1-3.145-.862l-.225-.133-2.339.613.625-2.28-.147-.233a6.163 6.163 0 0 1-.944-3.288 6.187 6.187 0 0 1 6.18-6.177c1.65 0 3.201.643 4.368 1.81a6.139 6.139 0 0 1 1.807 4.372 6.187 6.187 0 0 1-6.177 6.178ZM17.253 6.68a7.385 7.385 0 0 0-5.258-2.18c-4.096 0-7.431 3.334-7.432 7.432a7.415 7.415 0 0 0 .991 3.716L4.5 19.5l3.94-1.034a7.427 7.427 0 0 0 3.553.905h.003c4.096 0 7.43-3.334 7.432-7.433a7.387 7.387 0 0 0-2.175-5.258Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="whatsapp-bubble-group">
        <path fill="#fff" d="M4.5 4.5h15v15h-15z" />
      </clipPath>
    </defs>
  </BaseIcon>
);

WhatsappBubble.propTypes = {
  color: PropTypes.string,
  pathColor: PropTypes.string
};

export default WhatsappBubble;
