import { useState } from 'react';
import PropTypes from 'prop-types';

import { trackLandlordLandingFAQs } from '../../utils/track';

import FAQAccordionItem from './FAQAccordionItem';

const FAQAccordion = ({ data }) => {
  const [expanded, setExpanded] = useState(null);

  const handleChange = (panel, eventLabel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : null);
    if (newExpanded) {
      trackLandlordLandingFAQs(eventLabel);
    }
  };

  return (
    <div>
      {data.map((faqItem, index) => (
        <FAQAccordionItem
          id={`faq-item-${index}`}
          key={faqItem.top}
          top={faqItem.top}
          content={faqItem.content}
          isExpanded={expanded === faqItem.top}
          onCollapsedChange={handleChange(faqItem.top, faqItem.eventLabel)}
        />
      ))}
    </div>
  );
};

FAQAccordion.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      top: PropTypes.node.isRequired,
      content: PropTypes.node.isRequired,
      eventLabel: PropTypes.string.isRequired
    })
  ).isRequired
};

export default FAQAccordion;
