import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import { Card } from '@mui/material';

import { PLANS } from '../plansInfo';

const PlanContainer = makeStyles(() => ({
  title: {
    width: '100%',
    justifyContent: 'center',
    display: 'flex',
    paddingBottom: '16px'
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#F7F7F7'
    }
  },
  cell: {
    borderBottom: 'none'
  },
  buttonWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '16px'
  }
}));

export const StyledCard = styled(Card)(({ theme, isSelected, plan }) => ({
  borderRadius: '24px',
  width: '285px',
  minHeight: '278px',
  border: '1px solid #EBEBEB',
  boxShadow: 'none',
  display: 'flex',
  flexDirection: 'column',
  margin: 'auto',
  backgroundColor: 'transparent',
  '&.plus': {
    borderTopLeftRadius: '0',
    borderTopRightRadius: '0'
  },
  [theme.breakpoints.up('md')]: {
    border: '3px solid #EBEBEB',
    width: '255px',
    borderBottom: 'none',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    minWidth: '100%',
    ...(plan === PLANS.STANDARD && {
      borderRight: 'none',
      borderTopRightRadius: 0,
      ...(isSelected && { border: 'none' })
    }),
    ...(plan === PLANS.PLUS && {
      borderLeft: 'none',
      borderRight: 'none',
      ...(isSelected && { border: 'none' })
    }),
    ...(plan === PLANS.ALLIN && {
      borderLeft: 'none',
      borderTopLeftRadius: 0,
      ...(isSelected && { border: 'none' })
    })
  }
}));

const PlanCard = makeStyles(theme => ({
  title: {
    paddingBottom: 8,
    textTransform: 'capitalize'
  },
  icon: {
    paddingBottom: 16
  },
  actionButton: {
    height: 34
  },
  plusHat: {
    textTransform: 'uppercase',
    backgroundColor: '#0A0777',
    height: '20px',
    borderTopLeftRadius: '24px',
    borderTopRightRadius: '24px',
    width: '285px',
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& > p': {
      color: '#FFFFFF',
      fontSize: ' 10px'
    },

    [theme.breakpoints.up('md')]: {
      width: '100%'
    }
  }
}));

export default () => ({
  planContainer: PlanContainer(),
  planCard: PlanCard()
});
