import { forwardRef, Fragment } from 'react';

import UserDetails from '../UserDetails';
import LogoutTooltip from '../LogoutTooltip';

import { noop } from '../../../../utils/fn';

import { HeaderToolbarPropTypes } from './propTypes';

const HeaderToolbarDesktop = forwardRef(
  (
    {
      name,
      email,
      avatarBorderWidth,
      logoutTitle,
      isLogoutTooltipOpen,
      logoutButtonText,
      onLogout,
      distance
    },
    ref
  ) => {
    return (
      <Fragment>
        <UserDetails
          name={name}
          email={email}
          avatarBorderWidth={avatarBorderWidth}
        />
        <LogoutTooltip
          title={logoutTitle}
          isOpen={isLogoutTooltipOpen}
          logoutButtonText={logoutButtonText}
          onLogout={onLogout}
          distance={distance}
          ref={ref}
        />
      </Fragment>
    );
  }
);

HeaderToolbarDesktop.defaultProps = {
  onLogout: noop,
  distance: 30
};

HeaderToolbarDesktop.propTypes = HeaderToolbarPropTypes;

export default HeaderToolbarDesktop;
