import BaseIcon from './Base';

const InfoCircledIcon = props => (
  <BaseIcon width="32" height="32" viewBox="0 0 32 32" {...props}>
    <g fill="none">
      <circle cx="16" cy="16" r="11.5" stroke="currentColor" />
      <path
        fill="currentColor"
        d="M16 13a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm-1 1h2v7h-2v-7z"
      />
    </g>
  </BaseIcon>
);

export default InfoCircledIcon;
