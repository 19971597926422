import styled from '@emotion/styled';

import { NavItem } from '../NavMenu/styles';

export const RegisterNavItem = styled(NavItem)`
  padding: 0 4px 0;
  ${props => props.theme.breakpoints.up('md')} {
    padding: 0 4px 0;
  }
`;

export const Container = styled.div`
  display: flex;
  margin 40px 0;
  ${props => props.theme.breakpoints.up('md')} {
    margin: 0;
  }
`;
