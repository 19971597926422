/* eslint-disable no-restricted-globals */
import { useEffect } from 'react';
import axios from 'axios';

import { useSoyuzClientConfig, useSoyuzLocales } from '@spotahome/soyuz/client';
import SoyuzAnalytics from '@spotahome/soyuz-analytics';

import { useCookie } from '../utils/withCookie';

const COOKIE_VALUE = 'google';
const COOKIE_NEW_SOCIAL_USER = 'sah-ui-social';

const sendTracking = notification => {
  if (
    notification.isDismissedMoment() &&
    notification.getDismissedReason() !== 'credential_returned'
  ) {
    const dismissedReason = notification.getDismissedReason();
    SoyuzAnalytics.sendGA4Event('GoogleOneTap', {
      action: 'dismissed',
      reason: dismissedReason
    });
  }
};

const OAUTH_LOGIN_URI = '/auth/oauth/sign-in-google';

const GoogleOneTap = () => {
  const { current: currentLocale } = useSoyuzLocales();
  const cookieManager = useCookie();
  const { googleOneTapId } = useSoyuzClientConfig();

  const handleSuccessCallback = response => {
    const { credential } = response;

    axios
      .post(OAUTH_LOGIN_URI, {
        credential,
        locale: currentLocale
      })
      .then(resp => {
        if (resp.data.redirectUrl) {
          window.location.href = resp.data.redirectUrl;
        } else {
          const isNewUser =
            cookieManager.getCookie(COOKIE_NEW_SOCIAL_USER) === '1';
          SoyuzAnalytics.sendGA4Event('signup/login/landlord', {
            method: 'google'
          });
          const newUser = isNewUser
            ? 'new-user-landlord'
            : 'already-user-landlord';
          SoyuzAnalytics.sendGA4Event('GoogleOneTap', {
            action: 'success',
            isNewUser: newUser
          });

          window.location.href = '/properties';
        }
      });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleScriptLoad = () => {
    if (window.google.accounts) {
      window.google.accounts.id.initialize({
        client_id: googleOneTapId,
        callback: handleSuccessCallback,
        cancel_on_tap_outside: false,
        use_fedcm_for_prompt: true,
        context: 'use'
      });
      window.google.accounts.id.prompt(sendTracking);
    }
  };

  useEffect(() => {
    const mustCreateScript =
      cookieManager.getCookie('authMethod') === COOKIE_VALUE ||
      !cookieManager.getCookie('authMethod');

    if (mustCreateScript) {
      const script = document.createElement('script');
      script.defer = true;
      script.src = 'https://accounts.google.com/gsi/client';
      script.onload = handleScriptLoad;

      document.body.appendChild(script);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default GoogleOneTap;
