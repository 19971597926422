import styled from '@emotion/styled';
import { rgba } from 'polished';

import colors from '../../../../shared/colors';
import fonts from '../../../../shared/fonts';
import { space, border } from '../../../../shared/spacing';

export const Anchor = styled.a`
  display: flex;
  flex-direction: column;
  background: ${rgba(colors.white, 0.1)};
  border-radius: ${border.radius};
  text-decoration: none;
  text-align: center;
  font-size: ${fonts.sizes.s};
  font-family: ${fonts.GothamMedium};
  line-height: ${fonts.lineHeights.regular};
  color: ${colors.white};

  &:hover {
    background: ${rgba(colors.white, 0.4)};
    font-family: ${fonts.GothamBold};
  }

  &:active {
    background: ${rgba(colors.white, 0.4)};
  }
`;

export const AnchorContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: ${space.x2} ${space.x2};
`;

export const AnchorTextWrapper = styled.div(props => ({
  display: 'flex',
  flexDirection: 'column',
  textAlign: props.align
}));

export const AnchorIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: ${space.x2};
`;

export const AnchorTextSubtitle = styled.div(props => ({
  fontSize: props.smaller && fonts.sizes.xxs,
  fontFamily: props.smaller && fonts.Gotham
}));
