import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

import BaseIcon from './Base';

const HealthAndSafety = ({ width = 20, height = 25, ...props }) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '32px',
      height: '32px'
    }}
  >
    <BaseIcon width={width} height={height} viewBox="0 0 20 25" {...props}>
      <path
        d="M8.33333 17.3333H11.6667V14H15V10.6667H11.6667V7.33333H8.33333V10.6667H5V14H8.33333V17.3333ZM10 25.6333C7.11111 24.8556 4.72222 23.1613 2.83333 20.5507C0.944445 17.9391 0 15.0222 0 11.8V4.13333L10 0.399994L20 4.13333V11.8C20 15.0222 19.0556 17.9391 17.1667 20.5507C15.2778 23.1613 12.8889 24.8556 10 25.6333ZM10 23.5333C12.3111 22.8 14.2222 21.3333 15.7333 19.1333C17.2444 16.9333 18 14.4889 18 11.8V5.49999L10 2.49999L2 5.49999V11.8C2 14.4889 2.75556 16.9333 4.26667 19.1333C5.77778 21.3333 7.68889 22.8 10 23.5333Z"
        fill="#0D0D0D"
      />
    </BaseIcon>
  </Box>
);

HealthAndSafety.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
};

export default HealthAndSafety;
