import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import icons from './icon-names';

const generateIconStyle = ({ color, size, name }) => css`
  line-height: 1;
  color: ${color};
  font-size: ${size};
  font-style: normal;

  &:before {
    font-family: ${icons[name].font};
    content: '\\${icons[name].code}';
  }
`;

const Icon = styled.i`
  ${props => generateIconStyle(props)};
`;

const StyledIcon = ({
  color = 'inherit',
  size = 'inherit',
  name,
  className = null
}) => <Icon color={color} size={size} name={name} className={className} />;

StyledIcon.propTypes = {
  /** Color for the icon  */
  color: PropTypes.string,
  /** Size that the icon will have */
  size: PropTypes.string,
  /** Name for the icon (which should be included in the font which is being loaded) */
  name: PropTypes.oneOf(Object.keys(icons)).isRequired,
  /** Class name to extend the styles */
  className: PropTypes.string
};

export default StyledIcon;
