import { memo } from 'react';
import PropTypes from 'prop-types';

import styles from './styles';

const variants = {
  DisplayXXL: {
    name: 'DisplayXXL',
    htmlElement: 'h1',
    variantClassName: 'display',
    size: 'xxl'
  },
  DisplayXL: {
    name: 'DisplayXL',
    htmlElement: 'h1',
    variantClassName: 'display',
    size: 'xl'
  },
  DisplayL: {
    name: 'DisplayL',
    htmlElement: 'h1',
    variantClassName: 'display',
    size: 'l'
  },
  DisplayM: {
    name: 'DisplayM',
    htmlElement: 'h1',
    variantClassName: 'display',
    size: 'm'
  },
  DisplayS: {
    name: 'DisplayS',
    htmlElement: 'h1',
    variantClassName: 'display',
    size: 's'
  },
  DisplayXS: {
    name: 'DisplayXS',
    htmlElement: 'h1',
    variantClassName: 'display',
    size: 'xs'
  },
  HeadingL: {
    name: 'HeadingL',
    htmlElement: 'h1',
    variantClassName: 'heading',
    size: 'l'
  },
  HeadingM: {
    name: 'HeadingM',
    htmlElement: 'h1',
    variantClassName: 'heading',
    size: 'm'
  },
  HeadingS: {
    name: 'HeadingS',
    htmlElement: 'h1',
    variantClassName: 'heading',
    size: 's'
  },
  ParagraphXL: {
    name: 'ParagraphXL',
    htmlElement: 'p',
    variantClassName: 'paragraph',
    size: 'xl'
  },
  ParagraphL: {
    name: 'ParagraphL',
    htmlElement: 'p',
    variantClassName: 'paragraph',
    size: 'l'
  },
  ParagraphM: {
    name: 'ParagraphM',
    htmlElement: 'p',
    variantClassName: 'paragraph',
    size: 'm'
  },
  ParagraphMTall: {
    name: 'ParagraphMTall',
    htmlElement: 'p',
    variantClassName: 'paragraph',
    size: 'm'
  },
  ParagraphS: {
    name: 'ParagraphS',
    htmlElement: 'p',
    variantClassName: 'paragraph',
    size: 's'
  },
  ParagraphSTall: {
    name: 'ParagraphSTall',
    htmlElement: 'p',
    variantClassName: 'paragraph',
    size: 'st'
  },
  ParagraphSShort: {
    name: 'ParagraphSShort',
    htmlElement: 'p',
    variantClassName: 'paragraph',
    size: 'ss'
  },
  ParagraphXS: {
    name: 'ParagraphXS',
    htmlElement: 'p',
    variantClassName: 'paragraph',
    size: 'xs'
  },
  LabelM: {
    name: 'LabelM',
    htmlElement: 'p',
    variantClassName: 'label',
    size: 'm'
  },
  LabelS: {
    name: 'LabelS',
    htmlElement: 'p',
    variantClassName: 'label',
    size: 's'
  },
  TagL: {
    name: 'TagL',
    htmlElement: 'p',
    variantClassName: 'tag',
    size: 'l'
  },
  TagM: {
    name: 'TagM',
    htmlElement: 'p',
    variantClassName: 'tag',
    size: 'm'
  },
  TagS: {
    name: 'TagS',
    htmlElement: 'p',
    variantClassName: 'tag',
    size: 's'
  }
};

const Typography = ({
  variant,
  dataTest = undefined,
  color = null,
  children,
  isHTML = false,
  className = null,
  renderAs = null
}) => {
  const variantObj = variants[variant];
  const useStylesTypography = styles.typography;
  const classesTypography = useStylesTypography({ color });
  const useStylesVariant = styles[variantObj.variantClassName];
  const classesVariant = useStylesVariant({ color });
  const Component = renderAs || variantObj.htmlElement;

  if (isHTML) {
    return (
      <Component
        className={`${className || ''} ${classesTypography.root} ${
          classesVariant.root
        } ${
          classesVariant[`${variantObj.variantClassName}__${variantObj.size}`]
        }`}
        data-test={dataTest}
        dangerouslySetInnerHTML={{ __html: children }}
      />
    );
  }

  return (
    <Component
      className={`${className || ''} ${classesTypography.root} ${
        classesVariant.root
      } ${
        classesVariant[`${variantObj.variantClassName}__${variantObj.size}`]
      }`}
      data-test={dataTest}
    >
      {children}
    </Component>
  );
};

Typography.propTypes = {
  variant: PropTypes.oneOf([
    'DisplayXXL',
    'DisplayXL',
    'DisplayL',
    'DisplayM',
    'DisplayS',
    'DisplayXS',
    'HeadingL',
    'HeadingM',
    'HeadingS',
    'ParagraphXL',
    'ParagraphL',
    'ParagraphM',
    'ParagraphMTall',
    'ParagraphS',
    'ParagraphSTall',
    'ParagraphSShort',
    'ParagraphXS',
    'LabelM',
    'LabelS',
    'TagL',
    'TagM',
    'TagS'
  ]).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.string
  ]).isRequired,
  dataTest: PropTypes.string,
  color: PropTypes.string,
  isHTML: PropTypes.bool,
  className: PropTypes.string,
  renderAs: PropTypes.node
};

export default memo(Typography);
