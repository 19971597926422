import { makeStyles } from '@mui/styles';

const PlusContent = makeStyles(theme => ({
  main: {
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'stretch',
    margin: 0,
    [theme.breakpoints.up('sm')]: {
      margin: '80px 48px'
    }
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: 32,
    width: '100%',
    maxWidth: 590,
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'start',
      width: '50%',
      paddingLeft: 64
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: 100
    }
  },
  ctaContent: {
    '& > *': {
      marginBottom: 32
    }
  },
  cta: {
    fontFamily: 'var(--sah-font-bold)',
    fontSize: 'var(--sah-font-m)',
    display: 'inline-block',
    margin: 0,
    padding: '16px 32px',
    borderRadius: 100,
    minWidth: 150,
    [theme.breakpoints.up('sm')]: {
      borderRadius: '0',
      width: 'auto',
      minWidth: 200
    },
    [theme.breakpoints.up('md')]: {
      minWidth: 320
    }
  }
}));

const PlusPicture = makeStyles(theme => ({
  desktop: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'flex'
    }
  },
  mobile: {
    display: 'flex',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    },
    '& > img': {
      maxWidth: 675,
      width: '100%'
    },
    '& > picture': {
      width: '100%'
    }
  },
  picture: {
    overflow: 'hidden',
    margin: 0,
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'end',
      width: '50%'
    }
  },
  img: {
    objectFit: 'cover',
    borderRadius: 0,
    [theme.breakpoints.up('sm')]: {
      borderRadius: '24px'
    }
  }
}));

export default () => ({
  content: PlusContent(),
  picture: PlusPicture()
});
