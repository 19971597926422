import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import SettingsMUIIcon from '@mui/icons-material/SettingsOutlined';

const SettingIcon = ({ color = undefined, ...props }) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '32px',
      height: '32px',
      fontSize: '32px'
    }}
    {...props}
  >
    <SettingsMUIIcon color={color} fontSize="inherit" />
  </Box>
);

SettingIcon.propTypes = {
  color: PropTypes.string
};

export default SettingIcon;
