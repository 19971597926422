import styled from '@emotion/styled';
import { css } from '@emotion/react';

import colors from '../../../../shared/colors';
import { space } from '../../../../shared/spacing';
import { mediaQueries } from '../../../../shared/breakpoints';
import TextButton from '../../../../components/TextButton';

export const Container = styled.header`
  position: relative;
  min-height: ${space.x7};
  padding: 0 ${space.x2};
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${colors.darkBlue};

  background-position: ${space.x8} center;

  ${({ backgroundColor }) =>
    mediaQueries.xl(css`
      flex-flow: row-reverse;
      background: ${backgroundColor};
      pointer-events: none;
    `)};
`;

export const RightContent = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  pointer-events: auto;
`;

export const MenuButton = styled(TextButton)`
  display: flex;
  color: ${colors.white};

  ${mediaQueries.xl(css`
    display: none;
  `)};
`;

export const LogoContainer = styled.div`
  margin-right: auto;
  margin-left: ${space.x4};
  ${mediaQueries.xl(css`
    display: none;
  `)};
`;
