import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';

import { border, space } from '../../shared/spacing';
import { mediaQueries } from '../../shared/breakpoints';
import fonts from '../../shared/fonts';
import colors from '../../shared/colors';

import { cloneStyledElement } from '../../utils/react';

import ButtonSpinner from './ButtonSpinner';

const generateButtonStyle = css`
  color: ${colors.white};
  background: ${colors.primary};

  &:hover {
    color: ${colors.white};

    &:not(:disabled) {
      background: ${colors.primaryLight};
    }
  }

  &:active:not(:disabled) {
    color: ${colors.white};
    background: ${colors.primary};
    box-shadow: inset 0 -2px ${colors.purple50};
    transform: translateY(2px);
  }

  &[disabled] {
    background: ${colors.greyLight};
    cursor: not-allowed;
  }
`;

const generateAltButtonStyle = css`
  color: ${colors.primary};
  border: ${border.size} solid currentColor;
  background: transparent;

  &:hover {
    color: ${colors.primary};

    &:not(:disabled) {
      color: ${colors.primaryLight};
    }
  }

  &:active:not(:disabled) {
    color: ${colors.primary};
    box-shadow: inset 0 -2px currentColor;
    transform: translateY(2px);
  }

  &[disabled] {
    color: ${colors.greyLight};
    cursor: not-allowed;
  }
`;

const loadingStyle = css`
  cursor: default;
  pointer-events: none;
  user-select: none;
  opacity: 0.5;
`;

const iconStyle = css`
  width: 20px;
  height: 20px;
  margin-right: ${space.x1};
  color: currentColor;
`;

const generateButtonSizes = (fullWidthOnMobile, icon) => css`
  padding: ${space.x15} ${space.x3};
  padding: ${icon ? `calc(${space.x15} - 1px) ${space.x3}` : undefined};
  width: ${fullWidthOnMobile ? '100%' : 'auto'};

  ${mediaQueries.md(css`
    width: auto;
    padding: ${space.x1} ${space.x3};
    padding: ${icon ? `calc(${space.x1} - 1px) ${space.x3}` : undefined};
  `)};
`;

const StyledButton = styled('button', {
  shouldForwardProp: prop => isPropValid(prop) && prop !== 'alt'
})`
  ${({ loading, alt, fullWidthOnMobile, icon }) => css`
    display: inline-block;
    border: none;
    border-radius: ${border.radius};
    text-align: center;
    cursor: pointer;
    font-family: inherit;
    text-decoration: none;
    transition: all 0.1s ease-out;
    font-size: ${fonts.sizes.m};

    &:focus {
      outline: none;
    }

    ${loading && loadingStyle};
    ${alt ? generateAltButtonStyle : generateButtonStyle};
    ${generateButtonSizes(fullWidthOnMobile, icon)};
  `}
`;

const ButtonSpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${space.x125};
  justify-content: center;
`;

const StyledButtonSpinner = styled(ButtonSpinner)`
  position: relative;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Button = ({
  children,
  alt = false,
  fullWidthOnMobile = false,
  loading = false,
  icon = null,
  type = 'button',
  ...htmlProps
}) => (
  <StyledButton
    type={type}
    {...htmlProps}
    fullWidthOnMobile={fullWidthOnMobile}
    alt={alt ? 1 : 0}
    loading={loading ? 1 : 0}
    icon={icon}
  >
    <Wrapper>
      {!loading &&
        icon &&
        cloneStyledElement(icon, {
          css: iconStyle
        })}
      {loading && (
        <ButtonSpinnerWrapper>
          <StyledButtonSpinner
            color={alt ? colors.primary : colors.white}
            size={fonts.sizes.l}
          />
        </ButtonSpinnerWrapper>
      )}
      {children}
    </Wrapper>
  </StyledButton>
);

Button.propTypes = {
  /** Button type */
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  /** Decides if the button should have full width on mobile or not */
  fullWidthOnMobile: PropTypes.bool,
  /** Invert the colors of the style defined for the button */
  alt: PropTypes.bool,
  /** Indicates if the button is processing an action or not */
  loading: PropTypes.bool,
  /** Primary button content. */
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.array
  ]).isRequired,
  /** Icon */
  icon: PropTypes.object
};

export default Button;
