import { memo } from 'react';
import PropTypes from 'prop-types';

import { Consumer } from './ToastContext';

/**
 * This wrapper prevents render the 'component' when ToastProvider state is modified
 */
const Wrapper = memo(({ component: Component, innerProps, ...rest }) => (
  <Component toast={rest} {...innerProps} />
));

Wrapper.defaultProps = {
  innerProps: {}
};

Wrapper.propTypes = {
  innerProps: PropTypes.object,
  component: PropTypes.func.isRequired
};

const withToast = WrappedComponent => props =>
  (
    <Consumer>
      {({ toasts, ...rest }) => (
        <Wrapper component={WrappedComponent} {...rest} innerProps={props} />
      )}
    </Consumer>
  );

export default withToast;
