import { Children } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, IconButton, Toolbar } from '@mui/material';
import { Logo } from '@spotahome/ui-library/base';

import SpotahomeSymbol from '../../../../../../../icons/SpotahomeSymbol';

import LandingLink from '../../../../LandingLink';

const MobileTopBar = ({ children, withSymbol = false, title = null }) => {
  return (
    <Toolbar>
      <Grid container sx={{ alignItems: 'center' }}>
        <LandingLink>{withSymbol ? <SpotahomeSymbol /> : <Logo />}</LandingLink>

        {title && <Box sx={{ marginLeft: 2 }}>{title}</Box>}

        <Box sx={{ marginLeft: 'auto', display: 'flex', gap: '20px' }}>
          {Children.map(children, child => (
            <IconButton style={{ padding: 0 }} size="large">
              {child}
            </IconButton>
          ))}
        </Box>
      </Grid>
    </Toolbar>
  );
};

MobileTopBar.propTypes = {
  children: PropTypes.node.isRequired,
  withSymbol: PropTypes.bool,
  title: PropTypes.node
};

export default MobileTopBar;
