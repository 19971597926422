import { makeStyles } from '@mui/styles';

const FeatureBox = makeStyles(theme => ({
  box: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'column'
    }
  },
  icon: {
    minWidth: '50px',
    width: '50px',
    textAlign: 'center',
    marginRight: '16px',
    marginBottom: '32px',
    [theme.breakpoints.up('sm')]: {
      textAlignLast: 'left'
    }
  },
  description: {
    fontSize: 'var(--sah-font-m)',
    lineHeight: '24px',
    color: 'var(--sah-dark)',
    padding: 0,
    [theme.breakpoints.up('sm')]: {
      padding: '8px 0'
    }
  },
  title: {
    fontFamily: 'var(--sah-font-bold)',
    color: 'var(--sah-dark)',
    padding: 0,
    marginTop: 0,
    fontSize: 'var(--sah-font-xl)',
    lineHeight: 'var(--sah-line-height-l)',
    fontWeight: 'var(--sah-font-w-bold)',
    marginBottom: '16px'
  },
  learnMore: {
    fontFamily: 'var(--sah-font-bold)',
    lineHeight: 'normal',
    color: 'var(--sah-dark)',
    fontSize: 'var(--sah-font-m)',
    marginTop: '24px',
    padding: 0,
    textDecoration: 'underline',
    border: 0,
    background: 'transparent',
    textAlign: 'left',
    cursor: 'pointer',
    outline: 'none'
  },
  learnMoreDesc: {
    '& *': {
      margin: 0
    },
    '& .info': {
      marginBottom: '32px'
    },
    '& .header': {
      fontFamily: 'var(--sah-font-bold)',
      marginBottom: '8px',
      fontSize: 'var(--sah-font-m)'
    }
  },
  modal: {
    display: 'flex',
    flexDirection: 'column',
    padding: '32px',
    transition: 'height 0.3s linear',
    maxWidth: '780px',
    width: '100%',
    borderRadius: '0'
  },
  closeModal: {
    textAlign: 'right',
    '& svg': {
      width: '16px',
      height: 'auto'
    }
  }
}));

const FeatureBoxSection = makeStyles(theme => ({
  section: {
    width: '100%',
    padding: '48px 24px',
    display: 'flex',
    flexDirection: 'column',
    justifyItems: 'center',
    [theme.breakpoints.up('sm')]: {
      padding: '(40px * 2) 48px'
    },
    [theme.breakpoints.up('lg')]: {
      padding: '120px 24px'
    }
  },
  container: {
    width: '100%',
    maxWidth: '1330px',
    margin: 'auto'
  },
  title: {
    fontFamily: 'var(--sah-font-title)',
    fontWeight: 'var(--sah-font-w-regular)',
    fontSize: 'var(--sah-font-xxxxl)',
    lineHeight: 'var(--sah-line-height-xxxxl)',
    margin: '0 0 40px',

    [theme.breakpoints.up('sm')]: {
      fontSize: 'var(--sah-font-xxxxxxl)',
      lineHeight: 'var(--sah-line-height-xxxxxxl)',
      margin: '0 0 48px'
    },

    [theme.breakpoints.up('lg')]: {
      margin: '0 0 88px',
      fontSize: '64px',
      lineHeight: '72px'
    }
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridColumnGap: '40px',
    gridRowGap: '88px',
    marginLeft: '0',
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '1fr 1fr'
    },
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: '1fr 1fr 1fr'
    }
  }
}));

export default props => ({
  featureBox: FeatureBox(props),
  featureBoxSection: FeatureBoxSection(props)
});
