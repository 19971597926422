import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';

import { NotificationIconButton } from './styles';

const NotificationIcon = forwardRef(({ isActive, onClick }, ref) => {
  return (
    <NotificationIconButton
      isActive={isActive}
      data-testid="notification-button"
      onClick={onClick}
      ref={ref}
      size="large"
    >
      <NotificationsOutlinedIcon fontSize="large" />
    </NotificationIconButton>
  );
});

NotificationIcon.propTypes = {
  isActive: PropTypes.bool,
  onClick: PropTypes.func.isRequired
};

NotificationIcon.defaultProps = {
  isActive: false
};

export default NotificationIcon;
