import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';

import { useStyles } from './FooterLazyImage.style';

const FooterLazyImage = props => (
  <LazyLoad offset={100} once>
    <img className={useStyles().store} src={props.src} alt={props.alt} />
  </LazyLoad>
);

FooterLazyImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired
};

export default FooterLazyImage;
