import PropTypes from 'prop-types';

export default PropTypes.shape({
  current: PropTypes.string.isRequired,
  ui: PropTypes.objectOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired
    })
  ),
  routes: PropTypes.objectOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired
    })
  )
});
