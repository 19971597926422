import styled from '@emotion/styled';

import colors from '../../../../shared/colors';
import fonts from '../../../../shared/fonts';
import { space, border } from '../../../../shared/spacing';
import { pixelsToRem } from '../../../../utils/units';

export const MainItems = styled.div`
  margin-bottom: ${space.x5};
`;

export const MenuNav = styled.nav`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 130px);
`;

export const Item = styled.a`
  ${({ selected }) => `
    display: flex;
    align-items: baseline;
    padding: ${space.x2} ${space.x3};
    margin-bottom: ${space.x05};
    font-size: ${fonts.sizes.m};
    font-family: ${selected ? fonts.GothamBold : 'inherit'};
    color: ${colors.white};
    text-decoration: none;
    white-space: nowrap;
    border-radius: ${border.radius};
    background-color: ${selected ? 'rgba(0, 0, 0, 0.3)' : undefined};

    &:hover {
      background-color: rgba(0, 0, 0, 0.3);
    }

    transition: all 0.3s ease-in-out;

    &::before {
      content: '';
      display: inline-block;
      width: ${pixelsToRem(10)};
      height: ${pixelsToRem(10)};
      border-radius: 50%;
      margin-right: ${space.x2};
      background: ${selected ? colors.primaryLight : colors.logan};
    }
  `}
`;

export const NewLabel = styled.div`
  display: inline-block;
  padding: ${space.x05};
  margin-left: ${space.x15};
  border: 1px solid ${colors.white};
  border-radius: ${border.radius};
  font-size: ${fonts.sizes.xxs};
  font-family: ${fonts.Gotham};
`;

export const SupportItemSection = styled.div`
  margin-top: ${space.x4};
`;

export const SectionTitle = styled.div`
  margin-left: ${space.x3};
  font-size: ${fonts.sizes.s};
  font-family: ${fonts.GothamBold};
  color: ${colors.white};
  margin-bottom: ${space.x2};
`;

export const ReferralAnchor = styled.div`
  margin-bottom: ${space.x25};
`;

export const WhatsappAnchor = styled.div`
  margin-top: auto;
`;
