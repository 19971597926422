import PropTypes from 'prop-types';

const WorldIcon = ({ className = '', color = '#C9E3F7' }) => (
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M29.9999 57.1184C25.2818 53.1867 21.3178 45.6545 21.0006 31.8633L38.9991 31.8633C38.6819 45.6545 34.7179 53.1867 29.9999 57.1184Z"
      fill={color}
    />
    <path
      d="M38.2866 55.8259C48.6297 52.5021 56.2587 43.1234 56.9999 31.8633H44.4122C44.1822 42.5063 41.906 50.3127 38.2866 55.8259Z"
      fill={color}
    />
    <path
      d="M56.8207 26.4515C55.4284 16.0016 48.0723 7.43711 38.2866 4.29246C41.6984 9.48942 43.9166 16.724 44.3525 26.4515H56.8207Z"
      fill={color}
    />
    <path
      d="M29.9999 3C34.5081 6.75681 38.3277 13.8009 38.9354 26.4515L21.0643 26.4515C21.672 13.8009 25.4917 6.75681 29.9999 3Z"
      fill={color}
    />
    <path
      d="M21.7131 55.8259C18.0938 50.3127 15.8176 42.5063 15.5876 31.8633H2.99988C3.74109 43.1234 11.37 52.5021 21.7131 55.8259Z"
      fill={color}
    />
    <path
      d="M3.1791 26.4515H15.6472C16.0832 16.724 18.3014 9.48941 21.7131 4.29246C11.9275 7.43711 4.57133 16.0016 3.1791 26.4515Z"
      fill={color}
    />
  </svg>
);

export default WorldIcon;

WorldIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string
};
