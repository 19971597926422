import PropTypes from 'prop-types';
import TooltipLite from 'react-tooltip-lite';

import {
  positions,
  alignments,
  positionsArray,
  alignmentsArray
} from '../../tooltip-positions';

const Tooltip = ({
  children,
  content,
  showOnClick = false,
  distance = 10,
  position = positions.DOWN,
  alignment = alignments.MIDDLE,
  background = '',
  color = '',
  className = null,
  arrow = true,
  tipContentHover = false,
  isOpen = undefined,
  onToggle = undefined
}) => (
  <TooltipLite
    eventOn={showOnClick ? 'onClick' : null}
    eventOff={showOnClick ? 'onMouseOut' : null}
    useHover={!showOnClick}
    direction={`${position}-${alignment}`}
    distance={distance}
    content={content}
    background={background}
    color={color}
    className={className}
    arrow={arrow}
    tipContentHover={tipContentHover}
    isOpen={isOpen}
    onToggle={onToggle}
  >
    {children}
  </TooltipLite>
);

Tooltip.propTypes = {
  /** Class name to extend the styles */
  className: PropTypes.string,
  /** Content of the tooltip */
  content: PropTypes.node.isRequired,
  /** Css background property */
  background: PropTypes.string,
  /** Css color property */
  color: PropTypes.string,
  /** Position of the tooltip relative to the target (children) */
  position: PropTypes.oneOf(positionsArray),
  /** The alignment of the content */
  alignment: PropTypes.oneOf(alignmentsArray),
  /** Whether the tooltip should be displayed with a mouse click (not with hover) */
  showOnClick: PropTypes.bool,
  /** Distance from the target (children) */
  distance: PropTypes.number,
  /** Whether the arrow should be displayed */
  arrow: PropTypes.bool,
  /** The target */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  /** Whether a button to close the tooltip should be displayed */
  tipContentHover: PropTypes.bool,
  /** Whether the tooltip is being manually displayed or not */
  isOpen: PropTypes.bool,
  /** If passed, the called when the visibility of the tooltip changes */
  onToggle: PropTypes.func
};

Tooltip.positions = positions;
Tooltip.alignments = alignments;
Tooltip.positionsArray = positionsArray;
Tooltip.alignmentsArray = alignmentsArray;

export default Tooltip;
