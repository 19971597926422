import { makeStyles } from '@mui/styles';

const NavBar = makeStyles({
  root: {
    position: 'relative',
    backgroundColor: 'white',
    borderBottom: '1px solid #ebebeb'
  },
  container: {
    padding: '0 16px !important',
    '@media (min-width: 600px)': {
      padding: '0 40px !important'
    }
  },
  logoContainer: {
    marginRight: '56px'
  },
  rightMenu: {
    display: 'flex',
    flex: '1 1 auto',
    alignItems: 'center',
    justifyContent: 'flex-end'
  }
});

export default props => ({
  navBar: NavBar(props)
});
