import styled from '@emotion/styled';
import { Fab } from '@mui/material';

import { space } from '../../../../../shared/spacing';

export const WhatsappButton = styled(Fab)`
  position: fixed !important;
  bottom: ${space.x4};
  right: ${space.x3};
  height: 40px !important;
  padding: 0 16px 0 4px !important;
  text-transform: none !important;
  background-color: white !important;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.18) !important;
`;

export const TextFloatingButton = styled.p`
  padding-left: ${space.x1};
`;

export const WhatsappButtonMobile = styled(Fab)`
  height: 56px !important;
  text-transform: none !important;
  background-color: white !important;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.18) !important;
  margin-left: auto !important;
  margin-right: auto !important;
  width: calc(100vw - 40px) !important;
  border: 0;
  border-radius: 100px !important;
`;
