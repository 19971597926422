import PropTypes from 'prop-types';

import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { trans } from '@spotahome/soyuz/client';

import Typography from '../Typography';

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'var(--sah-white)',
    color: 'var(--sah-interaction-dark)',
    boxShadow: '0px 8px 28px 0px rgba(0, 0, 0, 0.28)',
    width: '240px',
    borderRadius: '16px',
    padding: '16px'
  }
});

const InfoTooltip = ({
  text,
  onClose = () => {},
  icon = <InfoOutlinedIcon />
}) => (
  <StyledTooltip
    title={
      <Typography variant="ParagraphS" isHTML>
        {trans(text)}
      </Typography>
    }
    onClose={onClose}
    enterTouchDelay={200}
  >
    {icon}
  </StyledTooltip>
);

InfoTooltip.propTypes = {
  text: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  icon: PropTypes.node
};

export default InfoTooltip;
