import PropTypes from 'prop-types';

const MoneyIcon = ({ className = '', color = '#FFCCEF' }) => (
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <circle cx="30" cy="30" r="27" fill={color} />
    <path
      d="M30.6366 39.2282C32.1747 39.2282 33.5174 38.9108 34.6648 38.2761C35.8366 37.6169 36.7521 36.6526 37.4113 35.3831H42.4282C41.4761 37.8244 39.9502 39.7042 37.8507 41.0225C35.7512 42.3408 33.3465 43 30.6366 43C27.7803 43 25.2413 42.231 23.0197 40.693C20.7981 39.1305 19.2479 37.0554 18.369 34.4676H15V31.6113H17.7099C17.661 30.9277 17.6366 30.3906 17.6366 30C17.6366 29.6094 17.661 29.0723 17.7099 28.3887H15V25.5324H18.369C19.2479 22.9446 20.7981 20.8817 23.0197 19.3437C25.2413 17.7812 27.7803 17 30.6366 17C33.3221 17 35.7146 17.6714 37.8141 19.0141C39.938 20.3324 41.4761 22.2122 42.4282 24.6535H37.4113C36.7521 23.384 35.8366 22.4319 34.6648 21.7972C33.5174 21.138 32.1747 20.8085 30.6366 20.8085C28.8545 20.8085 27.2798 21.2235 25.9127 22.0535C24.57 22.8836 23.5446 24.0432 22.8366 25.5324H30.6366V28.3887H22.031C21.9577 28.9014 21.9211 29.4385 21.9211 30C21.9211 30.5615 21.9577 31.0986 22.031 31.6113H30.6366V34.4676H22.8366C23.5446 35.9812 24.57 37.1531 25.9127 37.9831C27.2798 38.8131 28.8545 39.2282 30.6366 39.2282Z"
      fill="#FB6E44"
    />
  </svg>
);

export default MoneyIcon;

MoneyIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string
};
