const purple = '#880068';
const purpleLight = '#b20094';
const red = '#e13250';
const redError = red;

const aliases = {
  bg: '#f7f5f5',
  error: redError,
  primary: purple,
  primaryLight: purpleLight,
  blackZendesk: '#3c3c3c',
  blackZendeskHover: '#302e2e'
};

const colors = {
  darkBlue: '#002a4d',
  darkBlue500: '#0a0777',
  blue: '#133f54',
  blueVeryLight: '#e3e8ea',
  blueInfo: '#00adff',
  blueInfoLight: '#ccefff',
  blueHighLight: '#22b8ff',

  greenVeryDark: '#195a4b',
  greenDark: '#268771',
  green: '#32b496',
  greenSuccess: '#60d3b8',
  greenSuccessLight: '#dff6f1',

  greyDark: '#3c3c3c',
  grey: '#6c6c6c',
  greyLight: '#cbcbcb',
  greyMediumLight: '#ededed',
  greyUltraLight: '#f7f7f7',
  greyHover: '#f3f3f3',
  greySeparator: '#e3e3e3',

  lightslategrey: '#778899',
  logan: '#ababc7',

  orangeAlert: '#ffa800',
  orangeAlertLight: '#ffeecc',

  purple50: '#c37fb3',

  redError,
  redErrorLight: '#f9d6dc',

  white: '#fff',
  almostWhite: '#f7f5f5',
  red,

  prussianBlue: '#002A4D',
  athensGray: '#F7F7FA',
  babyBlue: '#F7F7FA',

  ...aliases
};

export default colors;
