import { useState, useRef } from 'react';
import {
  Box,
  Avatar,
  Paper,
  DialogContent,
  DialogTitle,
  MenuList,
  Link,
  ClickAwayListener
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Typography } from '@spotahome/ui-library/base';
import PropTypes from 'prop-types';

import {
  getLink,
  LINK_PRIVACY_POLICIES,
  LINK_TERMS_AND_CONDITIONS,
  LINK_HOME
} from '../../../../../../utils/url';

import {
  Popper,
  Arrow,
  MenuItem,
  Separator,
  AvatarLabel,
  AvatarIconButton
} from './styles';

const AvatarMenu = ({ locales, handleLogout, trans, userName }) => {
  const [arrowRef, setArrowRef] = useState(null);
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleClickButton = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClickAway = () => {
    if (open) {
      setOpen(false);
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box
        sx={{ flexGrow: 0, display: { md: 'flex' } }}
        data-testid="avatar-menu"
      >
        <AvatarIconButton
          onClick={handleClickButton}
          disableRipple
          size="large"
        >
          <Avatar alt={userName} src="/static/images/avatar/2.jpg" />
          <AvatarLabel ref={anchorRef}>
            <Typography.LabelS>{userName?.split(' ')[0]}</Typography.LabelS>
            <KeyboardArrowDownIcon />
          </AvatarLabel>
        </AvatarIconButton>

        <Popper
          placement="bottom-end"
          anchorEl={anchorRef.current}
          disablePortal={false}
          open={open}
          modifiers={[
            {
              name: 'flip',
              enabled: true
            },
            {
              name: 'preventOverflow',
              enabled: true,
              options: {
                boundariesElement: 'scrollParent'
              }
            },
            {
              name: 'arrow',
              enabled: true,
              options: {
                element: arrowRef
              }
            }
          ]}
        >
          <Arrow ref={setArrowRef} />

          <Paper
            sx={{
              width: '260px',
              maxWidth: 400,
              overflow: 'auto',
              padding: '0',
              borderRadius: '16px'
            }}
          >
            <DialogTitle
              sx={{
                padding: '24px'
              }}
            >
              {<Typography.HeadingM>{userName}</Typography.HeadingM>}
            </DialogTitle>
            <DialogContent
              sx={{
                padding: '0px'
              }}
            >
              <MenuList
                sx={{
                  padding: '0px'
                }}
              >
                <MenuItem component={Link} href="/settings" disableRipple>
                  <Typography.ParagraphSShort>
                    {trans('sahc.menu.route.settings')}
                  </Typography.ParagraphSShort>
                </MenuItem>
                <MenuItem
                  component={Link}
                  href={getLink(LINK_HOME, locales.current)}
                  disableRipple
                >
                  <Typography.ParagraphSShort>
                    {trans('sahc.avatar.menu.go-website')}
                  </Typography.ParagraphSShort>
                </MenuItem>
                <MenuItem component={Link} href="/my-referrals" disableRipple>
                  <Typography.ParagraphSShort>
                    {trans('sahc.header.refer-a-landlord.button')}
                  </Typography.ParagraphSShort>
                </MenuItem>
                <Separator />
                <MenuItem component={Link} href="/support" disableRipple>
                  <Typography.ParagraphSShort>
                    {trans('landlord.support.title')}
                  </Typography.ParagraphSShort>
                </MenuItem>
                <MenuItem
                  component={Link}
                  href={getLink(LINK_TERMS_AND_CONDITIONS, locales.current)}
                  disableRipple
                >
                  <Typography.ParagraphSShort>
                    {trans('sahc.footer.links.terms.and.conditions')}
                  </Typography.ParagraphSShort>
                </MenuItem>
                <MenuItem
                  component={Link}
                  href={getLink(LINK_PRIVACY_POLICIES, locales.current)}
                  disableRipple
                >
                  <Typography.ParagraphSShort>
                    {trans('sahc.footer.links.privacy.policy')}
                  </Typography.ParagraphSShort>
                </MenuItem>
                <Separator />
                <MenuItem onClick={handleLogout} component={Link} disableRipple>
                  <Typography.ParagraphSShort>
                    {trans('sahc.header.logout.text')}
                  </Typography.ParagraphSShort>
                </MenuItem>
              </MenuList>
            </DialogContent>
          </Paper>
        </Popper>
      </Box>
    </ClickAwayListener>
  );
};

AvatarMenu.propTypes = {
  locales: PropTypes.object.isRequired,
  handleLogout: PropTypes.func.isRequired,
  trans: PropTypes.func.isRequired,
  userName: PropTypes.string.isRequired
};

export default AvatarMenu;
