export const landingLoggedInRoutes = {
  PROPERTIES: {
    path: '/properties',
    titleTranslationKey: 'sahc.menu.route.properties',
    id: 'properties',
    isNew: false
  },
  BOOKINGS: {
    path: '/bookings',
    titleTranslationKey: 'sahc.menu.route.bookings',
    id: 'bookings',
    isNew: false
  },
  OFFERS: {
    path: '/offers',
    titleTranslationKey: 'sahc.menu.route.offers',
    id: 'offers',
    isNew: false
  },
  DASHBOARD: {
    path: '/dashboard',
    titleTranslationKey: 'sahc.menu.route.dashboard',
    id: 'dashboard',
    isNew: false
  },
  PLANS: {
    path: '/plans',
    titleTranslationKey: 'sahc.menu.route.plans',
    id: 'plans',
    isNew: true,
    highlight: true
  }
};

export const landingLoggedOutRoutes = {
  TENANT: {
    id: 'externals.landing',
    titleTranslationKey: 'landingpage.button.tenant',
    isNew: false,
    highlight: true
  },
  HOWTO: {
    id: 'externals.how-it-works',
    titleTranslationKey: 'footer.item.how_it_works',
    isNew: false
  },
  PLUS: {
    id: 'externals.plus-plan',
    titleTranslationKey: 'landingpage.button.plus',
    isNew: false
  }
};

export default landingLoggedInRoutes;
