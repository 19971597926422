const KeyIcon = () => (
  <svg
    width="89"
    height="88"
    viewBox="0 0 89 88"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M72.3331 0H16.3333C7.4967 0 0.333252 7.16344 0.333252 16V72C0.333252 80.8366 7.49671 88 16.3333 88H72.3331C81.1696 88 88.3331 80.8366 88.3331 72V16C88.3331 7.16344 81.1696 0 72.3331 0Z"
      fill="#FFCCEF"
    />
    <rect
      x="62.3733"
      y="57.3468"
      width="12.9067"
      height="13.0533"
      transform="rotate(90 62.3733 57.3468)"
      fill="#0A0777"
    />
    <rect x="37.8799" y="49.2801" width="12.9067" height="31.24" fill="white" />
    <circle cx="44.3332" cy="30.0667" r="22.44" fill="#FB6E44" />
    <path
      d="M44.3333 39.6001C49.5984 39.6001 53.8666 35.3319 53.8666 30.0668C53.8666 24.8016 49.5984 20.5334 44.3333 20.5334C39.0682 20.5334 34.7999 24.8016 34.7999 30.0668C34.7999 35.3319 39.0682 39.6001 44.3333 39.6001Z"
      fill="#FFCCEF"
    />
  </svg>
);

export default KeyIcon;
