import PropTypes from 'prop-types';

const SupportIcon = ({ className = '', color = '#0A0777' }) => (
  <svg
    width="61"
    height="60"
    viewBox="0 0 61 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <circle cx="30.6667" cy="30" r="27" fill="#F4EFED" />
    <path
      d="M43.2024 17.4644L25.2942 24.6276L18.131 42.5358L36.0391 35.3725L43.2024 17.4644Z"
      fill={color}
    />
    <circle cx="30.6667" cy="29.9999" r="1.92857" fill="white" />
  </svg>
);

export default SupportIcon;

SupportIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string
};
