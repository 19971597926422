import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const HelpIcon = ({ color = undefined, ...props }) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '32px',
      height: '32px',
      fontSize: '32px'
    }}
    {...props}
  >
    <HelpOutlineIcon color={color} fontSize="inherit" />
  </Box>
);

HelpIcon.propTypes = {
  color: PropTypes.oneOf([
    'action',
    'disabled',
    'error',
    'inherit',
    'primary',
    'secondary'
  ])
};

export default HelpIcon;
