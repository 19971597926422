import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import { Box, Table as TableMUI, TableCell } from '@mui/material';

export const Title = styled(Box)({
  padding: '40px 40px 40px 40px'
});

export const Table = styled(TableMUI)({
  padding: '0 40px 40px 40px',
  borderCollapse: 'separate',
  '& td': {
    width: 285
  }
});

export const StyledTableCell = styled(TableCell)(({ accent }) => ({
  backgroundColor: accent ? 'rgba(201, 227, 247, 0.2)' : 'inherit'
}));

export const useStyles = makeStyles(() => ({
  title: {
    padding: '40px 40px 40px 40px'
  },
  table: {
    padding: '0 40px 40px 40px',
    '& td': {
      width: 285
    },
    borderCollapse: 'separate'
  },
  tableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#F7F7F7'
    }
  },
  tableCell: {
    '&.plus': {
      backgroundColor: 'rgba(201, 227, 247, 0.2)'
    }
  }
}));
