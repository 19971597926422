import styled from '@emotion/styled';
import { Link } from '@mui/material';

import { space } from '../../../../../../shared/spacing';
import colors from '../../../../../../shared/colors';
import ChevronUp from '../../../../../../icons/ChevronDown';

const customThemeProps = id => {
  const acceptedIds = [
    'isCurrentSection',
    'isLoggedIn',
    'theme',
    'isCurrentSection'
  ];
  return acceptedIds.includes(id);
};

const NavItemLink = ({ ...props }) => <Link {...props} underline="hover" />;
NavItemLink.muiName = Link.muiName;

export const NavItem = styled(NavItemLink, {
  shouldForwardProp: prop => !customThemeProps(prop)
})`
  font-size: var(--sah-font-m);
  line-height: var(--sah-line-height-s);
  font-family: var(--sah-font-bold);
  font-weight: var(--sah-font-w-bold);
  color: var(--sah-interaction-dark);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${space.x3} 0;
  ${props => props.theme.breakpoints.up('md')} {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 ${space.x2};
    cursor: pointer;

    &:before {
      content: '';
      position: absolute;
      opacity: ${props => (props.isCurrentSection ? 1 : 0)};
      bottom: 0;
      left: 0;
      display: block;
      height: 3px;
      width: 100%;
      background-color: ${props =>
        props.isLoggedIn ? colors.darkBlue500 : ' transparent'};
      transition: opacity 0.3s ease;
    }

    &:hover {
      &:before {
        opacity: 1;
      }
    }
  }
`;

export const Arrow = styled(ChevronUp)`
  color: black;
  transform: scale(0.8) rotate(270deg);
  transition: transform 0.3s ease;
  ${props => props.theme.breakpoints.up('md')} {
    margin: 0 4px;
    transform: ${props =>
      props.menuOpen ? 'scale(0.8) rotate(180deg)' : 'scale(0.8)'};
  }
`;

export const NavContainer = styled.nav`
  padding: ${space.x2} 0;
  ${props => props.theme.breakpoints.up('md')} {
    display: flex;
    align-self: stretch;
    align-items: stretch;
    padding: 0;
  }
  ${NavItem}:hover {
    text-decoration: none;
  }
`;

export const Square = styled.div`
  border-color: var(--sah-medium);
  border-style: solid;
  border-width: 1px;
  color: var(--sah-interaction-dark);
  padding: 8px 14px;
`;
