import { PropTypes } from 'prop-types';

import { Link } from '@spotahome/ui-library';

const SocialLink = ({ href, children }) => (
  <Link hover size="medium" href={href} underlined={false}>
    {children}
  </Link>
);

SocialLink.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default SocialLink;
