import { styled } from '@mui/material/styles';
import {
  IconButton,
  MenuItem as MenuItemMUI,
  Popper as PopperMUI
} from '@mui/material';

import { fontNormalizer } from '../../styles';

export const Arrow = styled('span')({
  position: 'absolute',
  fontSize: 7,
  width: '3em',
  height: '3em',
  '&:before': {
    content: '""',
    margin: 'auto',
    display: 'block',
    width: 0,
    height: 0,
    borderStyle: 'solid',
    position: 'relative',
    bottom: '4px',
    left: '-20px'
  },
  '&:after': {
    content: '""',
    margin: 'auto',
    display: 'block',
    width: 0,
    height: 0,
    borderStyle: 'solid',
    position: 'relative',
    bottom: '13px',
    left: '-20px'
  }
});

export const Popper = styled(PopperMUI)(({ placement }) => ({
  ...fontNormalizer,
  borderRadius: '16px',
  zIndex: 100,
  boxShadow: '0px 2px 8px 0 rgba(0, 0, 0, 0.18)',
  borderColor: '#EBEBEB',
  top: '20px !important',
  right: '-20px !important',
  ...(placement.includes('bottom') && {
    [`${Arrow}`]: {
      top: 0,
      left: '-10px',
      marginTop: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '0 1.5em 1.5em 1.5em',
        borderColor: 'transparent transparent #EBEBEB transparent'
      },
      '&::after': {
        borderWidth: '0 1.5em 1.5em 1.5em',
        borderColor: 'transparent transparent white transparent'
      }
    }
  })
}));

export const MenuItem = styled(MenuItemMUI)({
  paddingLeft: '24px',
  paddingRight: '24px',
  height: '36px',
  '& p': {
    color: '#000000'
  },
  '&:last-of-type': {
    marginBottom: '16px'
  },
  '&:hover': {
    backgroundColor: 'transparent'
  }
});

export const AvatarIconButton = styled(IconButton)({
  display: 'inline-block',
  '&:hover': {
    cursor: 'pointer',
    backgroundColor: 'transparent'
  }
});
