import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

import BaseIcon from './Base';

const PowerRounded = ({ width = 23, height = 23, ...props }) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '32px',
      height: '32px'
    }}
  >
    <BaseIcon width={width} height={height} viewBox="0 0 23 23" {...props}>
      <path
        fill="#0D0D0D"
        d="M12.0001 13.1333c-.2889 0-.5276-.0946-.716-.284-.1894-.1884-.284-.4271-.284-.716V1.3667c0-.289.0946-.528.284-.7174.1884-.1884.4271-.2826.716-.2826s.528.0942.7173.2826c.1885.1894.2827.4285.2827.7174v10.7666c0 .2889-.0942.5276-.2827.716-.1893.1894-.4284.284-.7173.284Zm0 10c-1.5778 0-3.0498-.3-4.416-.9C6.217 21.6333 5.0223 20.8222 4 19.8c-1.0222-1.0222-1.8333-2.2169-2.4333-3.584-.6-1.3662-.9-2.8382-.9-4.416 0-1.4889.2835-2.9169.8506-4.284C2.0835 6.1498 2.9 4.9222 3.9666 3.8333c.2-.2.4392-.2946.7174-.284.2773.0116.516.1174.716.3174.1778.1778.2555.4.2333.6666-.0222.2667-.1222.5112-.3.7334-.8666.8889-1.5275 1.8942-1.9827 3.016-.456 1.1226-.684 2.2951-.684 3.5173 0 2.6.9058 4.8053 2.7174 6.616C7.1947 20.2276 9.4 21.1333 12 21.1333c2.6 0 4.8053-.9057 6.616-2.7173 1.8115-1.8107 2.7173-4.016 2.7173-6.616 0-1.2222-.2275-2.4-.6827-3.5333-.456-1.1334-1.1173-2.1445-1.984-3.0334-.2-.2-.3053-.4333-.316-.7-.0115-.2666.0716-.5.2494-.7.2-.2.4391-.3.7173-.3.2773 0 .516.1.716.3C21.1 4.9223 21.9169 6.1498 22.484 7.516c.5662 1.3671.8493 2.7951.8493 4.284 0 1.5778-.3 3.0498-.9 4.416-.6 1.3671-1.4111 2.5618-2.4333 3.584-1.0222 1.0222-2.2169 1.8333-3.584 2.4333-1.3662.6-2.8382.9-4.416.9Z"
      />
    </BaseIcon>
  </Box>
);

PowerRounded.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
};

export default PowerRounded;
