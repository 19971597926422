import { createStyles, makeStyles } from '@mui/styles';

import { fontNormalizer } from '../../styles';

const languageMenuOption = {
  height: '80px',
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
  textTransform: 'capitalize'
};

const flag = {
  paddingRight: '12px'
};

const rightIcon = {
  marginLeft: 'auto',
  display: 'flex'
};

export default makeStyles(() =>
  createStyles({
    languageMenuOption,
    fontNormalizer,
    rightIcon,
    flag
  })
);
