import { makeStyles } from '@mui/styles';

const Footer = makeStyles(theme => ({
  topWrapper: {
    textAlign: 'center',
    padding: '64px 16px 0',
    background: 'var(--sah-tertiary)',

    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  title: {
    fontFamily: 'var(--sah-font-title)',
    fontWeight: 'var(--sah-font-w-regular)',
    fontSize: 'var(--sah-font-xxxxl)',
    lineHeight: 'var(--sah-line-height-xxxxl)',
    color: 'var(--sah-dark)',
    paddingBottom: '32px'
  },
  cta: {
    fontFamily: 'var(--sah-font-bold)',
    padding: '16px 0',
    '& a': {
      padding: '16px 32px'
    }
  },
  footer: {
    paddingTop: '32px',
    color: 'var(--sah-interaction-dark)',
    background: 'var(--sah-tertiary)'
  },
  sectionWrapper: {
    paddingLeft: '16px',
    paddingRight: '16px',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      flexWrap: 'wrap'
    },
    [theme.breakpoints.up('lg')]: {
      margin: 'auto',
      maxWidth: '1088px',
      padding: '0 16px'
    }
  },
  appFooter: {
    marginTop: '12px',
    '& ul': {
      flexWrap: 'wrap',
      [theme.breakpoints.up('sm')]: {
        '& li': {
          order: 1
        },
        '& li:first-child': {
          flexBasis: '100%',
          order: 2
        }
      }
    }
  },
  disclaimer: {
    flex: 1,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    padding: '32px 0px',
    '& hr': {
      flexBasis: '100%',
      borderTop: '2px solid var(--sah-dark)',
      margin: '32px auto'
    }
  },
  copyright: {
    marginBottom: '24px',
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '32px',
    fontFamily: 'var(--sah-font-light)',
    fontWeight: 'var(--sah-font-w-light)',
    fontSize: 'var(--sah-font-s)',
    '& svg': {
      marginRight: '12px'
    },

    [theme.breakpoints.down('md')]: {
      flexBasis: '100%'
    },

    [theme.breakpoints.up('lg')]: {
      marginBottom: 0
    }
  },
  social: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    textAlign: 'left',
    '& > span': {
      width: '120px',
      paddingRight: '24px'
    },
    '& select': {
      height: 'auto'
    },
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  }
}));

export default () => ({
  footer: Footer()
});
