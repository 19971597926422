import PropTypes from 'prop-types';

import { capitalize } from '../../lib/string-utils';

export const PLANS = {
  STANDARD: 'standard',
  PLUS: 'plus',
  ALLIN: 'allIn'
};

export const PLAN_VALUES = Object.values(PLANS);

const normalizePlanName = plan => {
  if (plan === PLANS.STANDARD || plan === PLANS.PLUS) {
    return capitalize(plan);
  }

  return 'All-in';
};

const getButtonTextFromCurrentPlan = currentPlan => {
  if (!PLAN_VALUES.find(plan => plan === currentPlan)) {
    return [
      'sahc.properties.plans.button.select',
      'sahc.properties.plans.button.select',
      'sahc.properties.plans.button.select'
    ];
  }

  return [
    [
      'sahc.properties.plans.current',
      'sahc.properties.plans.button.select',
      'sahc.properties.plans.button.select'
    ],
    [
      'sahc.properties.plans.button.downgrade',
      'sahc.properties.plans.current',
      'sahc.properties.plans.button.select'
    ],
    [
      'sahc.properties.plans.button.downgrade',
      'sahc.properties.plans.button.downgrade',
      'sahc.properties.plans.current'
    ]
  ][PLAN_VALUES.indexOf(currentPlan)];
};

const buttonTexts = [
  'sahc.properties.plans.standard.button',
  'sahc.properties.plans.plus.button',
  'sahc.properties.plans.plus.button'
];

const rows = {
  'sahc.properties.plans.table.1': {
    [PLANS.STANDARD]: true,
    [PLANS.PLUS]: true,
    [PLANS.ALLIN]: true
  },
  'sahc.properties.plans.table.2': {
    [PLANS.STANDARD]: true,
    [PLANS.PLUS]: true,
    [PLANS.ALLIN]: true
  },
  'sahc.properties.plans.table.3': {
    [PLANS.STANDARD]: true,
    [PLANS.PLUS]: true,
    [PLANS.ALLIN]: true
  },
  'sahc.properties.plans.table.4': {
    [PLANS.STANDARD]: true,
    [PLANS.PLUS]: true,
    [PLANS.ALLIN]: true
  },
  'sahc.properties.plans.table.5': {
    [PLANS.STANDARD]: false,
    [PLANS.PLUS]: true,
    [PLANS.ALLIN]: true,
    hasTooltip: true
  },
  'sahc.properties.plans.table.6': {
    [PLANS.STANDARD]: 'sahc.properties.plans.table.6.standard',
    [PLANS.PLUS]: 'sahc.properties.plans.table.6.plus',
    [PLANS.ALLIN]: 'sahc.properties.plans.table.6.plus'
  },
  'sahc.properties.plans.table.7': {
    [PLANS.STANDARD]: false,
    [PLANS.PLUS]: 'sahc.properties.plans.table.7.plus',
    [PLANS.ALLIN]: 'sahc.properties.plans.table.7.plus'
  },
  'sahc.properties.plans.table.8': {
    [PLANS.STANDARD]: false,
    [PLANS.PLUS]: 'sahc.properties.plans.table.8.plus',
    [PLANS.ALLIN]: 'sahc.properties.plans.table.8.plus'
  },
  'sahc.properties.plans.table.9': {
    [PLANS.STANDARD]: false,
    [PLANS.PLUS]: true,
    [PLANS.ALLIN]: true
  },
  'sahc.properties.plans.table.10': {
    [PLANS.STANDARD]: false,
    [PLANS.PLUS]: true,
    [PLANS.ALLIN]: true,
    hasTooltip: true
  },
  'sahc.properties.plans.table.11': {
    [PLANS.STANDARD]: false,
    [PLANS.PLUS]: false,
    [PLANS.ALLIN]: true
  }
};

export const getPlansData = (
  currentPlan,
  shouldUpdatePlans,
  hidePlans = [],
  disableButtons
) => {
  const updatePlanButtonInfo = shouldUpdatePlans
    ? getButtonTextFromCurrentPlan(currentPlan)
    : buttonTexts;

  const plans = {
    [PLANS.STANDARD]: {
      name: normalizePlanName(PLANS.STANDARD),
      description: 'sahc.properties.plans.standard.description',
      buttonInfo: {
        text: updatePlanButtonInfo[0],
        disabled:
          disableButtons ||
          (shouldUpdatePlans && currentPlan === PLANS.STANDARD)
      }
    },
    [PLANS.PLUS]: {
      name: normalizePlanName(PLANS.PLUS),
      description: 'sahc.properties.plans.plus.description',
      buttonInfo: {
        text: updatePlanButtonInfo[1],
        disabled:
          disableButtons || (shouldUpdatePlans && currentPlan === PLANS.PLUS)
      }
    },
    [PLANS.ALLIN]: {
      name: normalizePlanName(PLANS.ALLIN),
      description: 'sahc.properties.plans.allin.description',
      buttonInfo: {
        text: updatePlanButtonInfo[2],
        disabled:
          disableButtons || (shouldUpdatePlans && currentPlan === PLANS.ALLIN)
      }
    }
  };

  return {
    plans: Object.fromEntries(
      Object.entries(plans).filter(
        ([planName]) => !hidePlans.includes(planName)
      )
    ),
    rows
  };
};

export const plansDataShape = {
  plans: PropTypes.shape({
    [PropTypes.string]: PropTypes.shape({
      description: PropTypes.string,
      buttonInfo: PropTypes.shape({
        text: PropTypes.string,
        disabled: PropTypes.bool,
        link: PropTypes.func
      })
    })
  }),
  rows: PropTypes.shape({
    [PropTypes.string]: PropTypes.shape({
      [PropTypes.string]: PropTypes.bool
    })
  })
};

export const planValuesListShape = PropTypes.oneOf(Object.values(PLANS));
