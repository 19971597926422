import { forwardRef } from 'react';

import UserDetails from '../UserDetails';
import LogoutTooltip from '../LogoutTooltip';

import { noop } from '../../../../utils/fn';

import { HeaderToolbarPropTypes } from './propTypes';

const HeaderToolbarMobile = forwardRef(
  (
    {
      name,
      email,
      avatarBorderWidth,
      logoutTitle,
      isLogoutTooltipOpen,
      logoutButtonText,
      onLogout,
      distance
    },
    ref
  ) => {
    return (
      <LogoutTooltip
        title={logoutTitle}
        isOpen={isLogoutTooltipOpen}
        logoutButtonText={logoutButtonText}
        onLogout={onLogout}
        distance={distance}
        ref={ref}
      >
        <UserDetails
          name={name}
          email={email}
          avatarBorderWidth={avatarBorderWidth}
        />
      </LogoutTooltip>
    );
  }
);

HeaderToolbarMobile.defaultProps = {
  onLogout: noop,
  distance: 20
};

HeaderToolbarMobile.propTypes = HeaderToolbarPropTypes;

export default HeaderToolbarMobile;
