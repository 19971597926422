export const positions = {
  UP: 'up',
  DOWN: 'down',
  LEFT: 'left',
  RIGHT: 'right'
};

export const alignments = {
  MIDDLE: 'middle',
  START: 'start',
  END: 'end'
};

export const positionsArray = Object.values(positions);

export const alignmentsArray = Object.values(alignments);
