import BaseIcon from './Base';

const SpotahomeSymbol = props => (
  <BaseIcon width="22" height="32" viewBox="0 0 22 32" {...props}>
    <path
      d="M10.667 0a10.666 10.666 0 0 1 10.666 10.667H0A10.667 10.667 0 0 1 10.667 0"
      fill="#C9E3F7"
    />
    <path
      d="M10.667 32A10.667 10.667 0 0 1 0 21.333h21.333A10.667 10.667 0 0 1 10.667 32Z"
      fill="#0A0777"
    />
    <path
      d="M10.667 21.333A10.667 10.667 0 0 1 0 10.667h10.667v10.666Z"
      fill="#FB6E44"
    />
    <path
      d="M10.666 10.667a10.667 10.667 0 0 1 10.667 10.666H10.667V10.667Z"
      fill="#FFCCEF"
    />
  </BaseIcon>
);

export default SpotahomeSymbol;
