import styled from '@emotion/styled';
import { css } from '@emotion/react';

import fonts from '../../../../shared/fonts';
import colors from '../../../../shared/colors';
import { space } from '../../../../shared/spacing';
import { mediaQueries } from '../../../../shared/breakpoints';

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const LogoutIcon = styled.div`
  display: none;
  margin: 0 ${space.x2};
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid ${colors.primary};
  transform: scale(0.6);

  ${mediaQueries.xl(css`
    display: flex;
  `)};
`;

export const Title = styled.div`
  font-family: ${fonts.GothamBold};
  font-size: ${fonts.sizes.s};
  margin-bottom: ${space.x1};
`;
