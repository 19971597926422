import PropTypes from 'prop-types';
import AccordionSummaryMUI, {
  accordionSummaryClasses
} from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';
import Typography from '@spotahome/landlord-panel-ui-library/src/components/Typography';

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: ' column',
  padding: 0,
  [theme.breakpoints.up('sm')]: {
    placeItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    flexDirection: 'row'
  }
}));

const ContentDisplayS = styled('div')(({ theme }) => ({
  order: 2,
  display: 'none',
  [theme.breakpoints.up('sm')]: {
    order: 1,
    display: 'block'
  }
}));

const ContentDisplayXS = styled('div')(({ theme }) => ({
  order: 2,
  display: 'block',
  [theme.breakpoints.up('sm')]: {
    order: 1,
    display: 'none'
  }
}));

const Position = styled('span')(({ theme }) => ({
  display: 'flex',
  placeContent: 'center',
  placeItems: 'center',
  width: 64,
  height: 64,
  border: '1px solid #0D0D0D',
  borderRadius: '50%',
  order: 1,
  marginBottom: 24,
  [theme.breakpoints.up('sm')]: {
    order: 2,
    marginBottom: 0
  }
}));

const AccordionSummary = styled(AccordionSummaryMUI)(({ theme, isLast }) => ({
  padding: isLast ? '12px 20px 0' : '20px 20px 28px',
  [theme.breakpoints.up('sm')]: {
    padding: isLast ? '18px 56px' : '18px 56px 45px'
  },

  [`&.${accordionSummaryClasses.root}.${accordionSummaryClasses.expanded}`]: {
    padding: '6px 10px 0',
    [theme.breakpoints.up('sm')]: {
      padding: isLast ? '6px 56px' : '6px 56px 0'
    }
  }
}));

const AccordionHeader = ({ children, position = null, isLast = false }) => {
  return (
    <AccordionSummary
      isLast={isLast}
      aria-controls={`steps-to-publish-${position ?? 0}-content`}
      id={`steps-to-publish-${position ?? 0}-header`}
    >
      <Container>
        <ContentDisplayS>
          <Typography variant="DisplayS">{children}</Typography>
        </ContentDisplayS>
        <ContentDisplayXS>
          <Typography variant="DisplayXS">{children}</Typography>
        </ContentDisplayXS>
        {position && <Position>{position}</Position>}
      </Container>
    </AccordionSummary>
  );
};

AccordionHeader.propTypes = {
  children: PropTypes.node.isRequired,
  position: PropTypes.string,
  isLast: PropTypes.bool
};

export default AccordionHeader;
