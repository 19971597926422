import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';

const shakeKeyframes = {
  '0%, 9%, 100%': {
    transform: 'rotate(0deg)'
  },
  '2%, 6%': {
    transform: 'rotate(6deg)'
  },
  '4%, 8%': {
    transform: 'rotate(-6deg)'
  }
};

export const NotificationIconButton = styled(IconButton)(({ isActive }) => ({
  padding: '6px',
  color: 'var(--sah-interaction-dark)',
  boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.18)',
  transition: 'background .3s ease',
  ...(isActive && {
    color: 'var(--sah-white)',
    backgroundColor: 'var(--sah-primary)',
    animationName: 'shake',
    animationDelay: '2s',
    animationDuration: '6s',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'ease-in-out',
    '&:hover': {
      backgroundColor: 'var(--sah-primary-light)'
    }
  }),
  '@keyframes shake': shakeKeyframes
}));
